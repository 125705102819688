import React from "react";
import "./GraphViewer.scss";
import TextBlockDescription from "../StandardStyles/TextBlockDescription/TextBlockDescription";
import { Bar } from "react-chartjs-2";
require("./RoundedBars/RoundedBars");

/**
 * @description Creates a barchart to be viewed by the admin.
 * @param {string} top - Title of the barchart.
 * @param {string[]} xLabels - Labels representing the ticks of the x-axis.
 * @param {string[]} dataLabels - Labels representing what each batch of data represents.
 * @param {number[]} currentData - Data at the forefront of the barchart.
 * @param {number[]} historicalData - Data behind the currentData.
 *
 */

function GraphViewer({
  top,
  xLabels = Array(10).fill("tmp"),
  dataLabels = ["Label1", "label2"],
  currentData = [30, 25, 45, 78, 101, 27, 58, 99, 120, 45],
  historicalData = [45, 20, 101, 89, 67, 24, 67, 107, 155, 35],
  allowAnimation,
}: {
  top: string;
  xLabels?: string[];
  dataLabels?: string[];
  currentData?: number[];
  historicalData?: number[];
  allowAnimation: boolean;
}) {
  // Make sure that their is only 2 labels
  let numDataLabelsNeeded = 2 - dataLabels.length;

  if (numDataLabelsNeeded < 0) {
    dataLabels = dataLabels.slice(0, 2);
  } else if (numDataLabelsNeeded > 0) {
    let dummyLabels = Array(numDataLabelsNeeded).fill("tmp");
    dataLabels.push(...dummyLabels);
  }

  /* Make sure that both the size of the historical data and the current data are equal and compatible with the number of labels for the x-axis ticks
   */
  let diff = xLabels.length - historicalData.length;
  if (diff < 0) {
    //Remove extra data
    let offset = historicalData.length + diff;
    historicalData = historicalData.slice(0, offset);
  } else if (diff > 0) {
    let dummyArr = Array(diff).fill(10);
    historicalData.push(...dummyArr);
  }

  diff = historicalData.length - currentData.length;
  if (diff > 0) {
    //Add dummy data
    let dummyArr = Array(diff).fill(10);
    currentData.push(...dummyArr);
  } else if (diff < 0) {
    //Remove extra data
    let offset = currentData.length + diff;
    currentData = currentData.slice(0, offset);
  }

  const firstDataBatch = {
    label: dataLabels[0],
    data: currentData,
    backgroundColor: currentData.map(
      // Comparison of currentData and historicalData, if currentData is smaller, turn it opaque dark blue, else turn it transparent
      (_, i) => {
        return currentData[i] <= historicalData[i]
          ? "rgba(2,37,60,1)"
          : "rgba(2,37,60,0.85)";
      }
    ),
  };

  // const secondDataBatch = {
  //   label: dataLabels[1],
  //   data: historicalData,
  //   backgroundColor: currentData.map(
  //     // Comparison of currentData and historicalData, if historicalData is bigger, turn it light blue, else turn it dark blue
  //     (_, i) => {
  //       return currentData[i] <= historicalData[i]
  //         ? "rgba(146,161,183,1)"
  //         : "rgba(2,37,60,1)";
  //     }
  //   ),
  // };

  //How the graph will look to the admin
  const graphOptions = {
    tooltips: {
      callbacks: {
        title: function () {
          return "";
        },
      },
    },
    legend: { display: false },
    cornerRadius: 4,
    scales: {
      xAxes: [
        {
          // align datasets on x-axis and remove the gridlines.
          stacked: true,
          gridLines: { display: false },
        },
      ],
      yAxes: [
        {
          // Remove y-axis labels and gridlines
          ticks: { display: false },
          gridLines: { display: false },
        },
      ],
    },
  };

  const completeGraphOptions = allowAnimation
    ? graphOptions
    : Object.assign(graphOptions, {
        animation: {
          duration: 0,
        },
      });

  return (
    <graph-viewer>
      <graph-viewer-start>
        <TextBlockDescription>{top}</TextBlockDescription>
      </graph-viewer-start>
      <graph-viewer-middle>
        <Bar
          data={{
            labels: xLabels,
            datasets: [firstDataBatch],
          }}
          options={completeGraphOptions}
        />
      </graph-viewer-middle>
    </graph-viewer>
  );
}

export default GraphViewer;
