import React from "react";
import "./ChatTypingIndicator.scss";
import { useMaybeObservable } from "../../../Hooks";
import { ChatView } from "./../../../Services/Chat";
import {
  useExtendedTranslation,
  i18nItemList,
} from "../../../Services/i18nService";

function ChatTypingIndicator({ chat }: { chat: ChatView }) {
  const whoTyping = useMaybeObservable(chat.whoIsTyping);
  const t = useExtendedTranslation();
  if (!whoTyping || whoTyping.length === 0) {
    return <chat-typing-indicator></chat-typing-indicator>;
  } else {
    if (whoTyping.length === 1) {
      return (
        <chat-typing-indicator>
          {t("chat.onePersonTyping", { name: whoTyping[0].name })}
        </chat-typing-indicator>
      );
    } else {
      const names = whoTyping.map((w) => w.name);
      return (
        <chat-typing-indicator>
          {t("chat.multiplePeopleTyping", { names: i18nItemList(names) })}
        </chat-typing-indicator>
      );
    }
  }
}

export default ChatTypingIndicator;
