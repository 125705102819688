import { Observable, Subject, BehaviorSubject, ReplaySubject } from "rxjs";
import { first } from "rxjs/operators";
import { Deferred, DeferredPromise } from "./DeferredPromise";
import memoize from "lodash.memoize";

export function delay(timeInMs: number) {
  return new Promise((resolve) => setTimeout(resolve, timeInMs));
}

export function autoTimeout<T>(srcPromise: Promise<T>, maxTime: number) {
  return new Promise<T>((resolve, reject) => {
    setTimeout(reject, maxTime);
    srcPromise.then(resolve, reject);
  });
}

/**
 * Schedules a function to run before the next render but after the current context has finished
 * @param fn The function to be scheduled
 */
export function setImmediate(fn: () => void): void {
  Promise.resolve().then(fn);
}

/**
 * Clones an object
 * @param obj The object to be cloned
 */
export function deepClone<T extends Object>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

const isFrozen = Symbol("Frozen");
/**
 * Prevents the object or it's descendants from being modified
 * @param obj The object to be frozen
 */
export function deepFreeze<T extends Object>(obj: T): Readonly<T> {
  if (typeof obj !== "object" || !obj || (obj as any)[isFrozen]) {
    return obj;
  }
  (obj as any)[isFrozen] = true;

  Object.values(obj).forEach(deepFreeze);
  Object.freeze(obj);
  return obj;
}

/**
 * Waits until the browser is idle and then resolves
 * @param maxWaitTimeInMs The maximum amount of time to delay running the function
 */
export function untilBrowserIsIdle(
  maxWaitTimeInMs: number = -1
): Promise<void> {
  const done = DeferredPromise<void>();
  const fnRef = () => done.resolveFn();
  if ((window as any).requestIdleCallback) {
    (window as any).requestIdleCallback(fnRef, { timeout: maxWaitTimeInMs });
  } else {
    const delay = maxWaitTimeInMs > 0 ? Math.min(maxWaitTimeInMs, 500) : 500;
    setTimeout(fnRef, Math.min(500, delay));
  }
  return done.promise;
}

/**
 * Wraps a generator so that it will be invoked once regardless of the number of times it's called
 */
export function lazy<T>(fnRef: () => T): () => T {
  let result: T;
  return () => {
    if (!result) {
      result = fnRef();
    }
    return result;
  };
}

export type AnyFunction = (...args: any[]) => any;
export type PromiseReturnType<T> = T extends PromiseLike<infer U> ? U : T;

type ObservableDebouncedFunction<T> = T & {
  isReady: () => boolean;
  watchIsBusy: () => Observable<boolean>;
};

/**
 * Protects a function from being run multiple times in a short window.
 * An asynchronous function will hold the time while running and reset the debounce window when it completes.
 * @param fnRef The function to be debounced
 * @param debounceIntervalInMs [1000ms] - The window of time after the function has run to prevent it from being run again
 */
export function createObservableDebouncedFunction<T extends AnyFunction>(
  fnRef: T,
  debounceIntervalInMs: number = 1000
): ObservableDebouncedFunction<T> {
  let isBlocked: boolean;
  let subject: Subject<boolean> = new BehaviorSubject<boolean>(false);

  const setIsRunnning = () => {
    isBlocked = true;
    subject.next(true);
  };
  const setDone = () => {
    setTimeout(() => {
      isBlocked = false;
      subject.next(false);
    }, debounceIntervalInMs);
  };
  const wrappedFn: any = (...args: Parameters<T>[]) => {
    if (isBlocked) {
      throw new Error("Operation is already in progress");
    }
    setIsRunnning();
    try {
      const rv = fnRef(...args);
      if (rv && typeof rv === "object" && typeof rv.then === "function") {
        rv.then(setDone, setDone);
      } else {
        setDone();
      }
      return rv;
    } finally {
      setDone();
    }
  };
  wrappedFn.isReady = () => !isBlocked;
  wrappedFn.watchIsBusy = () => subject;
  return wrappedFn;
}

/**
 * A shortcut type for a function that accepts a single value of Type A and returns Type B
 */
export interface MapFn<T1, T2> {
  (v: T1): T2;
}

/**
 * A function to return a promise of mapping an array to async functions
 */
export function asyncMap<T, U>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => Promise<U>,
  thisArg?: any
): Promise<U[]> {
  const promises = array.map(async (v, i, a) => callbackfn(v, i, a), thisArg);
  return Promise.all(promises);
}

/**
 * A function that returns whatever value it is passed
 * @param v A value which will be returned
 */
export function IdentityFn<T>(v: T): T {
  return v;
}

/**
 * A function that returns whatever value it is passed
 * @param v A value which will be returned
 */
export async function AsyncIdentityFn<T>(v: T): Promise<T> {
  return v;
}

/**
 * A shortcut for types whose values which may not be present
 */
export type Maybe<T> = T | undefined;

type VoidAction = () => void | Promise<void>;
type PendingAsyncAction = {
  action: VoidAction;
  deferred: Deferred<void>;
};

/**
 *  guarentees that async functions using the mutex will run sequentially after each other.
 * This does NOT stop any other aysnc functions from running unless they're using the same mutex.
 */
export interface Mutex {
  /**
   * Schedule an async function to run and will wait until it resolves or rejects before allowing the next function to run
   */
  run: (fnRef: VoidAction) => Promise<void>;
}

/**
 * Creates a Mutex that guarentees that async functions using the mutex will run sequentially after each other.
 * This does NOT stop any other aysnc functions from running unless they're using the same mutex.
 */
export function createMutex(): Mutex {
  let isBusy: boolean = false;
  let pending: PendingAsyncAction[] = [];

  const checkNext = async () => {
    if (isBusy) {
      return;
    }
    if (pending.length === 0) {
      return;
    }
    const next = pending.shift();
    if (!next) {
      return;
    }
    try {
      isBusy = true;
      await next.action();
      next.deferred.resolveFn();
    } catch (err) {
      next.deferred.rejectFn(err);
    } finally {
      isBusy = false;
      checkNext();
    }
  };

  return {
    run: (fnRef: VoidAction) => {
      const wrapper: PendingAsyncAction = {
        action: fnRef,
        deferred: DeferredPromise<void>(),
      };
      pending.push(wrapper);
      checkNext();
      return wrapper.deferred.promise;
    },
  };
}
export type Dict<K extends keyof any, V> = {
  [key in K]: V;
};

export function sortBy<T, S extends string | number>(fn: (value: T) => S) {
  return (a: T, b: T) => {
    const aVal = a ? fn(a) : undefined;
    const bVal = b ? fn(b) : undefined;
    if (aVal === bVal) {
      return 0;
    }
    if (!aVal) {
      return 1;
    }
    if (!bVal) {
      return -1;
    }
    return aVal > bVal ? 1 : -1;
  };
}

/**
 * This is a typescript aware wrapper for the lodash memoize function.  It's just a pass through, but typescript will be able to
 * correctly infer and validate the resulting type
 * @param fnRef The function to be memoized
 * @param resolver Optional - A resolver function to get the memozie key, if omitted only the first argument will be used as the key
 */
export function memoizeFunction<T extends (...args: any) => any>(
  fnRef: T,
  resolver?: (...args: Parameters<T>) => string
): T {
  const memoizedFn = memoize(fnRef, resolver);
  return memoizedFn;
}

/**
 * Maps an observable to another observable but guarentees the map function will be executed once per update regardless of the number of downstream subscribers.
 * @param obs An observable to be mapped
 * @param mapFn The mapping function
 */
export function stableMap<T1, T2>(
  obs: Observable<T1>,
  mapFn: MapFn<T1, T2>
): Observable<T2> {
  const result = new ReplaySubject<T2>(1);
  obs.subscribe((value) => result.next(mapFn(value)));
  return result;
}

export function once<T>(obs: Observable<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    obs.pipe(first()).subscribe(resolve, reject);
  });
}
