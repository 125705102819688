import { Deferred, DeferredPromise } from "./DeferredPromise";
import { setImmediate } from "./util";

/**
 * A flag you can await on.
 * State changes are processed asynchronously, meaning that it's guarenteed to change state a maximum of once between micro-tasks regardless of the number of times set/clear are called in a given frame.
 */
export class AwaitableFlag {
  stateChangedDeferred: Deferred<any> = DeferredPromise();
  currentState: boolean = false;
  nextState: boolean = false;

  /**
   * Creates a new AwaitableFlag
   * @param isInitiallySet [false] - The initial state of the flag
   */
  constructor(isInitiallySet: boolean = false) {
    this.currentState = isInitiallySet;
    this.nextState = isInitiallySet;
    this.setFlag = this.setFlag.bind(this);
    this.clearFlag = this.clearFlag.bind(this);
  }

  private queueStateChange(newState: boolean) {
    this.nextState = newState;
    setImmediate(() => {
      if (this.nextState !== this.currentState) {
        this.currentState = this.nextState;
        this.stateChangedDeferred.resolveFn(null);
        this.stateChangedDeferred = DeferredPromise();
      }
    });
  }

  /**
   * blocks until the flag state changes once
   */
  async untilFlagChanges(): Promise<void> {
    await this.stateChangedDeferred.promise;
  }

  /**
   * if the flag is set then doesn't block, otherwise blocks until the flag is set
   */
  async untilFlagIsSet(): Promise<void> {
    if (!this.currentState) {
      await this.untilFlagChanges;
    }
  }

  /**
   * if the flag is cleared then doesn't block, otherwise blocks until the flag is cleared
   */
  async untilFlagIsCleared(): Promise<void> {
    if (this.currentState) {
      await this.untilFlagChanges;
    }
  }

  /**
   * Queues a state change that will sets the flag during the next microtask.
   * Set-Clear-Set-Clear... will only trigger a single state change to whatever the final state was.
   * If the state of the flag doesn't actually change then none of the waiters will be notified.
   */
  setFlag(): void {
    this.queueStateChange(true);
  }

  /**
   * Queues a state change that will sets the flag during the next microtask.
   * Set-Clear-Set-Clear... will only trigger a single state change to whatever the final state was.
   * If the state of the flag doesn't actually change then none of the waiters will be notified.
   */
  clearFlag(): void {
    this.queueStateChange(false);
  }
}
