import React from "react";
import SinglePanelLayout from "../../../Components/Layout/SinglePanel/SinglePanel";
import TextBlockDescription from "../../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import { useExtendedTranslation } from "../../../Shared/Services/i18nService";
import { Button } from "@material-ui/core";
import { appName } from "../../../Shared/Services/branding";
import { restartWorkflow } from "../../../Services/WorkflowService";
import { TextBlockHint } from "../../../Shared/Components/StandardStyles";
import { getCurrentLocation } from "./../../../Services/DataService";
import { LeftCenterRight } from "../../../Shared/Components";

function ScreenOutOfRange() {
  const t = useExtendedTranslation();
  const name = appName();
  const location = getCurrentLocation();
  const dist = location?.checkinDistance || 0;

  const distance = dist < 1000 ? dist : Math.floor(dist / 1000);
  const units = dist < 1000 ? "m" : "km";

  return (
    <screen-closing>
      <SinglePanelLayout
        title={<>{t("outofrange.title")}</>}
        footer={
          <LeftCenterRight
            center={
              <Button
                variant="contained"
                color="primary"
                onClick={restartWorkflow}
              >
                {t("outofrange.close")}
              </Button>
            }
          />
        }
      >
        <TextBlockDescription>
          {t("outofrange.description", { name, distance, units })}
        </TextBlockDescription>
        <TextBlockHint>{t("outofrange.hint")}</TextBlockHint>
      </SinglePanelLayout>
    </screen-closing>
  );
}

export default ScreenOutOfRange;
