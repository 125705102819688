const _ = require('lodash');
const { Observable } = require('rxjs');
const { handleBeeError } = require('./ConnectionService');

const orderQueueItems = (data, items) => {
  const sortArray = _.get(data, 'queue.properties.queueItemIds', []);
  return items.sort((item1, item2) => sortArray.indexOf(item1.id) - sortArray.indexOf(item2.id));
};

const updateSubscribers = _.curry((data, items) => {
  data.queueItems = orderQueueItems(data, items);
  data.subscriber.next({
    id: data.queue.id,
    queueName: data.queue.properties.name,
    items: data.queueItems,
    location: _.assignIn(
      { id: _.get(data, 'location.id')},
      _.get(data, 'location.properties', {}),
    ),
  });
});

const fetchQueue = (data, queueId) => {
  return data.bee.storage.getClassInstanceByClassNameAndId('vwroom', 'queue', queueId)
    .then(queue => {
      data.queue = queue;
      return queue;
    });
};

const fetchLocation = data => queue => {
  return data.bee.storage.getClassInstanceByClassNameAndId('vwroom', 'location', queue.properties.locationId)
    .then(location => {
      data.location = location;
      return queue;
    });
};

const fetchQueueItems = data => queue => {
  const itemIds = _.get(queue, 'properties.queueItemIds', []);
  if (_.isEmpty(itemIds)) {
    updateSubscribers(data, []);
    return [];
  }

  return data.bee.storage.getManyClassInstanceByClassNameAndId('vwroom', 'queueItem', itemIds)
    .then(updateSubscribers(data))
  ;
};

const listenForQueueChanges = data => queue => {
  if (data.unsubscribed) {
    return queue;
  }

  const queueHandler = () => {
    console.debug('Queue changed, reloading');
    return fetchQueue(data, queue.id)
      .then(fetchQueueItems(data));
  };

  let callbackId = data.bee.reactions.setCallback(`storage:mutate.instance.${queue.id}`, queueHandler);
  console.debug(`Adding callback for queue ${data.queueId}: ${callbackId}`);
  data.callbackIds.push(callbackId);

  const itemHandler = r => {
    console.debug('Queue item changed');

    const item = _.find(data.queueItems, { id: r.details.id });
    if (item) {
      return data.bee.storage.getClassInstanceByClassNameAndId('vwroom', 'queueItem', item.id)
        .then(updatedItem => {
          const items = _.map(data.queueItems, i => {
            if (i.id === updatedItem.id) {
              return updatedItem;
            }

            return i;
          });

          updateSubscribers(data, items);
        });
    }
  };

  callbackId = data.bee.reactions.setCallback(`storage:mutate.vwroom.queueItem`, itemHandler);
  console.debug(`Adding callback for queue ${data.queueId}: ${callbackId}`);
  data.callbackIds.push(callbackId);

  return queue;
};

const removeCallbacks = (data) => {
  const callbackIds = data.callbackIds;
  data.callbackIds = [];
  console.debug(`Removing callbacks for queue ${data.queueId}: ${callbackIds}`);
  callbackIds.forEach(data.bee.reactions.removeCallback);
};

function observe(bee, queueId) {
  // TODO: validate params

  return new Observable(subscriber => {
    console.debug(`Subscribing to queue ${queueId}...`);

    const data = {
      unsubscribed: false,
      bee,
      queueId,
      subscriber,
      location: undefined,
      queueItems: [],
      callbackIds: [],
    };

    fetchQueue(data, queueId)
      .then(fetchLocation(data))
      .then(listenForQueueChanges(data))
      .then(fetchQueueItems(data))
      .catch(error => {
        console.error('Subscribing error', error);
        handleBeeError(error);
        removeCallbacks(data);
        subscriber.error(error);
      })
      .finally(() => {
        if (data.unsubscribed) {
          removeCallbacks(data);
        }
      })
    ;

    return () => {
      console.debug(`Unsubscribing from queue ${queueId}`);
      removeCallbacks(data);
      data.unsubscribed = true;
    };
  });
}

module.exports = {
  observe,
};
