import React, { useCallback, useEffect, useRef, useState } from "react";
import { useGuid } from "../../Hooks";
import { prefetchSvgImport } from "../SvgImport/SvgImport";

import "./InQCheckBox.scss";

const svgChecked = prefetchSvgImport("Shared/checked.svg");
const svgUnchecked = prefetchSvgImport("Shared/unchecked.svg");

function InQCheckBox({
  label,
  checked,
  onChange,
  disabled,
  children,
}: {
  label?: JSX.Element | string;
  checked?: boolean;
  onChange?: (isChecked: boolean) => void;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[];
}) {
  const checkboxRef = useRef();
  const [isChecked, setIsChecked] = useState(checked);
  const checkboxId = useGuid();
  const handleChange = useCallback(
    (e: any) => {
      const elem: any = checkboxRef?.current;
      if (!elem || disabled) {
        return;
      }
      const state = elem.checked;
      if (state !== isChecked) {
        setIsChecked(state);
        if (onChange) {
          onChange(state);
        }
      }
    },
    [checkboxRef, isChecked, onChange, disabled]
  );
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const className = disabled ? "disabled" : "";
  return (
    <inq-checkbox class={className}>
      <input
        id={checkboxId}
        ref={checkboxRef}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        tabIndex="0"
      ></input>
      <label htmlFor={checkboxId}>
        {isChecked ? svgChecked : svgUnchecked}
        <inq-label>
          {label}
          {children}
        </inq-label>
      </label>
    </inq-checkbox>
  );
}

export default InQCheckBox;
