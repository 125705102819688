import React, { useCallback, useContext, useRef } from "react";
import { useGuid } from "../../Hooks";
import { prefetchSvgImport } from "../SvgImport/SvgImport";
import {
  InQRadioButtonGroupMetaData,
  InQRadioButtonGroupContext,
} from "./../InQRadioButtonGroup/InQRadioButtonGroup";

import "./InQRadioButton.scss";

const svgHollow = prefetchSvgImport("Shared/hollow.svg");
const svgPip = prefetchSvgImport("Shared/pip.svg");

function InQRadioButton({
  id,
  className,
  label,
  value,
  children,
}: {
  id?: string;
  className?: string;
  label?: JSX.Element | string;
  value: string;
  children?: React.ReactNode;
}) {
  const metaData: InQRadioButtonGroupMetaData | undefined = useContext(
    InQRadioButtonGroupContext
  );
  const radioButtonRef = useRef();
  const radioButtonId = useGuid();
  const handleChange = useCallback(
    (e: any) => {
      metaData.onChange(e.target.value);
    },
    [metaData]
  );
  const isChecked = value === metaData.selectedValue;

  if (!metaData) {
    return (
      <inq-radiobutton>
        Must be nested inside InqRadioButtonGroup
      </inq-radiobutton>
    );
  }

  return (
    <inq-radiobutton id={id} class={className}>
      <input
        id={radioButtonId}
        ref={radioButtonRef}
        type="radio"
        name={metaData.groupName}
        value={value}
        onChange={handleChange}
        checked={isChecked}
        tabIndex="0"
      ></input>
      <label htmlFor={radioButtonId}>
        <radio-icon class={isChecked ? "checked" : ""}>
          {svgHollow}
          {svgPip}
        </radio-icon>
        <inq-label>
          {label}
          {children}
        </inq-label>
      </label>
    </inq-radiobutton>
  );
}

export default InQRadioButton;
