import React, { useState, useEffect } from "react";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import "./ScreenWaitingInQueue.scss";
import { useExtendedTranslation } from "../../Shared/Services/i18nService";
import { eorlaAverageWaitTime, presentInPerson } from "./../../Shared/Services/branding";
import { Link } from "react-router-dom";
import {
  watchCurrentTicket,
  watchUserData,
  setSMS,
} from "../../Services/DataService";
import QueueViewer from "../../Shared/Components/QueueViewer/QueueViewer";
import TextBlockDescription from "../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import TextBlockHint from "../../Shared/Components/StandardStyles/TextBlockHint/TextBlockHint";
import { useObservable } from "./../../Shared/Hooks/useObservable";
import InQCheckBox from "../../Shared/Components/InQCheckBox/InQCheckBox";

function ScreenWaitingInQueue() {
  const avgWaitTime = eorlaAverageWaitTime();
  const showInPerson = presentInPerson();
  const data = useObservable(watchUserData());
  const ticket = useObservable(watchCurrentTicket());
  const t = useExtendedTranslation();
  const [allowSMS, setAllowSMS] = useState(data.allowSMS);

  useEffect(() => {
    setAllowSMS(data.allowSMS);
  }, [data]);

  const handleCheck = async (isChecked: boolean) => {
    setAllowSMS(isChecked);
    await setSMS(isChecked);
  };

  return (
    <screen-waiting-in-queue>
      <SinglePanelLayout
        footer={
          <screen-waiting-bottom-leave-button>
            <Link to="/confirm-leave-queue">{t("waiting.leave.button")}</Link>
          </screen-waiting-bottom-leave-button>
        }
        title={t("waiting.title")}
      >
        <TextBlockDescription>{t("waiting.message")}</TextBlockDescription>

        <QueueViewer
          top={t("waiting.counter.prefix")}
          bottom={t("waiting.counter.suffix")}
          number={ticket ? ticket.order + 1 : undefined}
        />

        <TextBlockDescription>{t(avgWaitTime)}</TextBlockDescription>

        <TextBlockDescription>{t("waiting.notification")}</TextBlockDescription>

        <TextBlockDescription>{t(showInPerson)}</TextBlockDescription>

        <InQCheckBox
          label={t("waiting.sms.checkbox")}
          checked={allowSMS}
          onChange={handleCheck}
        />

        <TextBlockHint>
          {allowSMS
            ? t("waiting.leave.message.sms.allowed")
            : t("waiting.leave.message.sms.not.allowed")}
        </TextBlockHint>
      </SinglePanelLayout>
    </screen-waiting-in-queue>
  );
}

export default ScreenWaitingInQueue;
