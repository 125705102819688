export function textToArrayBuffer(text: string): ArrayBuffer {
  return new TextEncoder().encode(text);
}

export function byteStringToArrayBuffer(byteString: string): ArrayBuffer {
  const temp = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    temp[i] = byteString.charCodeAt(i);
  }
  return temp.buffer;
}

export function arrayBufferToByteString(buffer: ArrayBuffer): string {
  const ctArray = Array.from(new Uint8Array(buffer));
  return ctArray.map((byte) => String.fromCharCode(byte)).join("");
}

export function arrayBufferToText(buffer: ArrayBuffer): string {
  return new TextDecoder().decode(buffer);
}
