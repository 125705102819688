const _ = require('lodash');
const location = require('./location');
const { getLocations: getLocationsUtils } = require("./locations");
const queue = require("./queue");
const queueStats = require("./queueStats");
const { waitForInvocationReaction } = require("./reactions_utils");
const { assertConnection: assertConnectionUnwrapped } = require("./utils");

function assertConnection() {
  try {
    return assertConnectionUnwrapped();
  }
  catch(err) {
    if(window.onFatalError){
      window.onFatalError(err);
    }
  }
}

async function allErrorsAreFatal(fn) {
  try {
    return await fn();
  }
  catch(err) {
    if (window.onFatalError) {
      window.onFatalError(err);
    }
  }
}

async function sessionErrorsAreFatal(fn) {
  try {
    return await fn();
  }
  catch(err) {
    if (!err.status) {
      return;
    }
    var isFatal = false;
    if (err.status === 404) {
      console.error("JS and Hive API's are not in sync, make sure hive got updated");
      isFatal = true;
    }
    if (err.status === 403) {
      console.error("Session permission error");
      isFatal = true;
    }
    if (window.onFatalError && isFatal) {
      window.onFatalError(err);
    }
  }
}

const REACTION_TIMEOUT = 30000;

/**
 * @class Admin
 * @description Represents an administrator of a queue and location. The application must be connected to Hive
 * with proper credentials and roles.
 */
class Admin {
  constructor() {}

  /**
   * @memberof Admin
   * @description Returns the available locations.
   * @returns {Promise<Array>} An array containing the locations. Each location in the array contain location information
   * (address, location, initial queues, etc).
   */
  getLocations(callerLatitude, callerLongitude) {
    return getLocationsUtils(callerLatitude, callerLongitude);
  }

  /**
   * @memberof Admin
   * @description Fetches the queues available for a specfied locatoin
   * @param {String} locationId - The id of the location to query.
   * @returns {Promise<array>} - An array with information about the queues in the specified location.
   */
  getQueuesByLocation(locationId) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.storage
      .executeQuery("vwroom", "getLocationQueues", [locationId]))
      .then((queues) => {
        return queues;
      });
  }

  /**
   * @memberof Admin
   * @description Obtains an observable to the specified queue.
   * @param {String} queueId - The id of the queue to monitor.
   * @returns {Observable<IQueue>} - An observable that receives notifications when the queue changes.
   */
  getQueue(queueId) {
    const connection = assertConnection();

    return queue.observe(connection.bee, queueId);
  }

  /**
   * @memberof Admin
   * @description Gets the initial queue id for a location.
   *
   * @param {ILocation} location - The location of the queue to get.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  getInitialQueueId(location) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.storage
      .executeQuery("vwroom", "queueByLocationAndName", [location.id, location.initialQueueName]))
      .then((queues) => {
        if (queues && queues.length) {
          return queues[0].id;
        }
        return undefined;
      });
  }

  /**
   * @memberof Admin
   * @description Updates the business hours of a location.
   *
   * @param {String} locationId - The id of the location to update.
   * @param {Array} hours - The array of business hours.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  updateLocationBusinessHours(locationId, hours) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.updateLocationBusinessHours", locationId, hours))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Updates the queue hours of a location.
   *
   * @param {String} locationId - The id of the location to update.
   * @param {Array} hours - The array of queue hours.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  updateLocationQueueHours(locationId, hours) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.updateLocationQueueHours", locationId, hours))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Updates the capacity of a location.
   *
   * @param {String} locationId - The id of the location to update.
   * @param {Number} capacity - The new capacity.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  updateLocationCapacity(locationId, capacity) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.updateLocationCapacity", locationId, capacity))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Updates the max entrant time and grace period of a location.
   *
   * @param {String} locationId - The id of the location to update.
   * @param {Number} maxEntrantTime - The new max entrant time in seconds.
   * @param {Number} gracePeriodSeconds - The new grace period in seconds.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  updateLocationEntrantTimeouts(locationId, maxEntrantTime, gracePeriodSeconds) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.updateLocationEntrantTimeouts", locationId, maxEntrantTime, gracePeriodSeconds))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Obtains an observable to the specified location.
   * @param {String} locationId - The id of the location to monitor.
   * @returns {Observable<Object>} - An observable that receives notifications when the location changes.
   */
  getLocation(locationId) {
    const connection = assertConnection();
    return location.observe(connection.bee, locationId);
  }

  /**
   * @memberof Admin
   * @description Moves the specified queue item into the entrants list.
   *
   * @param {String} queueItemId - The id of the queue item to move.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  moveItemToNext(queueItemId) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.moveItemToNext", queueItemId))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Removes the specified queue item from its queue.
   *
   * @param {String} queueItemId - The id of the queue item to remove.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  removeQueueItem(queueItemId) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.removeItemFromQueue", queueItemId))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Checks in a new user at the end of a location's initial queue.
   * @param {String} - The id of the location to check into.
   * @param {String} - The name of the user checking in.
   * @param {String} - The phone number of the user checking in
   * @param {String} - The preferred lanugage of the user
   * @param {String} clientApp - the name of the application performing the checkin
   * @returns {Promise<Object>} - the queue item that was checked in
   */
  checkInFromLocation(newQueueItem, clientApp) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.checkInFromLocation', newQueueItem, clientApp))
      .then(waitForInvocationReaction(connection.bee, r => _.get(r, 'details.message'), REACTION_TIMEOUT))
    ;
  }

  /**
   * @memberof Admin
   * @description Moves an item to a new position within its queue.
   * @param {String} queueItemId - The id of the queue item to move.
   * @param {Number} position - The new position of the queue item.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  moveQueueItem(queueItemId, position) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.moveQueueItemPosition", queueItemId, position))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Marks a queue item's document as verified.
   * @param {String} queueItemId - The queue item that owns the document
   * @param {String} documentId - The id of the document to verify.
   * @param {Number} verified - Whether the document is verified or not.
   * @returns {Promise} - A promise that resolved when the operation success, or rejects when it fails.
   */
  verifyQueueItemDocument(queueItemId, documentId, verified) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke(
        "vwroom.verifyQueueItemDocument",
        queueItemId,
        documentId,
        verified
      ))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Get an observable to the specified queue stats.
   * @param {String} queueId - the id of the queue whose stats we are interesting in.
   * @returns {Observable<Object>} - An observable to a stat observable.
   */
  getStatsForQueue(queueId) {
    const connection = assertConnection();
    return queueStats.observe(connection.bee, queueId);
  }

  /**
   * @memberof Admin
   * @description Adds person to the specified location
   * @param {String} locationId - The id of the location to modify.
   * @returns {Promise} - a promise that resolves when the operation completes, or is rejected when it fails.
   */
  addPersonToLocation(locationId) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.addPerson', locationId))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Removes person to the specified location
   * @param {String} locationId - The id of the location to modify.
   * @returns {Promise} - a promise that resolves when the operation completes, or is rejected when it fails.
   */
  removePersonFromLocation(locationId) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.removePerson', locationId))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Adds a "to client" message to the item.
   * @param {String} itemId - The id of the queue item that the message will be added to.
   * @param {String} text - The text of the message to add.
   * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  addMessageToClientToItem(itemId, text) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.addMessageToClientToItem', itemId, text))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Sets the manuallyVerified flag on a queue item.
   * @param {String} itemId - the id of the queue item to modify.
   * @param {Boolean} isVerified - the value of the flag to set.
   * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  setManuallyVerified(itemId, isVerified) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.setManuallyVerified', itemId, isVerified))
      .then(waitForInvocationReaction(connection.bee, () => {}, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Updates the visitor flags on a queue item
   * @param {*} itemId - the id of the queue item to modify
   * @param {*} flags - the new value of the flags, leave as undefined to avoid changing the value.
   * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  setVisitorFlags(itemId, flags) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.setVisitorFlags', itemId, flags))
        .then(waitForInvocationReaction(connection.bee, () => {}, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Updates the values of manual queue control for location
   * @param {*} locationId - The id of the location to update
   * @param {*} isQueueOpen - the value of the flag to set
   * @param {*} isQueueManuallyControlled - the value of the flag to set
   * @returns {Promise<any>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  setManualQueueControl(locationId, isQueueOpen, isQueueManuallyControlled) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke(
        'vwroom.setManualQueueControl',
        locationId,
        isQueueOpen,
        isQueueManuallyControlled))
        .then(waitForInvocationReaction(connection.bee, () => {}, REACTION_TIMEOUT));
  }

  /**
   * @memberof Admin
   * @description Marks that person has arrived.
   * @param {String} itemId - the id of the queue item to modify.
   * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  markItemAsArrived(itemId) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.markItemAsArrived', itemId))
      .then(waitForInvocationReaction(connection.bee, () => {}, REACTION_TIMEOUT));
  }

  /**
   * @memberof Client
   * @description Starts or retrieves the conversation ID for chatting with the owner of a queue item.
   *
   * @param {Object} item - a queue item *object*
   * @returns <Promise<String>> - a promise that returns the conversation id on success or is rejected on failure.
   */
  getOrStartConversation(item) {
    const conversationId = _.get(item, 'properties.smsConversationId');
    if (conversationId) {
      return Promise.resolve(conversationId);
    }

    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.getOrStartConversation', item.id))
      .then(waitForInvocationReaction(connection.bee, r => _.get(r, 'details.uuid'), REACTION_TIMEOUT));
  }

  /**
   * @memberof Client
   * @description Counts the number of queue items by client app in a time range.
   *
   * @param {String} startDate - an ISO-8601 UTC date
   * @param {String} endDate - an ISO-8601 UTC date
   *
   * @returns <Promise<String>> - a promise that returns the counts by client app on success or is rejected on failure.
   */
  countQueueItemsByClientApp(startDate, endDate) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.countQueueItemsByClientApp', startDate, endDate) )
      .then(waitForInvocationReaction(connection.bee, r => _.get(r, 'details.message'), REACTION_TIMEOUT))
      .then(res => _.reduce(res, (a, v) => {
        a[v.group] = v.count;
        return a;
      }, {}));
  }
}

module.exports = Admin;
