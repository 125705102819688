import { Observable } from "rxjs";

/**
 * Whether the message has been sent or received from the perspective of the user viewing the chat window
 */
export enum MessageDirection {
  Sent = "Sent",
  Received = "Received",
}

/**
 * What kind of user sent the message
 */
export enum ParticipantType {
  User = "User",
  System = "System",
  Admin = "Admin",
}

/**
 * The delivery status of an individual message
 */
export enum MessageState {
  Pending = "Pending",
  Received = "Received",
  Delivered = "Delivered",
  Removed = "Removed",
}

/**
 * The format that a message has been encoded with
 */
export enum MessageFormat {
  /**
   * No formatting markup, just unicode text
   */
  Plaintext = "Plaintext",
}

/**
 * Whether the system beleives the user is active or not based on their recent interactions
 */
export enum ParticipantStatus {
  Active = "Active",
  Inactive = "Inactive",
}

/**
 * A low level version of message where persistent object references don't exist yet.  This is not intended for use outside chat service
 */
export interface ChatApi_Message {
  id: string;
  authorId: string;
  whenSent: Date;
  lastUpdated?: Date;
  message: string;
  messageFormat?: MessageFormat;
  state?: MessageState;
  hasBeenEdited?: boolean;
}

/**
 * A chat message
 */
export interface Message {
  id: string;
  author: Participant;
  whenSent: Date;
  lastUpdated: Date;
  message: string;
  messageFormat: MessageFormat;
  state: MessageState;
  hasBeenEdited: boolean;
  readBy: Participant[];
  lastReadLocationFor: Participant[];
}

/**
 * A chat message decorated with properties specific to the perspective of the user viewing the chat window
 */
export interface MessageView extends Message {
  direction: MessageDirection;
  readBy: ParticipantView[];
  lastReadLocationFor: ParticipantView[];
}

export interface ChatApi_Participant {
  name?: string;
  id: string;
  type: ParticipantType;
  avatarUrl?: string;
}

/**
 * A participant in a chat conversation
 */
export interface Participant extends ChatApi_Participant {
  name: string;
  avatarUrl: string;
  status: ParticipantStatus;
}

/**
 * A view of a participant decorated with properties specific to the perspective of the user viewing the chat window
 */
export interface ParticipantView extends Participant {}

/**
 * A description of a conversation between multiple people
 */
export interface Chat {
  readonly whoIsTyping: Observable<Participant[]>;
  readonly title: string;
  readonly id: string;
  readonly messages: Observable<Message[]>;
  readonly participants: Observable<Participant[]>;
  readonly participantStatus: Observable<Map<Participant, ParticipantStatus>>;
  send(author: Participant, message: Partial<MessageView>): void;
  userIsTyping(who: Participant): void;
  markRead(who: Participant, message: MessageView): void;
}

/**
 * A description of a conversation between multiple people decorated with properties and functions specific to the perspective of the user viewing the chat window
 */
export interface ChatView {
  readonly whoIsTyping: Observable<ParticipantView[]>;
  readonly title: string;
  readonly id: string;
  readonly messages: Observable<MessageView[]>;
  readonly participants: Observable<ParticipantView[]>;
  readonly participantStatus: Observable<
    Map<ParticipantView, ParticipantStatus>
  >;
  readonly unreadMessages: Observable<MessageView[]>;
  readonly perspective: ParticipantView;
  readonly others: Observable<ParticipantView[]>;
  readonly unreadMessageCount: Observable<number>;
  readonly hasUnreadMessages: Observable<boolean>;

  /**
   * Sends a message from the user viewing the chat window to the chat
   * @param message The message to be sent
   */
  send(message: Partial<MessageView>): void;
  /**
   * Hints that the user viewing the chat window is currently typing
   */
  userIsTyping(): void;
  /**
   * Marks that a specific message has been read by the user viewing the chat window
   * @param message The message that was read
   */
  markRead(message: MessageView): void;
}

/**
 * Indicates a participant has read a specific message
 */
export interface ChatApi_ReadMarker {
  participantId: string;
  messageId: string;
  timestamp: Date;
}

export interface ChatApi_TypingNotification {
  participantId: string;
  timestamp: Date;
}

/**
 * This is a lower level event driven API.  It is intended to be consumed by the Chat Service and used to interface with the messaging back end.
 * Please do not directly access this API from anywhere except the chat service
 */
export interface ChatApi {
  onMessage: Observable<ChatApi_Message[]>;
  onRead: Observable<ChatApi_ReadMarker[]>;
  onTyping: Observable<ChatApi_TypingNotification[]>;
  onParticipants: Observable<ChatApi_Participant[]>;
  /**
   * Hint to the implementing class that this resource will no longer be used so that any resources used can be release
   */
  dispose(): void;
  /**
   * Sends a message;
   * @param message The message to be sent
   */
  sendMessage(message: Partial<ChatApi_Message>): void;
  /**
   * Deletes a message (if supported)
   * @param messageId The message to be deleted
   */
  deleteMessage(messageId: string): void;
  /**
   * Hints that a user is typing
   * @param userId The id of the user that's typing
   */
  userIsTyping(userId: string): void;
  /**
   * Marks that a specific user has read a specific message
   * @param userId The user who read the message
   * @param messageId The message that was read
   */
  markRead(userId: string, messageId: string): void;
}
