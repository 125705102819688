import _get from "lodash/get";
import _split from "lodash/split";
import { getLanguage, SupportedLanguages } from "./i18nService";
import environmentVar from "../environment.json";

let defaultBranding = {
  name: "Lifecare",
  properties: {
    logoImage: {
      en: `Lifecare_EN.svg`,
      fr: `Lifecare_FR.svg`,
    },
    appName: {
      en: "Lifecare HC",
      fr: "Lifecare CH",
    },
    highPrioritySelfIndicate: {
      en: "I am a transplant patient",
      fr: "J’ai reçu une greffe d’organe",
    },
    welcomeScreenDescription: {
      en: "At Lifecare HC, we care about your safety, and to respect the social distancing recommendation, we have put in place this virtual waiting room.",
      fr: "Chez Lifecare CH, nous avons votre sécurité à coeur. Pour respecter les recommendations de distanciation sociale, nous avons mis en place cette salle d'attente virtuelle."
    },
  },
};

// Move this to Hive
let chiromaxBranding = {
  name: "Chiromax",
  properties: {
    logoImage: {
      en: `chiromax_logo.svg`,
      fr: `chiromax_logo.svg`,
    },
    appName: {
      en: "Chiromax",
      fr: "Chiromax",
    },
    welcomeScreenDescription: {
      en: "At Chiromax, we care about your safety, and to respect the social distancing recommendation, we have put in place this virtual waiting room.",
      fr: "Chez Chiromax, nous avons votre sécurité à coeur. Pour respecter les recommendations de distanciation sociale, nous avons mis en place cette salle d'attente virtuelle."
    },
  },
};
let eorlaBranding = {
  name: "EORLA",
  properties: {
    logoImage: {
      en: `eorla.svg`,
      fr: `eorla.svg`,
    },
    appName: {
      en: "EORLA",
      fr: "ALREO",
    },
    welcomeScreenDescription: {
      en: "At EORLA, we value your time and safety. This app allows you to check in for your laboratory visit and creates a virtual waiting room where patients are physically distanced. Once you are checked in, the app will tell you when it’s your turn to come to the laboratory.",
      fr: "À l’ALREO, nous accordons une grande importance à votre temps et à votre sécurité. Cette application vous permet de vous enregistrer pour votre visite au laboratoire et crée une salle d’attente virtuelle où les patients sont physiquement éloignés. Une fois que vous êtes enregistré, l’application vous indiquera le moment de vous présenter au laboratoire."
    },
    eorlaAverageWaitTime: {
      en: "On average each lab visit takes 8-15 minutes.",
      fr: "En moyenne, chaque visite de laboratoire dure de 8 à 15 minutes.",
    },
    presentInPerson: {
      en: "If you have not been called after 2 hours or the application is not working for you, please present to the lab in person",
      fr: "Si vous n’avez pas été appelé après 2 heures ou si l’application ne fonctionne pas pour vous, veuillez vous présenter en personne au laboratoire",
    },
  },
};

// TODO: Get the branding from Hive instead of using an env variable
let currentBranding = (() => {
  switch (_get(environmentVar, "branding")) {
    case "chiromax":
      return chiromaxBranding;
    case "eorla":
      return eorlaBranding;
    default:
      return defaultBranding;
  }
})();

export function setCurrentBrand(brandName: string) {
  // TODO: set currentBranding from server
}

function getBrandPropFromThisBrand(
  brand: any,
  propName: string,
  fullLang = getLanguage()
): any {
  const props = _get(brand, ["properties", propName]);
  if (!props) {
    return undefined;
  }

  let prop = _get(props, [fullLang], null);
  if (prop !== null) {
    return prop;
  }

  // try without region (eg "en" of "en-US")
  let lang = _split(fullLang, "-", 1)[0];
  if (lang !== fullLang) {
    prop = _get(props, [lang], null);
    if (prop !== null) {
      return prop;
    }
  }

  // default to English
  if (fullLang === SupportedLanguages.English) {
    return undefined;
  }
  return getBrandProp(propName, SupportedLanguages.English);
}

function getBrandProp(propName: string, fullLang?: SupportedLanguages) {
  return (
    getBrandPropFromThisBrand(currentBranding, propName, fullLang) ||
    getBrandPropFromThisBrand(defaultBranding, propName, fullLang)
  );
}

export function logoImage(): string {
  return getBrandProp("logoImage");
}

export function appName(): string {
  return getBrandProp("appName");
}

export function highPrioritySelfIndicate(): string {
  return getBrandProp("highPrioritySelfIndicate");
}

export function welcomeScreenDescription(): string {
  return getBrandProp("welcomeScreenDescription");
}

export function eorlaAverageWaitTime(): string {
  return getBrandProp("eorlaAverageWaitTime");
}

export function presentInPerson(): string {
  return getBrandProp("presentInPerson");
}
