/**
 * If the app has been running for more than 16 hours, reload.
 * Also if the app has been idle for more than 1 minute, reload.
 * This will most likely occur when a person returns to work after having gone home for the day and they first click on the tab in their browser.
 * As such the person won't even have had a chance to click on anything in the page itself, the page will appear to just load normally.
 * Reloading the page is both necessary and sufficient for restoring the hive connection and ensuring that application state reflects the hive state
 */
const MAX_UPTIME = 16 * 60 * 60 * 1000;
const MAX_IDLE = 1 * 60 * 1000;
const CHECK_FREQUENCY = 1 * 1000;

const whenStarted = Date.now();
var showUpTimeFrequency = -1;
var lastDisplayedUptime: number = 0;
var lastTick: number = whenStarted;

function check() {
  const now = Date.now();
  const uptime = now - whenStarted;
  const timeSinceLastTick = now - lastTick;
  lastTick = now;
  if (uptime > MAX_UPTIME || timeSinceLastTick > MAX_IDLE) {
    reload();
  }
  if (
    showUpTimeFrequency > 0 &&
    now - lastDisplayedUptime > showUpTimeFrequency
  ) {
    lastDisplayedUptime = now;
    console.log("App up-time", uptime);
  }
}

function reload() {
  try {
    window.location.reload();
  } catch (ignored) {}
}

export function showUpTime(howOften?: number) {
  showUpTimeFrequency = howOften && howOften > 0 ? howOften : -1;
}

setInterval(check, CHECK_FREQUENCY);
