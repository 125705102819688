import { BehaviorSubject } from "rxjs";

const intervalKey = Symbol("Interval Key");

interface DecoratedSubject<T> extends BehaviorSubject<T> {
  [intervalKey]: ReturnType<typeof setInterval>;
  cancel: () => void;
  reset: () => void;
  pause: () => void;
  resume: () => void;
}

export function createClock(speed: number = 1000, initialValue: number = 0) {
  const clock: DecoratedSubject<number> = (new BehaviorSubject(
    initialValue
  ) as any) as DecoratedSubject<number>;
  let count = 0;
  const startClock = () => {
    clock[intervalKey] = setInterval(() => clock.next(count++), speed);
  };
  const stopClock = () => {
    clearInterval(clock[intervalKey]);
  };
  startClock();
  clock.cancel = () => {
    stopClock();
    clock.complete();
    clock.cancel = () => {};
  };
  clock.pause = () => {
    stopClock();
  };
  clock.resume = () => {
    startClock();
  };
  clock.reset = () => {
    count = 0;
  };
  return clock;
}
