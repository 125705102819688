import React, { useEffect, useState, useCallback } from "react";
import "./ScreenWhatsNearby.scss";
import {
  watchGpsLocation,
  GpsLocationCoordinates,
} from "../../Shared/Services/locationService";
import {
  updateGpsLocationNow,
  usersGpsLocationHasBeenDetermined,
} from "../../Shared/Services/locationService";
import { Button } from "@material-ui/core";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import {
  TextBlockDescription,
  TextBlockHint,
} from "../../Shared/Components/StandardStyles";
import { useExtendedTranslation } from "../../Shared/Services/i18nService";
import {
  useObservable,
  useMaybeObservable,
  useInstanceConst,
  useDictState,
} from "./../../Shared/Hooks";
import { createClock } from "../../Shared/observableClock";
import { Spinner, SevenSegmentDisplay } from "../../Shared/Components";
import {
  watchAllLocations,
  setSelectedLocation,
} from "../../Services/DataService";
import { sortBy, Dict } from "../../Shared/util";
import { ILocation } from "../../Shared/Types/IClient";
import { Subscription } from "rxjs";
import { watchQueueAtLocation } from "./../../Services/DataService";
import { calculateDistanceInMeters } from "./../../Shared/Services/locationService";
import { GpsFixed, GpsNotFixed, SortByAlpha } from "@material-ui/icons";
import { createSyncStorageInterface } from "./../../Shared/Services/Storage";
import { useInit } from "./../../Shared/Hooks/useInit";

const NORMAL_GPS_WAIT_TIME_SECONDS = 10; // maybe the phone can't find enough satellites or it's overcast
const MAX_REASONABLE_WAIT_TIME_SECONDS = 20; //yeah no we're not getting a location anytime soon

const PrefStore = createSyncStorageInterface("whatsnearby");
const SORT_KEY = "sortby";
const SORT_BY_GPS = "gps";
const SORT_BY_ALPHA = "alpha";

function ScreenWhatsNearby() {
  const t = useExtendedTranslation();
  const timeSpentWaitingClock = useInstanceConst(() => {
    const result = createClock();
    result.pause();
    return result;
  });
  const [sortByGps, setSortByGps] = useState(
    PrefStore.getItem(SORT_KEY) === SORT_BY_GPS
  );

  const timeSpentWaiting = useObservable(timeSpentWaitingClock);
  const gpsPosition = useMaybeObservable(watchGpsLocation());
  const allLocations = useMaybeObservable(watchAllLocations());
  const queueSubscriptions: Dict<string, Subscription> = useInstanceConst(
    {} as any
  );
  const [peopleInQueue, updatePeopleInQueue] = useDictState<string, number>();
  const [gaveUpOnGps, setGaveUpOnGps] = useState(false);

  const sortedListOfLocations = allLocations ? [...allLocations] : [];
  const sortFnGps = sortBy((loc: ILocation) =>
    calculateDistanceInMeters(gpsPosition || {}, GpsLocationCoordinates(loc))
  );
  const sortFnAlpha = sortBy((loc: ILocation) => loc.name.toLocaleLowerCase());
  const sortFn = sortByGps ? sortFnGps : sortFnAlpha;

  sortedListOfLocations.sort(sortFn);

  const [uiModeIsList, setUiModeToList] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);

  const showList =
    uiModeIsList &&
    (!sortByGps || gaveUpOnGps || usersGpsLocationHasBeenDetermined()) &&
    !showSpinner;
  const showMap =
    !uiModeIsList &&
    (gaveUpOnGps || usersGpsLocationHasBeenDetermined()) &&
    !showSpinner;
  const tookTooLong =
    showSpinner && timeSpentWaiting > NORMAL_GPS_WAIT_TIME_SECONDS;

  const startToUpdateGpsLocation = useCallback(() => {
    setShowSpinner(true);
    timeSpentWaitingClock.reset();
    timeSpentWaitingClock.resume();
    updateGpsLocationNow();
  }, [timeSpentWaitingClock]);
  useInit(() => {
    if (sortByGps) {
      startToUpdateGpsLocation();
    }
  });

  const setSortModeGps = useCallback(() => {
    setSortByGps(true);
    startToUpdateGpsLocation();
    PrefStore.setItem(SORT_KEY, SORT_BY_GPS);
  }, [startToUpdateGpsLocation]);
  const setSortModeAlpha = useCallback(() => {
    setSortByGps(false);
    PrefStore.setItem(SORT_KEY, SORT_BY_ALPHA);
  }, []);

  useEffect(() => {
    if (gpsPosition) {
      setShowSpinner(false);
      timeSpentWaitingClock.pause();
    }
  }, [gpsPosition, timeSpentWaitingClock]);

  useEffect(() => {
    if (timeSpentWaiting > MAX_REASONABLE_WAIT_TIME_SECONDS) {
      timeSpentWaitingClock.pause();
      setShowSpinner(false);
      setGaveUpOnGps(true);
    }
  }, [timeSpentWaiting, timeSpentWaitingClock]);
  const selectLocation = useCallback(
    (location: ILocation) => setSelectedLocation(location),
    []
  );
  useEffect(() => {
    if (allLocations && allLocations.length === 1) {
      selectLocation(allLocations[0]);
    }
  }, [allLocations, selectLocation]);
  useEffect(() => {
    if (!allLocations) {
      return;
    }

    allLocations.forEach((location) => {
      if (!queueSubscriptions[location.id]) {
        queueSubscriptions[location.id] = watchQueueAtLocation(
          location.id,
          location.initialQueueName
        ).subscribe((update) => {
          updatePeopleInQueue({ [location.id]: update.itemCount });
        });
      }
    });
    return () => {
      Object.entries(queueSubscriptions).forEach(([key, subscription]) => {
        subscription.unsubscribe();
        delete queueSubscriptions[key];
      });
    };
  }, [allLocations, queueSubscriptions, updatePeopleInQueue]);
  const busyUI = showSpinner ? (
    <busy-message>
      <Spinner />
      {tookTooLong ? t("whatsnearby.takingtoolong") : <></>}
    </busy-message>
  ) : (
    <></>
  );

  const modeSelector = (
    <>
      <Button onClick={() => setUiModeToList(true)}>
        {t("whatsnearby.list")}
      </Button>
      <Button onClick={() => setUiModeToList(false)}>
        {t("whatsnearby.map")}
      </Button>
    </>
  );

  const sortSelector = (
    <sort-selector class="sort-selector">
      {/* This classname has been appended as a workaround for overriding material-ui styles */}
      <Button
        onClick={setSortModeAlpha}
        color={sortByGps ? "secondary" : "primary"}
        variant={sortByGps ? "text" : "outlined"}
      >
        <SortByAlpha />
      </Button>
      <Button
        onClick={setSortModeGps}
        color={!sortByGps ? "secondary" : "primary"}
        variant={!sortByGps ? "text" : "outlined"}
      >
        {usersGpsLocationHasBeenDetermined() ? <GpsFixed /> : <GpsNotFixed />}
      </Button>
    </sort-selector>
  );

  const nav = (
    <in-screen-nav>
      <TextBlockDescription>
        {t("whatsnearby.description")}
      </TextBlockDescription>
      {sortSelector}
      {
        modeSelector &&
          false /* hidden because we're not implementing map view at this time */
      }
    </in-screen-nav>
  );

  const replaceUndefined = (v: any, replacement: any) =>
    v === undefined ? replacement : v;

  const listItem = (entry: ILocation) => {
    return (
      <li key={entry.name} onClick={() => selectLocation(entry)}>
        <span>{entry.name}</span>
        <queue-viewer>
          <SevenSegmentDisplay
            message={replaceUndefined(peopleInQueue[entry.id], "")}
            pad={3}
          />
          <span>{t("whatsnearby.queued")}</span>
        </queue-viewer>
      </li>
    );
  };
  const list = showList ? (
    <>
      <ul>{sortedListOfLocations?.map(listItem)}</ul>
      <TextBlockHint>{t("whatsnearby.hint")}</TextBlockHint>
    </>
  ) : (
    <></>
  );

  const map = showMap ? t("whatsnearby.placeholder") : <></>;

  const content =
    showList || showMap ? (
      <panel-contents>
        {nav}
        {list}
        {map}
      </panel-contents>
    ) : (
      <></>
    );

  return (
    <screen-whats-nearby>
      <SinglePanelLayout
        title={t.map("whatsnearby.title", [
          t.plainText,
          (name) => <app-title>{name}</app-title>,
          t.plainText,
        ])}
      >
        {busyUI}
        {content}
      </SinglePanelLayout>
    </screen-whats-nearby>
  );
}

export default ScreenWhatsNearby;
