import React from "react";
import { withRouter } from "react-router-dom";
import { Spinner } from "./../../Shared/Components/Spinner/Spinner";
import { setHistoryInterface } from "./../../Services/WorkflowService";

function WorkflowRouteInternal(props: any) {
  setHistoryInterface(props.history);
  return <Spinner></Spinner>;
}

const WorkflowRoute = withRouter(WorkflowRouteInternal);
export default WorkflowRoute;
