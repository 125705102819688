import { Participant, Chat, ChatView, ParticipantType } from "./chat.model";
import { memoizeFunction, setImmediate } from "../../util";
import { openChatApi } from "./chat.api.hive";
import { ChatImplementation } from "./chat.class";
import { ChatViewImplementation } from "./chatview.class";
import { isFeatureEnabled, FeatureFlags } from "../featureFlags";

const MAX_TIME_TO_WAIT_FOR_PARTICIPANT_REGISTRATION_IN_MS = 10 * 1000;

function createChatViewForParticipant(
  chat: Chat,
  participant: Participant
): ChatView {
  return new ChatViewImplementation(chat, participant);
}

async function createChatById(chatId: string): Promise<Chat> {
  return new ChatImplementation(await openChatApi(chatId), chatId);
}

function createChatView_NotMemoized(
  chatId: string,
  participantType: ParticipantType
): Promise<ChatView> {
  if (!isFeatureEnabled(FeatureFlags.Chat)) {
    return new Promise((r) => {}) as any;
  }
  return new Promise<ChatView>(async (resolve, reject) => {
    try {
      const chat = await createChatById(chatId);
      const watchParticipants = chat.participants.subscribe((list) => {
        try {
          const user = list.find((user) => user.type === participantType);
          if (user) {
            setImmediate(() => watchParticipants.unsubscribe());
            const chatView = createChatViewForParticipant(chat, user);
            resolve(chatView);
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
      setTimeout(
        () => reject("Couldn't find matching user"),
        MAX_TIME_TO_WAIT_FOR_PARTICIPANT_REGISTRATION_IN_MS
      );
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
}

/**
 * Creates a chat view for a specific chat conversation using the specified view.
 * This function assumes that that there will be at most one participant per participant type.
 * @param chatId
 * @param participantType
 */
export const createChatView = memoizeFunction(
  createChatView_NotMemoized,
  (chatId, participantType) => chatId + "|" + participantType
);
