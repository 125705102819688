import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";

function maybeToString(text?: string): any {
  return text ? text.toString() : undefined;
}

export type InQFieldProps = Omit<TextFieldProps, "onChange" | "onInput"> & {
  onChange?: (value: string) => void;
  onInput?: (value: string) => void;
  onEnter?: () => void;
  hint?: React.ReactNode;
};

export function InQFieldDefaultInput(props: InQFieldProps) {
  const { children, onChange, onInput, onEnter, ...moreProps } = props;

  if (children !== undefined) {
    if (
      props.required ||
      props.placeholder ||
      props.value ||
      props.InputProps ||
      props.error ||
      props.id ||
      onChange ||
      onInput ||
      onEnter
    ) {
      throw new Error(
        `InQFieldDefaultInput (${props.label}): You have provided a child element so this component is working in custom field mode.  As a result you must remove all data bindings from InQFieldDefaultInput except for label`
      );
    }
    return children;
  } else {
    if (
      props.value === undefined &&
      onChange === undefined &&
      onInput === undefined
    ) {
      throw new Error(
        `InQFieldDefaultInput (${props.label}): You have not provided a child element so this component is working in TextField/Readonly mode.  As a result you must provide either a value or an onChange handler`
      );
    }

    if (onChange || onInput) {
      const wrappedOnChange = onChange
        ? (e: any) => onChange(maybeToString(e?.target?.value))
        : (e: any) => {};
      const wrappedOnInput = onInput
        ? (e: any) => onInput(maybeToString(e?.target?.value))
        : (e: any) => {};
      const wrappedOnEnter = onEnter
        ? (e: any) => {
            if (e.keyCode === 13) {
              onEnter();
            }
          }
        : (e: any) => {};

      return (
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          onChange={wrappedOnChange}
          onInput={wrappedOnInput}
          onKeyUp={wrappedOnEnter}
          {...moreProps}
        />
      );
    } else {
      return props.value;
    }
  }
}

export default InQFieldDefaultInput;
