import React from "react";
import i18n, { TFunction, StringMap } from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { MapFn, setImmediate } from "../../Shared/util";
import KeyedFragment from "./../Components/KeyedFragment";

export enum SupportedLanguages {
  English = "en",
  French = "fr",
}

const LANGUAGE_KEY = "i18nextLng";
const DEFAULT_LANGUAGE = SupportedLanguages.English;

let rotateInterval: number;

export function init(en: any, fr: any) {
  const resources = {
    en,
    fr,
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
      resources,

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
}

export function getLanguage(): SupportedLanguages {
  const fromStore = localStorage.getItem(LANGUAGE_KEY) as SupportedLanguages;
  if (!fromStore) {
    setLanguage(DEFAULT_LANGUAGE);
  }
  return fromStore || DEFAULT_LANGUAGE;
}

export function getLanguageCodes(): SupportedLanguages[] {
  return Object.values(SupportedLanguages);
}

/**
 * Sets the language and updates the users settings so that next time the page will load with thier chosen language by default
 * @param newLanguage The language to use
 * @param reloadPage If true the page will be reloaded to ensure the entire app including all loaded data is switched to the correct language
 */
export function setLanguage(
  newLanguage: SupportedLanguages,
  reloadPage: boolean = false
) {
  localStorage.setItem(LANGUAGE_KEY, newLanguage);
  i18n.changeLanguage(newLanguage);
  if (reloadPage) {
    window.location.reload(false);
  }
}

/**
 * Sets the language without updating the users settings
 * @param newLanguage The language to use.
 */
export function setLanguageTemporarily(newLanguage: SupportedLanguages) {
  const before = getLanguage();
  i18n.changeLanguage(newLanguage);
  localStorage.setItem(LANGUAGE_KEY, before);
}

/**
 * Rotates between English and French at a fixed interval
 * @param durationInMs [10000ms (10s)] How long to wait between language changes
 */
export function rotateLanguages(durationInMs: number = 10 * 1000) {
  if (rotateInterval) {
    clearInterval(rotateInterval);
    rotateInterval = 0;
  }
  if (durationInMs <= 0) {
    setLanguageTemporarily(getLanguage());
    return;
  }
  let tempLang = getLanguage();
  const doRotate = () => {
    setImmediate(() => {
      tempLang =
        tempLang === SupportedLanguages.English
          ? SupportedLanguages.French
          : SupportedLanguages.English;
      setLanguageTemporarily(tempLang);
    });
  };
  rotateInterval = setInterval(doRotate, durationInMs) as any;
}

interface ExtendedTranslator extends TFunction {
  /**
   * Splits a translated string by a delimter and returns the resulting array of strings
   * @param key The translation key to look for
   * @param props Optional properties to supply to the translation
   * @param delimiter "|" - Override the default delimiter
   */
  multiple(key: string, props?: StringMap, delimiter?: string): string[];
  /**
   * Maps over a delimited translation string applying one function to all, or one function per section
   * @param key The translation key to look for
   * @param mapFn Either a single function to apply to all sections, or an array of functions with one function per section
   * @param props Optional properties to supply to the translation
   * @param delimiter "|" - Override the default delimiter
   */
  map(
    key: string,
    mapFn: MapFn<string, JSX.Element> | MapFn<string, JSX.Element>[],
    props?: StringMap,
    delimiter?: string
  ): JSX.Element;
  /**
   * A shortcut for (text:string) => <>{text}</>
   */
  plainText: MapFn<string, JSX.Element>;
  useSpecificLanguage(lang: SupportedLanguages): ExtendedTranslator;
}

function multiple(
  t: TFunction,
  key: string,
  props?: StringMap,
  delimiter: string = "|"
): string[] {
  return t(key, props).split(delimiter);
}

function map(
  t: TFunction,
  key: string,
  mapFn: MapFn<string, JSX.Element> | MapFn<string, JSX.Element>[],
  props?: StringMap,
  delimiter: string = "|"
): JSX.Element {
  const tokens = multiple(t, key, props, delimiter);
  let mapped: JSX.Element[];
  if (Array.isArray(mapFn)) {
    if (tokens.length !== mapFn.length) {
      throw new Error(
        "Delimited Translation must have the same number of sections as the number of map functions provided in an array, or pass a single map function not in an array"
      );
    }
    mapped = tokens.map((text, i) => (
      <KeyedFragment key={i}>{mapFn[i](text)}</KeyedFragment>
    ));
  } else {
    mapped = tokens.map((text, i) => (
      <KeyedFragment key={i}>{mapFn(text)}</KeyedFragment>
    ));
  }
  return <>{mapped}</>;
}

function plainText(text: string): JSX.Element {
  return <>{text}</>;
}

function useSpecificLanguage(lang: SupportedLanguages): ExtendedTranslator {
  return useWrappedTranslationActual(
    i18n.getFixedT(lang === SupportedLanguages.English ? "en" : "fr")
  );
}

function useWrappedTranslation(): ExtendedTranslator {
  return useWrappedTranslationActual(useTranslation().t);
}

function useWrappedTranslationActual(ot: TFunction): ExtendedTranslator {
  const t = (ot as any) as ExtendedTranslator;
  t.map = map.bind(null, t);
  t.multiple = multiple.bind(null, t);
  t.plainText = plainText;
  t.useSpecificLanguage = useSpecificLanguage;
  return t;
}

export function i18nText(key: string, props?: StringMap) {
  return i18n.t(key, props);
}

export function i18nItemList(items: string[]): string {
  if (!items || items.length === 0) {
    return "";
  } else {
    return items
      .map((item, i) => {
        if (i === 0) {
          return i18nText("list.firstItem", { item });
        } else if (i === items.length - 1) {
          return i18nText("list.lastItem", { item });
        } else {
          return i18nText("list.middleItem", { item });
        }
      })
      .join("");
  }
}

export { useWrappedTranslation as useExtendedTranslation, useSpecificLanguage };
export default i18n;
