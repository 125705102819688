import React from "react";
import "./ChatDisplay.scss";
import ChatLog from "./../ChatLog/ChatLog";
import ChatTypingIndicator from "../ChatTypingIndicator/ChatTypingIndicator";
import ChatComposer from "../ChatComposer/ChatComposer";
import { ChatView } from "./../../../Services/Chat";
import { isFeatureEnabled, FeatureFlags } from "../../../Services/featureFlags";
import { Maybe } from "../../../util";
import { useMaybePromise } from "../../../Hooks";
import ErrorBoundary from "./../../ErrorBoundary/ErrorBoundary";
import { useExtendedTranslation } from "../../../Services/i18nService";
import { ChatError } from "./../ChatError/ChatError";

function ChatDisplayInternal({
  chat,
}: {
  chat: Maybe<Promise<Maybe<ChatView>>>;
}) {
  const chatResolved: Maybe<ChatView> = useMaybePromise(chat);

  return chatResolved && isFeatureEnabled(FeatureFlags.Chat) ? (
    <>
      <chat-title>{chatResolved.title}</chat-title>
      <chat-display>
        <ChatLog chat={chatResolved} />
        <top-fade></top-fade>
        <ChatTypingIndicator chat={chatResolved} />
        <ChatComposer chat={chatResolved} />
      </chat-display>
    </>
  ) : (
    <></>
  );
}

function GenericError() {
  const t = useExtendedTranslation();
  return <ChatError>{t("chatdisplay.generic_error")}</ChatError>;
}

function ChatDisplay({ chat }: { chat: Maybe<Promise<Maybe<ChatView>>> }) {
  return (
    <chat-area>
      <ErrorBoundary fallback={() => <GenericError />}>
        <ChatDisplayInternal chat={chat} />
      </ErrorBoundary>
    </chat-area>
  );
}

export default ChatDisplay;
