import React from "react";
import SinglePanelLayout from "../../../Components/Layout/SinglePanel/SinglePanel";
import TextBlockDescription from "../../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import { useExtendedTranslation } from "../../../Shared/Services/i18nService";
import TextBlockHint from "../../../Shared/Components/StandardStyles/TextBlockHint/TextBlockHint";
import { Button } from "@material-ui/core";

function reload() {
  window.location.reload();
}

function ScreenNoInternet() {
  const t = useExtendedTranslation();

  return (
    <screen-fatal-error>
      <SinglePanelLayout
        title={<>{t("nointernet.title")}</>}
        footer={
          <Button variant="contained" color="primary" onClick={reload}>
            {t("nointernet.reload")}
          </Button>
        }
      >
        <TextBlockDescription>
          {t("nointernet.description")}
        </TextBlockDescription>
        <TextBlockHint>{t("nointernet.hint")}</TextBlockHint>
      </SinglePanelLayout>
    </screen-fatal-error>
  );
}

export default ScreenNoInternet;
