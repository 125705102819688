const _ = require('lodash');
const { Observable } = require('rxjs');
const { handleBeeError } = require('./ConnectionService');
const { waitForInvocationReaction } = require('./reactions_utils');

const REACTION_TIMEOUT = 30000;

const formatLocation = location => ({
  id: location.id,
  name: location.properties.name,
  address: location.properties.address,
  city: location.properties.city,
  region:location.properties.region,
  country: location.properties.country,
  zipcode: location.properties.zipcode,
  latitude: location.properties.latitude,
  longitude: location.properties.longitude,
  initialQueueName: location.properties.initialQueueName,

  gracePeriodSeconds: location.properties.gracePeriodSeconds,
  nextEntrantCount: location.properties.nextEntrantCount,
  maxEntrantTime: location.properties.maxEntrantTime,

  capacity: location.properties.capacity,
  peopleCount: location.properties.peopleCount,

  timezone: location.properties.timezone,

  businessHours: location.properties.businessHours,
  queueHours: location.properties.queueHours,

  allowDocumentsUpload: location.properties.allowDocumentsUpload,
  healthCardRequired:location.properties.healthCardRequired,
  checkinDistance:location.properties.checkinDistance,
  supportedLanguages:location.properties.supportedLanguages,
  questionnaire:location.properties.questionnaire,
  isQueueOpen: location.properties.isQueueOpen,
  isQueueManuallyControlled: location.properties.isQueueManuallyControlled
});

const timezoneCache = {};

const fetchTimezoneOffset = _.memoize(
  async (data, location) => {
    console.log("memoizedFetchTimezone", location.id, location.timezone);
    return await data.bee.actions
      .invoke('vwroom.fetchTimezoneOffset', location.id, location.timezone)
      .then(waitForInvocationReaction(data.bee, r => _.get(r, 'details.message'), REACTION_TIMEOUT))
      ;
  }, (data, location) => location.timezone
);

const fetchTimezone = async (data, location) => {
  if(!location.timezone) {
    return location;
  }

  const timezoneOffset = await fetchTimezoneOffset(data, location);
  location.utcOffset = timezoneOffset;

  return location;
};

const fetchLocation = (data) => {
  return data.bee.storage.getClassInstanceByClassNameAndId('vwroom', 'location', data.locationId)
    .then(formatLocation)
    .then(location => fetchTimezone(data, location))
    .then(location => data.subscriber.next(location));
};

const handleChanges = (data) => () => {
  fetchLocation(data)
    .catch((error) => {
      console.error('Could not read item info, could have been deleted', error);
    });
};

const listenForChanges = (data) => () => {
  if (data.unsubscribed) {
    return;
  }

  const callbackId = data.bee.reactions.setCallback(
    `storage:mutate.instance.${data.locationId}`,
    handleChanges(data)
  );

  console.debug(`Adding callback for location ${data.locationId}: ${callbackId}`);
  data.callbackIds.push(callbackId);
};

const removeCallbacks = (data) => {
  const callbackIds = data.callbackIds;
  data.callbackIds = [];
  console.debug(`Removing callbacks for location ${data.locationId}: ${callbackIds}`);
  callbackIds.forEach(data.bee.reactions.removeCallback);
};

const setupObservable = (data) =>
  fetchLocation(data)
    .then(listenForChanges(data))
    .catch((error) => {
      console.error('Subscribing error', error);
      handleBeeError(error);
      data.subscriber.error(error);
      removeCallbacks(data);
    })
    .finally(() => {
      if (data.unsubscribed) {
        removeCallbacks(data);
      }
    })
;

const tearDown = (data) => () => {
  console.debug(`Unsubscribing from location ${data.locationId}...`);

  removeCallbacks(data);
  data.unsubscribed = true;

  console.debug(`Unsubscribed from location ${data.locationId}.`);
};

function observe(bee, locationId) {
  // TODO: validate params

  return new Observable((subscriber) => {
    console.debug(`Subscribing to location ${locationId}`);

    const data = {
      unsubscribed: false,
      bee,
      subscriber,
      locationId,
      callbackIds: [],
    };

    setupObservable(data);

    return tearDown(data);
  });
}

module.exports = {
  fetchTimezone,
  formatLocation,
  observe,
};
