import {
  Chat,
  ChatApi,
  ChatApi_Message,
  ChatApi_ReadMarker,
  ChatApi_TypingNotification,
  ChatApi_Participant,
  Message,
  Participant,
  ParticipantStatus,
  MessageFormat,
  MessageState,
  ParticipantType,
} from "./chat.model";
import { ReplaySubject, Observable } from "rxjs";
import { setImmediate, Dict, sortBy } from "../../util";
import { createObjectCache, mergeArrays, mergeObjects } from "./chat.util";

const ONE_SECOND_IN_MS = 1000;
const MAX_TYPING_TIME_IN_MS = 10 * ONE_SECOND_IN_MS;
const MAX_ACTIVE_TIME_IN_MS = 2 * 60 * ONE_SECOND_IN_MS;

const AVATAR_URLS: Dict<ParticipantType, string> = {
  [ParticipantType.Admin]: "Shared/chat-avatar-host.svg",
  [ParticipantType.User]: "Shared/chat-avatar-visitor.svg",
  [ParticipantType.System]: "Shared/chat-avatar-system.svg",
};

const MIN_DATE = new Date(0);

export class ChatImplementation implements Chat {
  constructor(private api: ChatApi, public readonly id: string) {
    this.title = id;

    api.onMessage.subscribe((m) => this.handleOnMessage(m));
    api.onRead.subscribe((r) => this.handleOnRead(r));
    api.onTyping.subscribe((t) => this.handleOnTyping(t));
    api.onParticipants.subscribe((p) => this.handleOnParticipant(p));

    this.statusInterval = (setInterval(
      () => this.updateStatuses(),
      ONE_SECOND_IN_MS
    ) as any) as number;
  }

  private statusInterval: number;

  private whoIsTypingSubject = new ReplaySubject<Participant[]>(1);
  private messagesSubject = new ReplaySubject<Message[]>(1);
  private participantsSubject = new ReplaySubject<Participant[]>(1);
  private participantStatusSubject = new ReplaySubject<
    Map<Participant, ParticipantStatus>
  >(1);

  private messageCache = createObjectCache<Message>();
  private participantCache = createObjectCache<Participant>();

  private presenceInfo: Map<Participant, ParticipantStatus> = new Map();
  private typingInfo: Participant[] = [];

  public title: string;
  public readonly whoIsTyping: Observable<Participant[]> = this
    .whoIsTypingSubject;
  public readonly messages: Observable<Message[]> = this.messagesSubject;
  public readonly participants: Observable<Participant[]> = this
    .participantsSubject;
  public readonly participantStatus: Observable<
    Map<Participant, ParticipantStatus>
  > = this.participantStatusSubject;

  private readonly lastTyping: Map<Participant, number> = new Map();
  private readonly lastSeen: Map<Participant, number> = new Map();

  private messagesIsDirty: boolean = false;
  private participantsIsDirty: boolean = false;
  private presenceInfoIsDirty: boolean = false;
  private typingInfoIsDirty: boolean = false;

  private userSeen(participantId: string, when: Date) {
    if (!when.getTime) {
      debugger;
    }
    const timestamp = when.getTime();
    const who = this.participantCache.get(participantId);
    if (
      !this.lastSeen.has(who) ||
      (this.lastSeen.get(who) || MIN_DATE) < timestamp
    ) {
      this.lastSeen.set(who, timestamp);
    }
  }

  private userStartedTyping(participantId: string, when: Date) {
    const timestamp = when.getTime();
    const who = this.participantCache.get(participantId);
    if (
      !this.lastTyping.has(who) ||
      (this.lastTyping.get(who) || MIN_DATE) < timestamp
    ) {
      this.lastTyping.set(who, timestamp);
    }
  }

  private userFinishedTyping(participantId: string, when: Date) {
    const timestamp = when.getTime();
    const who = this.participantCache.get(participantId);
    if (
      this.lastTyping.has(who) &&
      (this.lastTyping.get(who) || MIN_DATE) < timestamp
    ) {
      this.lastTyping.set(who, 0);
    }
  }

  private handleOnParticipant(participants: ChatApi_Participant[]) {
    participants.forEach((participant) => {
      const orig = this.participantCache.get(participant.id);
      mergeObjects(orig, participant);
      if (!orig.avatarUrl) {
        orig.avatarUrl = AVATAR_URLS[orig.type];
      }
      if (!orig.name) {
        orig.name = orig.id;
      }
    });
    this.participantsIsDirty = true;
    this.updateStatuses();
  }

  private handleOnMessage(messages: ChatApi_Message[]) {
    messages.forEach((api_message) => {
      if (!api_message.id) {
        throw new Error(
          "Chat API must provide an id for each chat message that is unique within the context of that conversation"
        );
      }
      if (!api_message.authorId) {
        throw new Error(
          "Chat API must provide an id for each chat participant that is unique within the context of that conversation"
        );
      }
      const orig = this.messageCache.get(api_message.id);
      const updated: Message = {
        id: api_message.id,
        author: this.participantCache.get(api_message.authorId),
        whenSent: api_message.whenSent,
        lastUpdated: api_message.lastUpdated || api_message.whenSent,
        message: api_message.message,
        messageFormat: api_message.messageFormat || MessageFormat.Plaintext,
        state: api_message.state || MessageState.Delivered,
        hasBeenEdited: api_message.hasBeenEdited || false,
        readBy: orig.readBy || [],
        lastReadLocationFor: orig.lastReadLocationFor || [],
      };
      mergeObjects(orig, updated);

      const timestamp =
        orig.whenSent > orig.lastUpdated ? orig.whenSent : orig.lastUpdated;
      this.userSeen(orig.author.id, timestamp);
      this.userFinishedTyping(orig.author.id, timestamp);
    });
    this.messageCache.all().sort(sortBy((m) => m.whenSent.getTime()));
    this.messagesIsDirty = true;
    this.updateStatuses();
  }

  private handleOnRead(readMarkers: ChatApi_ReadMarker[]) {
    readMarkers.forEach((marker) => {
      const message = this.messageCache.get(marker.messageId);
      const who = this.participantCache.get(marker.participantId);
      this.userSeen(who.id, marker.timestamp);
      mergeArrays(message.readBy, [who]);
    });
    const allMessages = this.messageCache.all();
    allMessages.sort(sortBy((m) => m.whenSent.getTime()));
    allMessages.forEach((m) => (m.lastReadLocationFor = []));
    const allParticipants = this.participantCache.all();
    allParticipants.forEach((p) => {
      const hasRead = allMessages.filter((m) => m.readBy.includes(p));
      if (hasRead.length > 0) {
        const last = hasRead[hasRead.length - 1];
        last.lastReadLocationFor.push(p);
      }
    });
    this.messagesIsDirty = true;
    this.updateStatuses();
  }

  private handleOnTyping(typingNotifications: ChatApi_TypingNotification[]) {
    typingNotifications.forEach((notification) => {
      this.userStartedTyping(
        notification.participantId,
        notification.timestamp
      );
    });
    this.updateStatuses();
  }

  private updateStatuses() {
    const now = Date.now();
    const previouslySeen = Array.from(this.lastSeen.entries());
    const previouslyTyping = Array.from(this.lastTyping.entries());
    const stillActive = now - MAX_ACTIVE_TIME_IN_MS;
    const stillTyping = now - MAX_TYPING_TIME_IN_MS;

    previouslySeen.forEach(([participant, timestamp]) => {
      const newState =
        timestamp > stillActive
          ? ParticipantStatus.Active
          : ParticipantStatus.Inactive;
      if (this.presenceInfo.get(participant) !== newState) {
        this.presenceInfo.set(participant, newState);
        participant.status = newState;
        this.presenceInfoIsDirty = true;
        this.participantsIsDirty = true;
      }
    });
    previouslyTyping.forEach(([participant, timestamp]) => {
      const isTyping = timestamp > stillTyping;
      if (isTyping && !this.typingInfo.includes(participant)) {
        this.typingInfo.push(participant);
        this.typingInfoIsDirty = true;
      } else if (!isTyping && this.typingInfo.includes(participant)) {
        this.typingInfo = this.typingInfo.filter((who) => who !== participant);
        this.typingInfoIsDirty = true;
      }
    });
    this.queueEmitPendingEvents();
  }
  private queueEmitPendingEvents() {
    setImmediate(() => {
      if (this.presenceInfoIsDirty) {
        this.presenceInfoIsDirty = false;
        this.participantStatusSubject.next(new Map(this.presenceInfo));
      }
      if (this.typingInfoIsDirty) {
        this.typingInfoIsDirty = false;
        this.whoIsTypingSubject.next([...this.typingInfo]);
      }
      if (this.participantsIsDirty) {
        this.participantsIsDirty = false;
        this.participantsSubject.next([...this.participantCache.all()]);
      }
      if (this.messagesIsDirty) {
        this.messagesIsDirty = false;
        this.messagesSubject.next([...this.messageCache.all()]);
      }
    });
  }

  public send(author: Participant, message: Partial<Message>) {
    this.api.sendMessage({
      authorId: author.id,
      whenSent: message.whenSent,
      lastUpdated: message.lastUpdated,
      message: message.message,
      messageFormat: message.messageFormat,
      hasBeenEdited: message.hasBeenEdited,
    });
    this.userSeen(author.id, new Date());
  }
  public userIsTyping(who: Participant) {
    if (!who || !who.id) {
      throw new Error("user must exist and have an id");
    }
    const now = new Date();
    this.userStartedTyping(who.id, now);
    this.userSeen(who.id, now);
    this.api.userIsTyping(who.id);
  }
  public markRead(who: Participant, message: Message) {
    if (!message || !message.id) {
      throw new Error("Message must exist and have an id");
    }
    this.userSeen(who.id, new Date());
    this.handleOnRead([
      {
        participantId: who.id,
        messageId: message.id,
        timestamp: new Date(),
      },
    ]);
    this.api.markRead(who.id, message.id);
  }
}
