import { useEffect } from "react";

function useDocumentTitle(title: string) {
  useEffect(() => {
    try {
      const titleElement = document.querySelector("title");
      if (titleElement) {
        titleElement.text = title;
      }
    } catch (err) {}
  }, [title]);
}

export default useDocumentTitle;
