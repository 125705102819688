import React from "react";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import TextBlockDescription from "./../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import { useExtendedTranslation } from "./../../Shared/Services/i18nService";
import { useObservable } from "../../Shared/Hooks/useObservable";
import {
  watchUserData,
  watchCurrentTicket,
  IUserData,
} from "../../Services/DataService";
import { createClock } from "../../Shared/observableClock";
import { useInstanceConst } from "../../Shared/Hooks/useInstanceConst";
import CountdownTimer from "../../Components/CountdownTimer/CountdownTimer";
import moment from "moment";
import "./ScreenOnMyWay.scss";
import TextBlockBigText from "../../Shared/Components/StandardStyles/TextBlockBigText/TextBlockBigText";
import TextBlockHint from "../../Shared/Components/StandardStyles/TextBlockHint/TextBlockHint";
import { Button } from "@material-ui/core";
import { restartWorkflow } from "../../Services/WorkflowService";
import VisitorFlagIcon, {
  VisitorFlags,
} from "../../Shared/Components/VisitorFlagIcon/VisitorFlagIcon";

function ScreenOnMyWay() {
  const t = useExtendedTranslation();

  const ticket = useObservable(watchCurrentTicket());
  const userData: IUserData = useObservable(watchUserData());

  const instanceClock = useInstanceConst(() => createClock());
  useObservable(instanceClock);

  const start = moment(ticket ? ticket.nextTimeout : 0);
  const elapsed = moment().diff(start, "seconds");
  const left = ticket ? ticket.location.maxEntrantTime - elapsed : -1;
  const showContinue = left <= 0;

  // Set the timestamp to the time the customer has to be there
  const time = ticket
    ? moment(ticket.nextTimeout)
        .add(ticket.location.maxEntrantTime, "seconds")
        .format("h:mm A")
    : "";

  return (
    <screen-on-my-way>
      <SinglePanelLayout
        title={t("onmyway.title")}
        footer={
          showContinue ? (
            <Button
              onClick={restartWorkflow}
              variant="contained"
              color="primary"
            >
              {t("onmyway.continue")}
            </Button>
          ) : (
            <></>
          )
        }
      >
        <TextBlockDescription>{t("onmyway.description")}</TextBlockDescription>
        <screen-onmyway-center>
          <TextBlockBigText>
            {userData.firstName} {userData.lastName} {""}
            {userData.isHighRisk && (
              <VisitorFlagIcon flag={VisitorFlags.HighRiskVisitor} />
            )}
            <br />
            {time}
          </TextBlockBigText>
          <TextBlockHint>{t("onmyway.timeRemaining")}</TextBlockHint>
          <CountdownTimer seconds={left}> </CountdownTimer>
        </screen-onmyway-center>
      </SinglePanelLayout>
    </screen-on-my-way>
  );
}

export default ScreenOnMyWay;
