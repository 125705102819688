import { MapFn, Maybe } from "../util";
import { useEffect } from "react";
import { useState } from "react";
import { Observable } from "rxjs";
import { useMaybePromise } from "./usePromise";

const dummy = Promise.resolve() as any;

export function useMaybePromisedObservable<T, T2>(
  promise: Maybe<Promise<Maybe<T>>>,
  propertySelector: MapFn<T, Observable<T2>>,
  silent?: boolean
): Maybe<T2> {
  const unwrapped: Maybe<T> = useMaybePromise(promise || dummy, silent);
  const [result, setResult] = useState<Maybe<T2>>(undefined);
  useEffect(() => {
    if (!unwrapped) {
      return;
    }
    const observable = propertySelector(unwrapped);
    const subscription = observable.subscribe(setResult);
    return () => subscription.unsubscribe();
  }, [unwrapped, propertySelector]);
  return result;
}
