import React from "react";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import "./ScreenPleaseWait.scss";
import { useExtendedTranslation } from "../../Shared/Services/i18nService";
import { Spinner } from "../../Shared/Components";
import TextBlockHint from "./../../Shared/Components/StandardStyles/TextBlockHint/TextBlockHint";
function ScreenPleaseWait() {
  const t = useExtendedTranslation();

  return (
    <screen-please-wait>
      <SinglePanelLayout title={t("pleasewait.title")}>
        <TextBlockHint>{t("pleasewait.hint")}</TextBlockHint>
        <Spinner />
      </SinglePanelLayout>
    </screen-please-wait>
  );
}

export default ScreenPleaseWait;
