import moment from "moment";

/**
 * A function to stringify the number of seconds in h:mm:ss format
 */
export function formatDuration(seconds: number) {
  if (seconds < 0) {
    seconds = 0;
  }

  const duration = moment.duration(seconds, "seconds");
  const formattable = moment.utc(duration.asMilliseconds());

  if (duration.asHours() >= 1) {
    return formattable.format("h:mm:ss");
  }
  return formattable.format("m:ss");
}
