import React, { useState } from "react";
import { default as NumberFormat } from "react-number-format";
import { sortBy } from "../../util";

const DEBUG_PHONE_NUMBER_FORMATTING = false;

interface Matcher {
  rgx: RegExp;
  pattern: string;
  format: string;
  specificity: number;
  rawLength: number;
}

const isSpecificDigit = (c: string) => c >= "0" && c <= "9";
const isWildcardDigit = (c: string) => c === "#" || c === "!";
const isDigit = (c: string) => isSpecificDigit(c) || isWildcardDigit(c);
function createMatcherFromExample(example: string): Matcher {
  const characters = Array.from(example);
  const rawLength = characters.filter(isDigit).length;
  const specificity = characters.filter(isSpecificDigit).length;
  const pattern = characters.filter(isDigit).join("");
  const format = characters.map((c) => (isDigit(c) ? "#" : c)).join("");
  const trimmedPattern = pattern.replace(/#*$/, "");

  return {
    rgx: new RegExp(
      "^" + trimmedPattern.replace(/#/g, "\\d").replace(/!/g, "[1-9]")
    ),
    pattern: pattern,
    format,
    specificity,
    rawLength,
  };
}

const MATCHERS: Matcher[] = [
  ///https://www.area-codes.org.uk/formatting.php
  // # = [0-9]
  // ! = [1-9]
  // specific number = that specific number
  // all other symbols are excluded from the pattern, but included in the format
  "(01###) #####",
  "(01###) ######",
  "(011#) ### ####",
  "(01#1) ### ####",
  "(013397) #####",
  "(013398) #####",
  "(013873) #####",
  "(015242) #####",
  "(015394) #####",
  "(015395) #####",
  "(015396) #####",
  "(016973) #####",
  "(016974) #####",
  "(016977) #####",
  "(017683) #####",
  "(017684) #####",
  "(017687) #####",
  "(019467) #####",
  "(019755) #####",
  "(019756) #####",
  "(02#) #### ####",
  "(03##) ### ####",
  "(05###) ######",
  "(07###) ######",
  "(0800) ######",
  "(08##) ### ####",
  "(09##) ### ####",
  "(!##) ###-####",
  "(0##) #### ####",
]
  .map(createMatcherFromExample)
  .sort(sortBy((m) => m.specificity + m.rawLength / 100))
  .reverse();

const DEFAULT_FORMAT = "(###) ###-####";
function guessFormatFromNumber(number: string) {
  const matcher = MATCHERS.find((m) => m.rgx.test(number));
  if (matcher) {
    if (DEBUG_PHONE_NUMBER_FORMATTING) {
      console.log(
        `${number} matches ${matcher.pattern}.  Applying format: ${matcher.format}`
      );
    }
    return matcher.format;
  }
  if (DEBUG_PHONE_NUMBER_FORMATTING) {
    console.log(
      `${number} doesn't match any known format, assuming default format: ${DEFAULT_FORMAT}`
    );
  }
  return DEFAULT_FORMAT;
}

export function NumberFormatPhoneCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  const [format, setFormat] = useState(() =>
    guessFormatFromNumber(props.value || "")
  );

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        if (values.value) {
          setFormat(guessFormatFromNumber(values.value));
        }
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      type="tel"
      mask=" "
      format={format}
      isNumericString
    />
  );
}

export function NumberFormatPhoneCustomTextOnly(props: any) {
  return NumberFormatPhoneCustom({
    displayType: "text",
    renderText: (v: any) => v,
    ...props,
  });
}

export function isPhoneNumberValid(number: string): boolean {
  const matcher = MATCHERS.find((m) => m.rgx.test(number));
  return !!(matcher && matcher.rawLength === number.length);
}

export default NumberFormatPhoneCustom;
