import _sortedIndexBy from "lodash/sortedIndexBy";
import { IHours, ILocation as IClientLocation } from "./Types/IClient";
import { ILocation as IAdminLocation } from "./Types/IAdmin";

const moment = require("moment");

/**
 * Gets the current time offset in milliseconds since the start of the week
 */
export function currentTimeOffset(
  location: IClientLocation | IAdminLocation
): number {
  // calculate using the location's timezone
  const now = moment();
  if (location.utcOffset) {
    now.subtract(now.utcOffset() - location.utcOffset, "minutes");
  }

  const timeOffset = moment(now).diff(
    moment(now).startOf("week"),
    "milliseconds"
  );
  return timeOffset;
}

/**
 * Find the hours that contain the given time offset
 * @param hoursArray The array of hours to search
 * @param timeOffset The time offset in milliseconds
 * @returns The hours and the index, if found, otherwise undefined and -1
 */
export function findHours(
  hoursArray: IHours[],
  timeOffset: number
): [IHours, number] {
  // get the next hours
  let [h, i] = findNextHours(hoursArray, timeOffset);

  // verify the offset is bound by the hours at that index
  i = h && h.startOffset <= timeOffset && timeOffset <= h.stopOffset ? i : -1;
  h = hoursArray[i];

  return [h, i];
}

/**
 * Find the next hours that would correspond to the given time offset,
 * or the hours that contian it
 *
 * @param hoursArray The array of hours to search
 * @param timeOffset The time offset in milliseconds
 * @returns The hours and the index, if found, otherwise undefined and 0
 */
export function findNextHours(
  hoursArray: IHours[],
  timeOffset: number
): [IHours, number] {
  // find the index in the sorted array
  let i = _sortedIndexBy(
    hoursArray,
    {
      startOffset: timeOffset,
      stopOffset: timeOffset,
    },
    function (h) {
      if (h.startOffset <= timeOffset && timeOffset <= h.stopOffset) {
        return timeOffset;
      }
      return h.startOffset;
    }
  );

  // get the hours
  let h = hoursArray[i];
  return [h, i];
}

/**
 * Check if the queue is currently open
 *
 * @param location The location to check
 * @returns True if open, false otherwise
 */
export function isQueueOpen(
  location?: IClientLocation | IAdminLocation
): boolean {
  if (!location) {
    return false;
  }

  if (location.isQueueManuallyControlled) {
    return !!location.isQueueOpen;
  }

  const [foundHours] = findHours(
    location.queueHours || [],
    currentTimeOffset(location)
  );
  return !!foundHours;
}
