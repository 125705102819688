import React, { useState } from "react";
import SinglePanelLayout from "./../../Components/Layout/SinglePanel/SinglePanel";
import "./ScreenCheckIn.scss";
import { Button } from "@material-ui/core";
import {
  NumberFormatPhoneCustom,
  InQField,
  InQToggleSwitch,
  LeftCenterRight,
  FeatureFilter,
} from "../../Shared/Components";
import {
  TextBlockDescription,
  TextBlockHint,
} from "./../../Shared/Components/StandardStyles";
import { useExtendedTranslation } from "./../../Shared/Services/i18nService";
import {
  watchUserData,
  IUserData,
  updateUserData,
} from "../../Services/DataService";
import { useObservable } from "../../Shared/Hooks/useObservable";
import { advanceUserCheckInWorkflow } from "../../Services/WorkflowService";
import { useAsyncEffect } from "./../../Shared/Hooks/useAsyncEffect";
import ButtonBackToLocationSelect from "../../Components/ButtonBackToLocationSelect/ButtonBackToLocationSelect";
import { highPrioritySelfIndicate } from "../../Shared/Services/branding";
import { FeatureFlags } from "../../Shared/Services/featureFlags";
import { isPhoneNumberValid } from "../../Shared/Components";

function ScreenCheckIn() {
  const t = useExtendedTranslation();

  const userData: IUserData = useObservable(watchUserData());
  const advanceIsDisabled = useObservable(
    advanceUserCheckInWorkflow.watchIsBusy()
  );

  const [firstName, setFirstName] = useState(
    (userData.firstName && userData.rememberMe) ? userData.firstName : ""
  );
  const [lastName, setLastName] = useState(
    (userData.firstName && userData.rememberMe) ? userData.lastName : ""
  );
  const [phone, setPhone] = useState(userData.phone ? userData.phone : "");
  const [rememberMe, setRememberMe] = useState(!!userData.rememberMe);
  const [isHighPriority, setIsHighPriority] = useState(
    !!userData.isHighPriority
  );

  const isPhoneValid = phone ? isPhoneNumberValid(phone) : false;
  const isIncomplete = !firstName || !lastName || !isPhoneValid;

  useAsyncEffect(async () => {
    await updateUserData({
      firstName,
      lastName,
      phone,
      rememberMe,
      isHighPriority,
    });
  }, [firstName, lastName, phone, rememberMe, isHighPriority]);

  return (
    <screen-check-in>
      <SinglePanelLayout
        footer={
          <LeftCenterRight
            left={<ButtonBackToLocationSelect />}
            center={
              <Button
                variant="contained"
                color="primary"
                onClick={advanceUserCheckInWorkflow}
                disabled={isIncomplete || advanceIsDisabled}
              >
                {t("checkin.continue.button")}
              </Button>
            }
          />
        }
      >
        <TextBlockDescription>{t("checkin.description1")}</TextBlockDescription>
        <TextBlockHint>{t("checkin.description2")}</TextBlockHint>
        <InQField
          label={t("checkin.firstName")}
          required
          placeholder={t("checkin.firstName.placeholder")}
          value={firstName}
          onChange={setFirstName}
        />
        <InQField
          label={t("checkin.lastName")}
          required
          placeholder={t("checkin.lastName.placeholder")}
          value={lastName}
          onChange={setLastName}
        />
        <InQField
          label={t("checkin.phone")}
          placeholder={t("checkin.phone.placeholder")}
          value={phone}
          InputProps={{
            inputComponent: NumberFormatPhoneCustom,
          }}
          error={phone && !isPhoneValid}
          onChange={setPhone}
        />
        <FeatureFilter feature={FeatureFlags.PriorityEntrants}>
          <InQToggleSwitch
            checked={isHighPriority}
            label={highPrioritySelfIndicate()}
            onChange={setIsHighPriority}
          />
        </FeatureFilter>
        <InQToggleSwitch
          checked={rememberMe}
          label={t("checkin.rememberMe")}
          onChange={setRememberMe}
        />
      </SinglePanelLayout>
    </screen-check-in>
  );
}

export default ScreenCheckIn;
