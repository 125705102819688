const _ = require('lodash');
const location = require('./location');
const { getLocations: getLocationsUtils } = require("./locations");
const { waitForInvocationReaction } = require("./reactions_utils");
const { assertConnection: assertConnectionUnwrapped } = require("./utils");

function assertConnection() {
  try {
    return assertConnectionUnwrapped();
  }
  catch(err) {
    if(window.onFatalError){
      window.onFatalError(err);
    }
  }
}

async function allErrorsAreFatal(fn) {
  try {
    return await fn();
  }
  catch(err) {
    if (window.onFatalError) {
      window.onFatalError(err);
    }
  }
}

async function sessionErrorsAreFatal(fn) {
  try {
    return await fn();
  }
  catch(err) {
    if (!err.status) {
      return;
    }
    var isFatal = false;
    if (err.status === 404) {
      console.error("JS and Hive API's are not in sync, make sure hive got updated");
      isFatal = true;
    }
    if (err.status === 403) {
      console.error("Session permission error");
      isFatal = true;
    }
    if (window.onFatalError && isFatal) {
      window.onFatalError(err);
    }
  }
}

const REACTION_TIMEOUT = 30000;

/**
 * Returns a copy of the location, but with the UI-only fields removed
 * @param {ILocation} location - the location object that will be sent to the hive
 */
function removeNonHiveLocationFields(location) {
  const copy = Object.assign({}, location);
  delete copy.id;
  delete copy.utcOffset;
  return copy;
}

/**
 * @class Config
 * @description Represents an administrator of an account. The application must be connected to Hive
 * with proper credentials and roles.
 */
class Config {
  constructor() {}

/**
 * @memberof Config
 * @description Fetches the configuration info for the brand
 * @returns {Promise<IOrganizationInfo?} The brand info
 */
  getOrganizationInfo() {
    return {
      name: "TODO (Not real data)"
    };
  }

  /**
   * @memberof Config
   * @description Updates the brand info for the current user
   * @param {*} newInfo the updated branding info
   */
  updateOrganizationInfo(newInfo) {
    throw new Error("Not Implemented yet");
  }
  /**
   * @memberof Config
   * @description Returns the available locations.
   * @returns {Promise<Array>} An array containing the locations. Each location in the array contain location information
   * (address, location, initial queues, etc).
   */
  getLocations(callerLatitude, callerLongitude) {
    return getLocationsUtils(callerLatitude, callerLongitude);
  }

  /**
   * @memberof Config
   * @description Updates the entire location object all at once.
   *
   * @param {ILocation} location - The location object.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  updateLocation(location) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.updateLocation", location.id, removeNonHiveLocationFields(location)))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Config
   * @description Updates the business hours of a location.
   *
   * @param {String} locationId - The id of the location to update.
   * @param {Array} hours - The array of business hours.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  updateLocationBusinessHours(locationId, hours) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.updateLocationBusinessHours", locationId, hours))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Config
   * @description Updates the queue hours of a location.
   *
   * @param {String} locationId - The id of the location to update.
   * @param {Array} hours - The array of queue hours.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  updateLocationQueueHours(locationId, hours) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.updateLocationQueueHours", locationId, hours))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Config
   * @description Updates the capacity of a location.
   *
   * @param {String} locationId - The id of the location to update.
   * @param {Number} capacity - The new capacity.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  updateLocationCapacity(locationId, capacity) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.updateLocationCapacity", locationId, capacity))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Config
   * @description Updates the max entrant time and grace period of a location.
   *
   * @param {String} locationId - The id of the location to update.
   * @param {Number} maxEntrantTime - The new max entrant time in seconds.
   * @param {Number} gracePeriodSeconds - The new grace period in seconds.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  updateLocationEntrantTimeouts(locationId, maxEntrantTime, gracePeriodSeconds) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.updateLocationEntrantTimeouts", locationId, maxEntrantTime, gracePeriodSeconds))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Config
   * @description Obtains an observable to the specified location.
   * @param {String} locationId - The id of the location to monitor.
   * @returns {Observable<Object>} - An observable that receives notifications when the location changes.
   */
  getLocation(locationId) {
    const connection = assertConnection();
    return location.observe(connection.bee, locationId);
  }

  /**
   * @memberof Config
   * @description Moves an item to a new position within its queue.
   * @param {String} queueItemId - The id of the queue item to move.
   * @param {Number} position - The new position of the queue item.
   * @returns {Promise} - A promise that is resolved when the operation completes or rejected if the operation fails.
   */
  moveQueueItem(queueItemId, position) {
    const connection = assertConnection();

    return sessionErrorsAreFatal( () => connection.bee.actions
      .invoke("vwroom.moveQueueItemPosition", queueItemId, position))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }


  /**
   * @memberof Config
   * @description Adds person to the specified location
   * @param {String} locationId - The id of the location to modify.
   * @returns {Promise} - a promise that resolves when the operation completes, or is rejected when it fails.
   */
  addPersonToLocation(locationId) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.addPerson', locationId))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Config
   * @description Removes person to the specified location
   * @param {String} locationId - The id of the location to modify.
   * @returns {Promise} - a promise that resolves when the operation completes, or is rejected when it fails.
   */
  removePersonFromLocation(locationId) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke('vwroom.removePerson', locationId))
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT));
  }

  /**
   * @memberof Config
   * @description Updates the values of manual queue control for location
   * @param {*} locationId - The id of the location to update
   * @param {*} isQueueOpen - the value of the flag to set
   * @param {*} isQueueManuallyControlled - the value of the flag to set
   * @returns {Promise<any>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  setManualQueueControl(locationId, isQueueOpen, isQueueManuallyControlled) {
    const connection = assertConnection();
    return sessionErrorsAreFatal( () => connection.bee.actions.invoke(
        'vwroom.setManualQueueControl',
        locationId,
        isQueueOpen,
        isQueueManuallyControlled))
        .then(waitForInvocationReaction(connection.bee, () => {}, REACTION_TIMEOUT));
  }
}

module.exports = Config;
