import { skip } from "rxjs/operators";
import moment from "moment";

import { Client, ConnectionService } from "../../../ClientLib/src/index";
import { createSyncStorageInterface, SyncStorage } from "./Services/Storage";
import { IClient } from "./Types/IClient";
import environmentVar from "./environment.json";

export const connection = ConnectionService.connection$;

const KEY_CREDS_ADMISSION = "hive-admission-app";
const KEY_CREDS_CLIENT = "hive-client-app";

interface IConnection {
  token: {
    access_token: string;
    access_token_expiry: number;
  };
  username: string;
  bee: any;
}

export enum AppType {
  Admission,
  Client,
}

interface IConnectionData {
  appType: AppType;
  rememberMe: boolean;
}

interface IStoredCredentials {
  username: string;
  token: string;
  expiration: number;
  appType: AppType;
  rememberMe: boolean;
}

const CONFIG = {
  host: environmentVar.urls.host,
  orgId: environmentVar.orgId,
  appId: environmentVar.appId,
  categories: ["core", "storage", "invocation"],
};

let connectionData: IConnectionData;

const localeStorageWrapper = createSyncStorageInterface("", "localstorage");
const sessionStorageWrapper = createSyncStorageInterface("", "sessionstorage");

function storeCredentials(
  connection: IConnection,
  appType: AppType,
  rememberMe: boolean
) {
  const creds: IStoredCredentials = {
    username: connection.username,
    token: connection.token.access_token,
    expiration: connection.token.access_token_expiry,
    appType,
    rememberMe,
  };

  const storage: SyncStorage = rememberMe
    ? localeStorageWrapper
    : sessionStorageWrapper;
  switch (appType) {
    case AppType.Admission:
      storage.setItem(KEY_CREDS_ADMISSION, creds);
      break;

    case AppType.Client:
      storage.setItem(KEY_CREDS_CLIENT, creds);
      break;

    default:
      console.error("Cannot store credentials without app type");
      break;
  }
}

async function clearStoredCredentials(appType: AppType) {
  switch (appType) {
    case AppType.Admission:
      await localeStorageWrapper.removeItem(KEY_CREDS_ADMISSION);
      await sessionStorageWrapper.removeItem(KEY_CREDS_ADMISSION);
      break;

    case AppType.Client:
      await localeStorageWrapper.removeItem(KEY_CREDS_CLIENT);
      await sessionStorageWrapper.removeItem(KEY_CREDS_CLIENT);
      break;

    default:
      console.error("Cannot clear credentials without app type");
      break;
  }
}

function getStoredCredentials(
  appType: AppType
): IStoredCredentials | undefined {
  let data: IStoredCredentials | undefined;
  switch (appType) {
    case AppType.Admission:
      data =
        localeStorageWrapper.getItem(KEY_CREDS_ADMISSION) ||
        sessionStorageWrapper.getItem(KEY_CREDS_ADMISSION);
      break;

    case AppType.Client:
      data =
        localeStorageWrapper.getItem(KEY_CREDS_CLIENT) ||
        sessionStorageWrapper.getItem(KEY_CREDS_CLIENT);
      break;

    default:
      console.error("Cannot get credentials without app type");
      break;
  }

  return data;
}

function validateStoredCredentials(appType: AppType) {
  const data: IStoredCredentials | undefined = getStoredCredentials(appType);
  if (!data) {
    return undefined;
  }

  // Check if we have a stored token, if so, we don't need the login screen
  const token = {
    access_token: data.token,
    access_token_expiry: data.expiration,
  };
  const username = data.username;
  const rememberMe = data.rememberMe;
  const expiration = moment(token.access_token_expiry * 1000);
  const now = moment();

  if (token && expiration.isAfter(now)) {
    return { username, token, rememberMe };
  }

  return undefined;
}

/**
 * @description Attempts to connect using stored token
 * @returns {Promise<Object>} - a promise that resolves to the initial or current connection object.
 */
export function connectWithStoredCredentials(appType: AppType) {
  const storedCredentials = validateStoredCredentials(appType);
  if (storedCredentials) {
    console.debug("Attempting to connect with stored credentials");
    connectionData = { appType, rememberMe: storedCredentials.rememberMe };
    return ConnectionService.initBee(
      CONFIG,
      storedCredentials.username,
      storedCredentials.token
    ).catch((error: any) => {
      console.error(
        "Could not connect with stored credentials, reconnecting...",
        error
      );
      clearStoredCredentials(appType);

      throw error;
    });
  }

  return Promise.reject("No valid stored credentials");
}

/**
 * @description Connects to Hive based on environment, if applicable.
 * @returns {Promise<Object>} - a promise that resolves to the initial or current connection object.
 */
export function connectAsClient() {
  const args = [
    CONFIG,
    environmentVar.everybodyBeeUser.username,
    environmentVar.everybodyBeeUser.password,
  ];
  console.debug("Attempting to sign in as bee", CONFIG);
  connectionData = { appType: AppType.Client, rememberMe: true };
  return ConnectionService.signInAsBee(...args);
}

/**
 * @description Connects to Hive based on environment, if applicable.
 *
 * @param {string} username
 * @param {string} password
 * @param {boolean} rememberMe - whether the credentials will be remembered between sessions.
 * @returns {Promise<Object>} - a promise that resolves to the initial or current connection object.
 */
export function connectAsAdmitter(
  username: string,
  password: string,
  rememberMe: boolean
) {
  console.debug("Attempting to sign in as user", CONFIG);
  connectionData = { appType: AppType.Admission, rememberMe };
  return ConnectionService.signInAsUser(CONFIG, username, password);
}

/**
 * @description Starts the password recovery process.
 *
 * @param {String} email - the email for the account to reset the password
 * @param {String} linkBase - the link base to send in the email.
 * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
 */
export function recoverPassword(email: string, linkBase: string) {
  console.debug("Attempting to recover password", CONFIG);
  return connectAsClient().then(() => {
    const client: IClient = new Client();
    return client.recoverPassword(email, linkBase);
  });
}

/**
 * @description Change the password by validating the code.
 *
 * @param {String} email - the email for the account to reset the password
 * @param {String} code - the code to validate.
 * @param {String} newPassword - the new password.
 * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
 */
export function changePasswordWithCode(
  email: string,
  code: string,
  newPassword: string
) {
  console.debug("Attempting to change password with code", CONFIG);
  return connectAsClient().then(() => {
    const client: IClient = new Client();
    return client.changePasswordWithCode(email, code, newPassword);
  });
}

ConnectionService.connection$
  .pipe(skip(1)) // skip the first item from the BehaviorSubject
  .subscribe((conn?: IConnection) => {
    if (conn) {
      storeCredentials(conn, connectionData.appType, connectionData.rememberMe);
    } else {
      clearStoredCredentials(connectionData.appType);
    }
  });

export function assertConnection(): any {
  let connection;
  ConnectionService.connection$
    .subscribe((conn: any) => (connection = conn))
    .unsubscribe();
  if (!connection) {
    throw new Error("Connection is needed!");
  }

  return connection;
}

export function disconnect() {
  clearStoredCredentials(connectionData.appType);
  ConnectionService.releaseBee();
}

export default connection;
