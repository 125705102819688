const _ = require('lodash');
const queueItem = require('./queueItem');
const queueForClient = require('./queueForClient');
const { waitForInvocationReaction } = require('./reactions_utils');
const HiveBee = require('hive-bee');
const { assertConnection } = require('./utils');
const { getLocations: getLocationsUtils } = require('./locations');
const location  = require('./location');

const REACTION_TIMEOUT = 30000;


/**
 * @class Client
 * @description Represents a user within a virtual waiting room.
 *
 * Users can check-in to the a new entry within the waiting room of a location
 * or they can recover an existing ticket.  Once checked-in or recovered, callers
 * can subscribe to the returned observable in order to get updates.
 *
 * Updates to the queue item observable are objects that contain:
 * - id - {uuid} - the id of the caller's ticker within the waiting room. Applications can retain this value
 *   in order to recover the ticket later, if still available.
 * - queueId: {uuid} - the id of the queue the user is in.
 * - order: {number} - the order the user is in the current queue of the waiting room. Note that this
 *   could change if the user changes queue.
 * - nextTimeout: {date} - an ISO 8601 date, when the queue item was admitted.
 * - state: {string} - the state of the item. Can be:
 *    - PENDING: added to the end of a queue
 *    - CALLED: person has been called up to enter
 *    - ACKNOWLEDGED: person has acknowledged being called up
 *    - ARRIVED: person has arrived
 *    - REVOKED: person has cancelled their ticket
 *    - REMOVED: item was removed by an administrator
 *    - EXPIRED: items was removed automatically by system
 *
 * Refer to [RxJS Observable](http://reactivex.io/rxjs/class/es6/Observable.js~Observable.html) and
 * [RxJS Subscriber](http://reactivex.io/rxjs/class/es6/Subscriber.js~Subscriber.html) for more details.
 */
class Client {
  constructor() {
  }

  /**
   * @memberof Client
   * @description Starts the password recovery process.
   * @param {String} email - the email for the account to reset the password
   * @param {String} linkBase - the link base to send in the email.
   * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  recoverPassword(email, linkBase) {
    const connection = assertConnection();

    return connection.bee.actions.invoke('passwordRecovery.startRecoverPassword', email, linkBase)
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT))
    ;
  }

  /**
   * @memberof Client
   * @description Change the password by validating the code.
   * @param {String} email - the email for the account to reset the password
   * @param {String} code - the code to validate.
   * @param {String} newPassword - the new password.
   * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  changePasswordWithCode(email, code, newPassword) {
    const connection = assertConnection();

    return connection.bee.actions.invoke('passwordRecovery.startChangePasswordWithCode', email, code, newPassword)
      .then(waitForInvocationReaction(connection.bee, undefined, REACTION_TIMEOUT))
    ;
  }

  /**
   * @memberof Client
   * @description Returns the available locations.
   * @param {number=} callerLatitude - a position to use to order locations by distance from the caller's position.
   * @param {number=} callerLongitude - a position to use to order locations by distance from the caller's position.
   * @returns {Promise<Array>} An array containing the locations. Each location in the array contain location information
   * (address, location, initial queues, etc).
   */
  getLocations(callerLatitude, callerLongitude) {
    return getLocationsUtils(callerLatitude, callerLongitude);
  }

  /**
   * @memberof Client
   * @description Get an observable for a location
   * @param {String} - The id of the location.
   * @returns {Observable} an observable to a location object.
   */
  getLocation(locationId) {
    const connection = assertConnection();
    return location.observe(connection.bee,locationId);
  }

  /**
   * @memberof Client
   * @description Updates the special needs flags on a queue item
   * @param {*} itemId - the id of the queue item to modify
   * @param {*} hasSpecialNeeds - the new value of the flag.
   * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  setSpecialNeeds(itemId, hasSpecialNeeds) {
    const connection = assertConnection();
    return connection.bee.actions.invoke('vwroom.markItemAsSpecialNeeds', itemId, hasSpecialNeeds)
      .then(waitForInvocationReaction(connection.bee, () => {}, REACTION_TIMEOUT));
  }


  /**
   * @memberof Client
   * @description Check-ins a new user at the end of a location's initial queue.
   * @param {String} - The id of the location to check into.
   * @param {String} - The name of the user checking in.
   * @param {String} - The phone number of the user checking in
   * @param {SupportedLanguages} - Preferred language of user
   * @param {Boolean} failedScreener - whether the screening questionnaire was failed
   * @param {Boolean} hasSpecialNeeds - whether the person has special needs
   * @param {Array>} answers -
   * @param {Boolean} allowSMS - whether the person permits send them SMS messages
   * @param {String} ticketNumber - an optional ticket number
   * @param {String} clientApp - the name of the application performing the checkin
   * @returns {Promise<String>} the id of the queue item that can be observed (see Client.recover)
   */
  checkIn(locationId, name, phone, lang, failedScreener, hasSpecialNeeds, answers, allowSMS, ticketNumber, clientApp) {
    const connection = assertConnection();
    ticketNumber = ticketNumber || '';

    return connection.bee.actions.invoke(
      'vwroom.checkIn',
      locationId,
      name,
      phone,
      lang,
      failedScreener,
      hasSpecialNeeds,
      answers,
      allowSMS,
      ticketNumber,
      clientApp
    )
      .then(waitForInvocationReaction(connection.bee, r => _.get(r, 'details.uuid'), REACTION_TIMEOUT))
    ;
  }

  /**
   * @member Client
   * @description Get an observable for a client to monitor.
   * @param {String} locationId - the id of the location to observe.
   * @param {String} queueName - the name of the queue to observe.
   * @returns {Promise<Observable>} = an observable to a queue object.
   */
  getQueue(locationId, queueName) {
    const connection = assertConnection();

    return queueForClient.observe(connection.bee, locationId, queueName);
  }

  /**
   * @memberof Client
   * @description recovers a known queue item.
   * @param {String} - The id of the queue item to recover
   * @returns {Observable<IQueueItem>} an observable that can be subscribed to to get updates about the user's within
   * the locations queues.
   */
  recover(itemId) {
    const connection = assertConnection();

    return queueItem.observeItem(connection.bee, itemId);
  }

  /**
   * @memberof Client
   * @description Revokes an item, which removes it from its queue and deletes all associated data.
   * @param {String} itemId - the id of the item to revoke
   * @returns {Promise<any>} - a promise that is resolve when the operation completes, or rejected if it fails.
   */
  revokeItem(itemId) {
    const connection = assertConnection();

    return connection.bee.actions.invoke('vwroom.revokeItem', itemId)
      .then(waitForInvocationReaction(connection.bee, _.identity, REACTION_TIMEOUT))
    ;
  }

  /**
   * @memberof Client
   * @description Returns the URL to use to download a document
   * @param {String} blobId - the id of a blob storage document
   * @returns {String} - a URL that can be used to download a document, if permitted.
   */
  getUrlForDocument(blobId) {
    const baseUrl = HiveBee.urls.shellUrl();
    return `${baseUrl}/blob/download/${blobId}`;
  }

  /**
   * @memberof Client
   * @description Prepares a document to be uploaded for the specified queueItem. The document will need to be uploaded
   * using [Resumable JS](http://www.resumablejs.com/) with the returned URL and a proper authorization header. The proper
   * Authorization header is `Authorization = Token <your_token>`.
   *
   * @param {String} itemId - The id of the queue item that will own the uploaded document.
   * @returns {Promise<IAddedDocument>} - An object with the document ID and the url to upload to.
   */
  addDocumentToItem(itemId) {
    const connection = assertConnection();

    const baseUrl = HiveBee.urls.shellUrl();
    return connection.bee.actions.invoke('vwroom.addDocumentToItem', itemId)
      .then(waitForInvocationReaction(connection.bee, r => _.get(r, 'details.uuid'), REACTION_TIMEOUT))
      .then(blobId => ({
        documentId: blobId,
        url: `${baseUrl}/blob/upload/${blobId}`
      }));
  }

  /**
   * @memberof Client
   * @description Sets sms message functionality
   * @param {String} itemId  - id of the item to update
   * @param {boolean} isAllowed - value to allow or dissallow sms messages
   * @returns {Promise<void>} - A Promise that resolves or rejects
   */
  setSmsAccessibility(publicId,isAllowed) {
    const connection = assertConnection();
    return connection.bee.actions.invoke('vwroom.setSMSAccessibility',publicId,isAllowed)
      .then(waitForInvocationReaction(connection.bee,()=>{},REACTION_TIMEOUT));
  }

  /**
   * @memberof Client
   * @description Adds a "from client" message to the item.
   * @param {String} itemPublicId - The public id of the queue item that the message will be added to.
   * @param {String} text - The text of the message to add.
   * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  addMessageFromClientToItem(itemPublicId, text) {
    const connection = assertConnection();
    return connection.bee.actions.invoke('vwroom.addMessageFromClientToItem', itemPublicId, text)
      .then(waitForInvocationReaction(connection.bee, () => {}, REACTION_TIMEOUT));
  }

  /**
   * @memberof Client
   * @description Marks a queue item has been acknowledged.
   * @param {String} itemId - the id of the queue item to modify.
   * @returns {Promise<void>} - a promise that is resolved when the operation completes, or rejected if it fails.
   */
  markItemAsAcknowledged(itemId) {
    const connection = assertConnection();
    return connection.bee.actions.invoke('vwroom.markItemAsAcknowledged', itemId)
      .then(waitForInvocationReaction(connection.bee, () => {}, REACTION_TIMEOUT));
  }
}

module.exports = Client;
