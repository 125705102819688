import React, { Suspense } from "react";
import QueueViewer from "../QueueViewer/QueueViewer";
import { Observable } from "rxjs";
import { usePromise } from "../../Hooks/usePromise";
import { useObservable } from "../../Hooks/useObservable";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

function Counter<T>({
  top,
  bottom,
  promise,
  fetchFn = () => 0,
}: {
  top: string;
  bottom: string;
  promise: Promise<Observable<T>>;
  fetchFn?: (t: T) => number;
}) {
  const queue$ = usePromise<Observable<T>>(promise);
  const queue = useObservable<T>(queue$);
  const value = fetchFn ? fetchFn(queue) : undefined;

  return <QueueViewer top={top} bottom={bottom} number={value} />;
}

function AsyncQueueViewer<T>({
  top,
  bottom,
  promise,
  fetchFn,
  fallbackValue,
}: {
  top: string;
  bottom: string;
  promise: Promise<Observable<T>>;
  fetchFn?: (t: T) => number;
  fallbackValue?: number;
}) {
  return (
    <ErrorBoundary
      fallback={() => <QueueViewer top="" bottom="" number="Err" />}
    >
      <Suspense
        fallback={
          <QueueViewer top={top} bottom={bottom} number={fallbackValue} />
        }
      >
        <Counter
          top={top}
          bottom={bottom}
          promise={promise}
          fetchFn={fetchFn}
        />
      </Suspense>
    </ErrorBoundary>
  );
}

export default AsyncQueueViewer;
