import React, { useState, useCallback } from "react";
import "./ChatComposer.scss";
import { ChatView } from "./../../../Services/Chat";
import { Button } from "@material-ui/core";
import ImageBox from "../../ImageBox/ImageBox";
import { useExtendedTranslation } from "../../../Services/i18nService";

function ChatComposer({ chat }: { chat: ChatView }) {
  const t = useExtendedTranslation();
  const [message, setMessage] = useState("");
  const canSend = message && message.length > 0;

  const updateMessage = useCallback((e: any) => setMessage(e.target.value), [
    setMessage,
  ]);
  const send = useCallback(() => {
    chat.send({ message: message });
    setMessage("");
  }, [message, chat, setMessage]);
  const typing = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        send();
      } else {
        chat.userIsTyping();
      }
    },
    [chat, send]
  );

  return (
    <chat-composer>
      <composer-inner>
        <input
          type="text"
          onChange={updateMessage}
          onKeyDown={typing}
          value={message}
          placeholder={t("chat.enterMessage")}
        ></input>
        <Button disabled={!canSend} onClick={send}>
          {t("chat.sendButton")}
          <ImageBox
            url="Shared/send.svg"
            disabledUrl="Shared/send-inactive.svg"
          />
        </Button>
      </composer-inner>
    </chat-composer>
  );
}

export default ChatComposer;
