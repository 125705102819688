import React, { useState, useEffect, useCallback } from "react";
import { Select, MenuItem } from "@material-ui/core";
import { MapFn } from "../../util";
import { useMap } from "./../../Hooks/useMap";
import memoize from "lodash.memoize";

const displayItems = (items: any[]) =>
  items.map((v, i) => {
    return (
      <MenuItem key={i} value={v}>
        {v}
      </MenuItem>
    );
  });

function DEFAULT_FORMAT<T>(value: T) {
  return String(value);
}

const InQSelectorId: unique symbol = Symbol("InQSelectorId");
function getStableRandomId<T>(item: T): string {
  if (!(item as any)[InQSelectorId]) {
    (item as any)[InQSelectorId] = "" + Math.random();
  }
  return (item as any)[InQSelectorId];
}

const stableObjectReference = memoize((id) => {
  return {} as any;
});

export default function InQSelector<T extends object>({
  items,
  onChange,
  value,
  format = DEFAULT_FORMAT,
  itemId = getStableRandomId,
}: {
  items: T[];
  onChange: MapFn<T, void>;
  value: T | undefined;
  format?: MapFn<T, React.ReactNode>;
  itemId?: MapFn<T, string>;
}) {
  if (items.length === 0) {
    console.warn("There are no items !");
  }

  // Index used as a common point between both child list and the parent list;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [previouslySelectedIndex, setPreviouslySelectedIndex] = useState(0);
  const stableItems = useMap(items, (items) => {
    return items.map((singleItem) => {
      const id = itemId(singleItem);
      const result = Object.assign(stableObjectReference(id), singleItem);
      return result;
    });
  });

  const [formattedListOfItems, setFormattedListOfItems] = useState(
    stableItems?.map((v) => format(v))
  );

  // Format the child list whenenver a new items list appears
  useEffect(() => {
    setFormattedListOfItems(stableItems?.map((v) => format(v)));
  }, [stableItems, format]);

  useEffect(() => {
    const id = value ? itemId(value) : "";
    const newIndex = value
      ? items.findIndex((item) => itemId(item) === id)
      : -1;
    if (newIndex === -1) {
      console.error(
        "The new value does not exist in the list of items, did you remember to useInstanceConst?  or otherwise keep the object identity stable?"
      );
    }
    setSelectedIndex(newIndex);
  }, [value, itemId, items]);

  // Update the parent list using the newly choosen index
  useEffect(() => {
    if (selectedIndex === previouslySelectedIndex) {
      return;
    }
    setPreviouslySelectedIndex(selectedIndex);
    onChange(items[selectedIndex] || items[0]);
  }, [selectedIndex, items, onChange, previouslySelectedIndex]);

  const handleChange = useCallback(
    (e: any) => {
      let indexSelected = formattedListOfItems.indexOf(e?.target?.value);
      if (indexSelected === -1) {
        console.error(
          "The selected item no longer exists in the list of items, did you remember to useInstanceConst?  or otherwise keep the object identity stable?"
        );
      }
      setSelectedIndex(indexSelected);
    },
    [formattedListOfItems]
  );

  return (
    <inq-selector>
      <Select
        value={formattedListOfItems[selectedIndex] || ""}
        onChange={handleChange}
      >
        {displayItems(formattedListOfItems)}
      </Select>
    </inq-selector>
  );
}
