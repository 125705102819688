const _ = require('lodash');
const { assertConnection } = require('./utils');
const { fetchTimezone, formatLocation } = require('./location');

const EARTH_RADIUS_METERS = 6378137;

const normalizeAcos = val => {
  if (val > 1) {
    return 1;
  }
  if (val < -1) {
    return -1;
  }
  return val;
};

const toRad = value => value * Math.PI / 180;

const getDistance = (lat1, long1, lat2, long2) => {
  let distanceInRads = Math.acos(
    normalizeAcos(
      Math.sin(toRad(lat2)) * Math.sin(toRad(lat1)) +
      Math.cos(toRad(lat2)) *
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(long1) - toRad(long2))
    )
  );

  return Math.round(distanceInRads * EARTH_RADIUS_METERS);
};

const processLocation = (callerLatitude, callerLongitude) => location => {
  const latitude = _.get(location, 'properties.latitude');
  const longitude = _.get(location, 'properties.longitude');
  if (latitude && longitude) {
    const newLocation = _.cloneDeep(location);
    newLocation.properties.distanceFromCaller = getDistance(callerLatitude, callerLongitude, latitude, longitude);
    return newLocation;
  }

  return location;
};

function getLocations(callerLatitude, callerLongitude) {
  const connection = assertConnection();

  return connection.bee.storage.getClassInstancesByClassName('vwroom', 'location')
    .then(locations => {
      if (callerLatitude && callerLongitude) {
        locations = _.map(locations, processLocation(callerLatitude, callerLongitude));
        locations = _.sortBy(locations, [ 'properties.distanceFromCaller', 'properties.name' ]);
      }
      return locations;
    }).then(locations => {
      locations = _.map(locations, formatLocation);
      return locations;
    }).then(locations => {
      const promises = locations.map(async (location) => fetchTimezone(connection, location));
      return Promise.all(promises);
    })
    ;
}

module.exports = {
  getLocations,
  getDistance,
};