import React from "react";
import "./QueueViewer.scss";
import TextBlockDescription from "../StandardStyles/TextBlockDescription/TextBlockDescription";
import SevenSegmentDisplay from "../SevenSegmentDisplay/SevenSegmentDisplay";

function QueueViewer({
  top,
  bottom,
  number,
  pad = 3,
  extras,
}: {
  top: string;
  bottom: string;
  number?: number | "Err";
  pad?: number;
  extras?: JSX.Element;
}) {
  const msg =
    typeof number === "number" ? number : number === "Err" ? "Err" : "";

  return (
    <queue-viewer>
      <queue-viewer-start>
        <TextBlockDescription>{top}</TextBlockDescription>
      </queue-viewer-start>
      <queue-viewer-middle>
        <SevenSegmentDisplay message={msg} pad={pad}></SevenSegmentDisplay>
      </queue-viewer-middle>
      <queue-viewer-end>
        <TextBlockDescription>{bottom}</TextBlockDescription>
      </queue-viewer-end>
      {extras}
    </queue-viewer>
  );
}

export default QueueViewer;
