import React from "react";
import ItemSelector from "../ItemSelector/ItemSelector";
import {
  setThemeName,
  getThemeName,
  SupportedThemes,
} from "../../Services/ThemeService";

function ThemeSelector() {
  return (
    <ItemSelector
      items={Object.values(SupportedThemes)}
      activeItem={getThemeName()}
      setActiveItem={setThemeName}
    ></ItemSelector>
  );
}

export default ThemeSelector;
