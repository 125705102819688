import { getLanguage, setLanguage } from "../Shared/Services/i18nService";
import { Observable, ReplaySubject, BehaviorSubject } from "rxjs";
import { createAsyncStorageInterface } from "../Shared/Services/Storage";
import {
  autoTimeout,
  deepFreeze,
  lazy,
  memoizeFunction,
} from "./../Shared/util";
import {
  IClient,
  ILocation,
  IQueue,
  IQueueItem,
  IUserAnswers,
} from "../Shared/Types/IClient";
import { QueueItemState } from "../Shared/Types/QueueItemState";
import { Client } from "vwroom-client-lib";
import {
  AppType,
  connectWithStoredCredentials,
  connectAsClient,
} from "./../Shared/HiveService";
import { createSwappableSubject } from "./../Shared/SwappableSubject";
import {
  arrayBufferToByteString,
  byteStringToArrayBuffer,
} from "./../Shared/Services/Encrypt/Encrypt-utils";
import { uploadImage } from "../Shared/Services/BlobService";
import { uuidv4 } from "../Shared/Hooks/useGuid";
import { notifyOfFatalError } from "../Shared/Services/FatalErrorService";
import { AppNames } from "../Shared/AppNames";

const DEFAULT_ALLOW_SMS = true;

export interface IUserData {
  firstName: string;
  lastName: string;
  phone: string;
  allowSMS: boolean;
  ticketId?: string;
  locationId: string;
  queueName: string;
  rememberMe: boolean;
  isHighPriority: boolean;
  updateNumber?: number;
  completedQuestionnaire: boolean;
  skipHealthCardCapture: boolean;
  skipDocumentCapture: boolean;
  isHighRisk: boolean;
  userAnswers?: IUserAnswers;
}

export interface IUserUploadedDocuments {
  healthCard?: string;
}

const DEFAULT_USER_DATA: IUserData = {
  firstName: "",
  lastName: "",
  phone: "",
  allowSMS: DEFAULT_ALLOW_SMS,
  locationId: "",
  queueName: "",
  completedQuestionnaire: false,
  rememberMe: true,
  skipHealthCardCapture: false,
  skipDocumentCapture: false,
  isHighPriority: false,
  isHighRisk: false,
};

const MAXIMUM_ALLOWED_TIME_FOR_CHECKIN = 30 * 1000;

const LOCAL_STORAGE_DATA_KEY = "D";
const LOCAL_STORAGE_PICTURE_KEY = "E";
const storage = createAsyncStorageInterface("ds", "localstorage", "encrypted");
const currentObservable = new ReplaySubject<Readonly<IUserData>>(1);

let currentValue: IUserData = DEFAULT_USER_DATA;
const currentTicketObservable = createSwappableSubject<Readonly<IQueueItem>>();
const currentLocationObservable = createSwappableSubject<Readonly<ILocation>>();
let currentConnection: Promise<any>;
const allLocationsObservable = new ReplaySubject<ILocation[]>(1);

/**
 * If true output additional information during updates to allow tracing updates events mroe accurately
 */
const DEBUG_EVENT_TIMING = false;

function connect() {
  if (!currentConnection) {
    currentConnection = createNewConnection();
    currentConnection.catch((err) => console.error("Failed to connect", err));
  }
  return currentConnection;
}

async function createNewConnection() {
  let connection;
  try {
    connection = await connectWithStoredCredentials(AppType.Client);
  } catch (ignored) {
    connection = await connectAsClient();
  }

  return connection;
}

async function init() {
  try {
    const temp = (await storage.getItem(LOCAL_STORAGE_DATA_KEY)) || {};
    currentValue = Object.assign({}, DEFAULT_USER_DATA, temp);
    currentObservable.next(deepFreeze(currentValue));

    await connect();

    if (currentValue.locationId) {
      await recoverLocation(currentValue.locationId);
    }

    if (currentValue.ticketId) {
      await recoverTicket(currentValue.ticketId);
      currentTicketObservable.subscribe((ticket) => {
        updateUserData({ ticketId: ticket ? ticket.id : undefined });
      });
    }

    hasUploadedAPictureCache = !!(await getUserPicture());
  } catch (err) {
    console.error("Error occured during Data Service Initiialization", err);
  }
}
const isReadyPromise = init();

export function isReady() {
  return isReadyPromise;
}

export function watchUserData(): Observable<Readonly<IUserData>> {
  return currentObservable;
}

let updateNumber: number = 0;
export async function updateUserData(
  newData: Partial<IUserData>
): Promise<void> {
  const thisUpdate = updateNumber++;
  if (DEBUG_EVENT_TIMING) {
    console.debug("Begin updateUserData", thisUpdate, newData);
  }
  await isReadyPromise;
  const before = JSON.stringify(currentValue);
  currentValue = Object.assign({}, DEFAULT_USER_DATA, currentValue, newData);
  const after = JSON.stringify(currentValue);
  if (before !== after) {
    await storage.setItem(LOCAL_STORAGE_DATA_KEY, currentValue);
    const tagged = DEBUG_EVENT_TIMING
      ? Object.assign({}, currentValue, { updateNumber: thisUpdate })
      : currentValue;
    currentObservable.next(deepFreeze(tagged));
    if (DEBUG_EVENT_TIMING) {
      console.log("End updateUserData", thisUpdate, newData);
    }
  } else if (DEBUG_EVENT_TIMING) {
    console.log("End updateUserData", thisUpdate, "no-op");
  }
}

let hasUploadedAPictureCache: boolean;
let inMemoryPictureCache: string | undefined;
export async function updateUserPicture(picture: IUserUploadedDocuments) {
  await isReadyPromise;
  inMemoryPictureCache = picture.healthCard;
  hasUploadedAPictureCache = !!inMemoryPictureCache;
  try {
    await storage.setItem(LOCAL_STORAGE_PICTURE_KEY, picture.healthCard);
  } catch (err) {
    console.error(
      "Something went wrong while storing the users health card locally.  As long as the page isn't refreshed the registration should still work ok though"
    );
  }
}

async function getUserPicture() {
  if (inMemoryPictureCache) {
    return inMemoryPictureCache;
  } else {
    return await storage.getItem<string>(LOCAL_STORAGE_PICTURE_KEY);
  }
}

export function hasUploadedAPicture() {
  return hasUploadedAPictureCache;
}

export async function checkInCurrentUser() {
  return await checkIn(
    currentValue.firstName,
    currentValue.lastName,
    currentValue.phone,
    currentValue.isHighPriority,
    currentValue.isHighRisk,
    currentValue.rememberMe
  );
}

export async function checkIn(
  firstName: string,
  lastName: string,
  phone: string,
  isHighPriority: boolean,
  failedScreener: boolean,
  rememberMe: boolean
): Promise<void> {
  try {
    return await autoTimeout(
      checkInNoTimeout(
        firstName,
        lastName,
        phone,
        isHighPriority,
        failedScreener,
        rememberMe
      ),
      MAXIMUM_ALLOWED_TIME_FOR_CHECKIN
    );
  } catch (err) {
    console.error("Could not complete check in", err);
    notifyOfFatalError(err);
  }
}

async function checkInNoTimeout(
  firstName: string,
  lastName: string,
  phone: string,
  isHighPriority: boolean,
  failedScreener: boolean,
  rememberMe: boolean
): Promise<void> {
  await connect();
  if (!currentValue.locationId) {
    throw new Error("Do not have a locationId to check in to");
  }
  if (!firstName || !lastName || !phone) {
    throw new Error("CheckIn called but User Data was missing");
  }

  try {
    const client: IClient = new Client();
    const name = `${firstName} ${lastName}`;
    const answers = currentValue.userAnswers || {};
    const ticketNumber = "";
    const ticketId: string = await client.checkIn(
      currentValue.locationId,
      name,
      phone,
      getLanguage(),
      failedScreener,
      isHighPriority,
      answers,
      currentValue.allowSMS,
      ticketNumber,
      AppNames.ClientApp
    );
    if (isHighPriority) {
      await client.setSpecialNeeds(ticketId, isHighPriority);
    }

    if (hasUploadedAPicture()) {
      const img = await getUserPicture();
      if (img && img.length > 0) {
        const blob = await encodedByteStringToBlob(img);
        const file = new File([blob], `${uuidv4()}.png`);
        await uploadImage(ticketId, file);
      }
    }

    await updateUserData({
      ticketId,
      firstName: firstName,
      lastName: lastName,
      phone: rememberMe ? phone : undefined,
      allowSMS: rememberMe ? currentValue.allowSMS : DEFAULT_ALLOW_SMS,
      isHighPriority: rememberMe ? currentValue.isHighPriority : undefined,
      skipHealthCardCapture: false,
      skipDocumentCapture: false,
      userAnswers: undefined,
      completedQuestionnaire: false,
      isHighRisk: failedScreener,
    });

    if (!rememberMe) {
      updateUserPicture({ healthCard: undefined });
    }

    const ticket$ = client.recover(ticketId);
    return currentTicketObservable.swap(ticket$);
  } catch (err) {
    console.error("Could not complete check in (actual error occured)", err);
    notifyOfFatalError(err);
  }
}

export async function recoverTicket(ticketId: string): Promise<void> {
  await connect();
  const client: IClient = new Client();
  currentTicketObservable.swap(client.recover(ticketId));
}

export async function recoverLocation(locationId: string): Promise<void> {
  if (locationId) {
    await connect();
    const client: IClient = new Client();
    currentLocationObservable.swap(client.getLocation(locationId));
  } else {
    currentLocationObservable.swap(new Observable<ILocation>());
  }
}

export async function updateScreenerDeclarationBasedOnQuestionaireAnswers() {
  console.debug("Evaluating condition...", currentValue.userAnswers);
  let result = currentValue.userAnswers
    ? computeScreenerResult(currentValue.userAnswers)
    : false;
  await updateUserData({ isHighRisk: result });
}

function computeScreenerResult(answers: IUserAnswers) {
  console.debug("Computing the result of the questionnaire ", answers);
  const failed = (v: number | string[]) => {
    if (typeof v === "number") {
      // TODO: compare to actual question outcome
      return !v;
    } else {
      return v.length > 0;
    }
  };
  let hasFailed = Object.values(answers).some(failed);
  console.debug("Is the user a high risk visitor ? : ", hasFailed);
  return hasFailed;
}

async function setupLocations() {
  try {
    const client: IClient = new Client();
    const locations: ILocation[] = await client.getLocations();
    const anyLocation = locations.filter((l) => l)[0];
    if (!anyLocation) {
      throw new Error(
        "No locations, check to make sure hive is initialized correctly"
      );
    }

    const previousLocation = locations.filter(
      (location) => location.id === currentValue.locationId
    )[0];
    allLocationsObservable.next(locations);
    doSetupThisLocation(previousLocation || ({} as any));
  } catch (err) {
    console.error("Could not fetch locations", err);
    notifyOfFatalError(err);
  }
}
const setupLocationsOnce = lazy(setupLocations);

async function doSetupThisLocation(location: ILocation) {
  await updateUserData({
    locationId: location.id,
    queueName: location.initialQueueName,
  });
  await recoverLocation(location.id);
  if (
    location.supportedLanguages &&
    !location.supportedLanguages.includes(getLanguage())
  ) {
    setLanguage(location.supportedLanguages[0]);
  }
}

export async function setSMS(value: boolean) {
  await connect();
  const client: IClient = new Client();
  await client.setSmsAccessibility(currentValue.ticketId, value);
  await updateUserData({ allowSMS: value });
}

export function getCurrentLocation(): ILocation | undefined {
  let location: ILocation | undefined;
  currentLocationObservable.subscribe((l) => (location = l)).unsubscribe();
  return location;
}

function getCurrentTicketValue(): IQueueItem | undefined {
  let ticket: IQueueItem | undefined;
  currentTicketObservable.subscribe((t) => (ticket = t)).unsubscribe();
  return ticket;
}

export function watchCurrentTicket(): Observable<IQueueItem> {
  return currentTicketObservable;
}

export function watchCurrentLocation(): Observable<ILocation> {
  setupLocationsOnce();
  return currentLocationObservable;
}

export function watchAllLocations(): Observable<ILocation[]> {
  setupLocationsOnce();
  return allLocationsObservable;
}

export function setSelectedLocation(location: ILocation): void {
  doSetupThisLocation(location);
}

export async function markCurrentTicketAsAcknowledged(): Promise<void> {
  const ticket = getCurrentTicketValue();
  if (!ticket) {
    throw new Error("There is no current ticket");
  }

  const client: IClient = new Client();
  await client.markItemAsAcknowledged(ticket.id);
}

export async function revokeCurrentTicket(): Promise<void> {
  let ticket: IQueueItem | undefined = getCurrentTicketValue();

  if (!ticket) {
    throw new Error("There is no current ticket");
  }

  const client: IClient = new Client();
  await client.revokeItem(ticket.id);

  // clear ticket information
  await updateUserData({ ticketId: undefined });

  const clearedTicket = new BehaviorSubject<IQueueItem>({
    id: "",
    order: Number.MAX_VALUE,
    state: QueueItemState.REVOKED,
    lastMessage: "",
    queue: ticket.queue,
    location: ticket.location,
  });
  currentTicketObservable.swap(clearedTicket);
}

function watchQueueAtLocationAlwaysNew(
  locationId: string,
  queueName: string
): Observable<IQueue> {
  try {
    return new Client().getQueue(locationId, queueName);
  } catch (err) {
    console.error("Could not watch queue: " + queueName, err);
    notifyOfFatalError(err);
    return new ReplaySubject<IQueue>(1);
  }
}
export const watchQueueAtLocation = memoizeFunction(
  watchQueueAtLocationAlwaysNew,
  (id, name) => id + "|" + name
);

export async function watchQueueAtCurrentLocation(): Promise<
  Observable<IQueue>
> {
  await setupLocations();
  if (!currentValue.locationId || !currentValue.queueName) {
    throw new Error("Current location is unknown");
  }
  return watchQueueAtLocation(currentValue.locationId, currentValue.queueName);
}

export async function blobToEncodedByteString(blob: Blob): Promise<string> {
  const arrayBuffer = await new Response(blob).arrayBuffer();
  const byteString = arrayBufferToByteString(arrayBuffer);
  const encoded = btoa(byteString);
  return encoded;
}

export async function encodedByteStringToBlob(encoded: string): Promise<Blob> {
  const decodedByteString = atob(encoded);
  const arrayBuffer = byteStringToArrayBuffer(decodedByteString);
  const blob = new Blob([arrayBuffer]);
  return blob;
}
