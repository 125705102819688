import { lazy } from "../../util";
import { DeferredPromise } from "../../DeferredPromise";

const MAX_IMAGE_DIMENSION = 1080;

async function getCameraPermission(): Promise<void> {
  console.log("Testing for camera access");
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    console.log("Camera API available, checking for stream access");
    const dummyStream = await openVideoStreamForThisDevice(undefined);
    console.log(
      "Camera stream obtained, Camera api is ready for use.  (Now closing test stream)"
    );
    closeStream(dummyStream);
  } else {
    throw new Error("Camera not supported");
  }
}

/**
 * https://www.digitalocean.com/community/tutorials/front-and-rear-camera-access-with-javascripts-getusermedia
 */
async function getAllCameras(): Promise<MediaDeviceInfo[]> {
  try {
    await getCameraPermission();
    const allDevices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = allDevices.filter(
      (device) => device.kind === "videoinput"
    );
    return videoDevices;
  } catch (err) {
    console.error(err);
    return [];
  }
}
const allCameras = lazy(getAllCameras);

function openVideoStreamForThisDevice(
  camera: MediaDeviceInfo | undefined
): Promise<MediaStream> {
  const id = camera?.deviceId;
  const constraints = {
    video: {
      deviceId: id,
    },
  };

  console.debug(
    id
      ? `Requesting video stream for ${id}`
      : "Requesting any available video stream"
  );
  const streamPromise = navigator.mediaDevices.getUserMedia(constraints);
  streamPromise.then((stream) => {
    stream
      .getTracks()
      .forEach((track) => console.debug("Opening Video Track", track));
  });
  return streamPromise;
}

function closeStream(stream: MediaStream) {
  stream?.getTracks()?.forEach((track) => {
    console.debug("Disposing Video Track", track);
    track.stop();
  });
}

async function captureSnapshotAsBlob(
  video: HTMLVideoElement | undefined
): Promise<Blob> {
  if (!video) {
    throw new Error("No video source");
  }
  const longEdge = Math.min(
    MAX_IMAGE_DIMENSION,
    Math.max(video.videoWidth, video.videoHeight)
  );
  let width: number;
  let height: number;
  if (video.videoWidth > video.videoHeight) {
    width = longEdge;
    height = longEdge * (video.videoHeight / video.videoWidth);
  } else {
    height = longEdge;
    width = longEdge * (video.videoWidth / video.videoHeight);
  }
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  const context2d = canvas.getContext("2d");
  if (context2d) {
    context2d.drawImage(video, 0, 0, width, height);
  }
  const result = DeferredPromise<Blob>();
  canvas.toBlob(
    (blob) =>
      blob ? result.resolveFn(blob) : result.rejectFn("No blob captures"),
    "image/jpeg",
    0.7
  );
  return result.promise;
}

class CameraWrapper {
  currentStreamPromise?: Promise<MediaStream>;
  curentDevice?: MediaDeviceInfo;

  listOfAllCameras() {
    return allCameras();
  }

  switchCamera(device: MediaDeviceInfo) {
    if (device !== this.curentDevice || !this.currentStreamPromise) {
      this.closeCamera();
      this.currentStreamPromise = openVideoStreamForThisDevice(device);
      this.curentDevice = device;
    }
    return this.currentStreamPromise;
  }

  closeCamera() {
    if (this.currentStreamPromise) {
      this.currentStreamPromise.then((stream: MediaStream) =>
        closeStream(stream)
      );
      this.currentStreamPromise = undefined;
      this.curentDevice = undefined;
    }
  }

  capture(video: HTMLVideoElement | undefined) {
    return captureSnapshotAsBlob(video);
  }
}

const CameraWrapperSingleton = new CameraWrapper();
export default CameraWrapperSingleton;
