import { createMuiTheme } from "@material-ui/core";
import { InQStyleGuide } from "./../Shared/constants";

const LightTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: InQStyleGuide.blue.medium,
      light: InQStyleGuide.blue.light,
      dark: InQStyleGuide.blue.dark,
    },
    secondary: { main: InQStyleGuide.blue.bright },
    info: { main: InQStyleGuide.blue.bright },
    warning: { main: InQStyleGuide.orange },
    error: { main: InQStyleGuide.red },
    success: { main: InQStyleGuide.green },
  },
  typography: {
    fontFamily: "Open Sans",
  },
});

export default LightTheme;
