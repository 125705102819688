import React from "react";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import "./ScreenWelcome.scss";
import { appName, welcomeScreenDescription } from "./../../Shared/Services/branding";
import { useExtendedTranslation } from "./../../Shared/Services/i18nService";
import { Button } from "@material-ui/core";
import TextBlockDescription from "./../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import {
  isReady,
  watchQueueAtCurrentLocation,
} from "./../../Services/DataService";
import { IQueue } from "../../Shared/Types/IClient";
import { useDeferredPromise } from "../../Shared/Hooks/useDeferredPromise";
import { AsyncQueueViewer, LeftCenterRight } from "../../Shared/Components";
import { beginUserCheckIn } from "../../Services/WorkflowService";
import ButtonBackToLocationSelect from "../../Components/ButtonBackToLocationSelect/ButtonBackToLocationSelect";

function ScreenWelcome() {
  const { promise, resolveFn, rejectFn } = useDeferredPromise<IQueue>(() => {
    isReady().then(watchQueueAtCurrentLocation).then(resolveFn).catch(rejectFn);
  });
  const t = useExtendedTranslation();
  const name = appName();
  const welcomeDescription = welcomeScreenDescription();

  return (
    <screen-welcome>
      <SinglePanelLayout
        footer={
          <LeftCenterRight
            left={<ButtonBackToLocationSelect />}
            center={
              <Button
                variant="contained"
                color="primary"
                onClick={beginUserCheckIn}
              >
                {t("welcome.checkin.button")}
              </Button>
            }
          />
        }
        title={t.map(
          "welcome.title",
          [t.plainText, (text) => <app-title>{text}</app-title>, t.plainText],
          { name }
        )}
      >
        <TextBlockDescription>
          {t(welcomeDescription)}
        </TextBlockDescription>
        <AsyncQueueViewer
          top={t("welcome.queue.top")}
          bottom={t("welcome.queue.bottom")}
          promise={promise}
          fetchFn={(q: IQueue) => q.itemCount}
        />
        <AsyncQueueViewer
          top={t("welcome.waittime.top")}
          bottom={t("welcome.waittime.bottom")}
          promise={promise}
          fetchFn={(q: IQueue) => Math.round(q.averageWaitTime / 60) || 0}
        />
      </SinglePanelLayout>
    </screen-welcome>
  );
}

export default ScreenWelcome;
