import React from "react";
import InQFieldDefaultInput, {
  InQFieldProps,
} from "../InQFieldDefaultInput/InQFieldDefaultInput";

import "./InQField.scss";

function InQField(props: InQFieldProps) {
  const { className, id, label, hint, ...moreProps } = props;

  return (
    <inq-field id={id} class={className}>
      <field-label>{label}</field-label>
      <field-value>
        <InQFieldDefaultInput {...moreProps} />
      </field-value>
      {hint ? <field-hint>{hint}</field-hint> : <></>}
    </inq-field>
  );
}

export default InQField;
