import { Observable } from "rxjs";
import { ReplaySubject } from "rxjs";
import { Subscription } from "rxjs";

/**
 * A subject that can be swapped in real time with other observables without disrupting any existing subscriptions
 */
export interface SwappableSubject<T> extends Observable<T> {
  /**
   * Swaps the source observable with another
   * @param observable The replacement observable to source events from
   */
  swap(observable: Observable<T>): void;
}

/**
 * Creates a swappable subject
 * @param buffer [1] - The number of past events to replay when a new subscription is made
 */
export function createSwappableSubject<T>(
  buffer: number = 1
): SwappableSubject<T> {
  const externalFace = new ReplaySubject(buffer);

  let currentSubscription: Subscription;
  const swap = (obs: Observable<T>) => {
    if (currentSubscription) {
      currentSubscription.unsubscribe();
    }
    currentSubscription = obs.subscribe((value) => {
      externalFace.next(value);
    });
  };
  const result = (externalFace as any) as SwappableSubject<T>;
  result.swap = swap;
  return result;
}
