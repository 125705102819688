import React from "react";
import InQFieldDefaultInput, {
  InQFieldProps,
} from "./../InQFieldDefaultInput/InQFieldDefaultInput";

import "./InQSideBySideField.scss";

function InQSideBySideField(props: InQFieldProps) {
  const { className, id, ...moreProps } = props;
  return (
    <inq-side-by-side-field id={id} class={className}>
      <field-label>{props.label}</field-label>
      <field-value>
        <InQFieldDefaultInput {...moreProps} />
      </field-value>
    </inq-side-by-side-field>
  );
}

export default InQSideBySideField;
