import React from "react";
import SinglePanelLayout from "../../../Components/Layout/SinglePanel/SinglePanel";
import TextBlockDescription from "../../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import { useExtendedTranslation } from "../../../Shared/Services/i18nService";
import { appName } from "./../../../Shared/Services/branding";
import {
  TextBlockBigText,
  TextBlockHint,
} from "../../../Shared/Components/StandardStyles";

import "./Closing.scss";
import { LeftCenterRight } from "../../../Shared/Components";
import ButtonBackToLocationSelect from "../../../Components/ButtonBackToLocationSelect/ButtonBackToLocationSelect";

function ScreenClosing() {
  const t = useExtendedTranslation();
  const name = appName();

  return (
    <screen-closing>
      <SinglePanelLayout
        title={t.map(
          "closing.welcome.title",
          [t.plainText, (text) => <app-title>{text}</app-title>, t.plainText],
          { name }
        )}
        footer={<LeftCenterRight left={<ButtonBackToLocationSelect />} />}
      >
        <TextBlockDescription>
          {t("closing.welcome.description", { name })}
        </TextBlockDescription>

        <screen-closing-center>
          <TextBlockBigText>{t("closing.closed_title")}</TextBlockBigText>
        </screen-closing-center>
        <TextBlockHint>
          {t("closing.closed_description", { name })}
        </TextBlockHint>
      </SinglePanelLayout>
    </screen-closing>
  );
}

export default ScreenClosing;
