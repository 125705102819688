import React from "react";
import "./CountdownTimer.scss";
import SevenSegmentDisplay from "../../Shared/Components/SevenSegmentDisplay/SevenSegmentDisplay";
import { Paper } from "@material-ui/core";
import { formatDuration } from "../../Util/util";

function CountdownTimer({ seconds }: { seconds: number }) {
  const timerColor = seconds <= 30 ? (seconds <= 0 ? "red" : "orange") : "blue";

  return (
    <countdown-timer>
      <Paper id="timerbox" elevation={4}>
        {timerColor === "blue" && (
          <blue-timer>
            <SevenSegmentDisplay
              message={formatDuration(seconds)}
              pad={3}
            ></SevenSegmentDisplay>
          </blue-timer>
        )}
        {timerColor === "orange" && (
          <orange-timer>
            <SevenSegmentDisplay
              message={formatDuration(seconds)}
              pad={3}
            ></SevenSegmentDisplay>
          </orange-timer>
        )}
        {timerColor === "red" && (
          <red-timer>
            <SevenSegmentDisplay
              message={formatDuration(seconds)}
              pad={3}
            ></SevenSegmentDisplay>
          </red-timer>
        )}
      </Paper>
    </countdown-timer>
  );
}

export default CountdownTimer;
