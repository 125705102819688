import React from "react";
import { FeatureFlags, isFeatureEnabled } from "../../Services/featureFlags";

export function FeatureFilter({
  feature,
  children,
  andAlso = true,
}: {
  feature: FeatureFlags | FeatureFlags[];
  children?: React.ReactNode;
  andAlso?: boolean;
}) {
  const flags = Array.isArray(feature) ? feature : [feature];

  const allEnabled = flags.every((flag) => isFeatureEnabled(flag));
  return allEnabled && andAlso ? children : <></>;
}
