import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./index.scss";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import { Spinner } from "./Shared/Components/Spinner/Spinner";
import { ThemeProvider } from "@material-ui/core/styles";
import { getTheme, init as initThemes } from "./Shared/Services/ThemeService";
import LightTheme from "./Themes/lightTheme";
import DarkTheme from "./Themes/darkTheme";
import { init as initI18n } from "./Shared/Services/i18nService";
import { default as en } from "./i18n/en.json";
import { default as fr } from "./i18n/fr.json";
import { default as sharedEn } from "./Shared/i18n/en.json";
import { default as sharedFr } from "./Shared/i18n/fr.json";
import { mergeObjects } from "./Shared/Services/Chat/chat.util";
import "typeface-open-sans";
import ErrorBoundary from "./Shared/Components/ErrorBoundary/ErrorBoundary";

initThemes(LightTheme, DarkTheme);
initI18n(mergeObjects<any>(sharedEn, en), mergeObjects<any>(sharedFr, fr));

declare module "react" {
  namespace JSX {
    interface IntrinsicElements {
      [elemName: string]: any;
    }
  }
}

const onError = (e: any) => (
  <>Sorry, Something went wrong {JSON.stringify(e)}</>
);

const app = (
  <>
    <CssBaseline />
    <ErrorBoundary fallback={onError}>
      <Suspense fallback={<Spinner />}>
        <App />
      </Suspense>
    </ErrorBoundary>
  </>
);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={getTheme()} children={app} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
