import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if ((this.state as any).hasError) {
      return (this.props as any).fallback ? (
        (this.props as any).fallback((this.state as any).error)
      ) : (
        <>
          <h1>Something went wrong, and there is no fallback content.</h1>;
        </>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
