import React from "react";
import "./ItemSelector.scss";
import { Button, ButtonGroup } from "@material-ui/core";

const getText = (item: any) => item + "";

function ItemSelector<T>({
  items,
  activeItem,
  setActiveItem,
}: {
  items: T[];
  activeItem: T;
  setActiveItem: (item: T) => void;
}) {
  const isActive = (item: T) => item === activeItem;
  const displayItem = (item: T) => {
    const text = getText(item);
    return (
      <Button
        key={text}
        onClick={() => setActiveItem(item)}
        disabled={isActive(item)}
      >
        {text}
      </Button>
    );
  };

  const itemsDisplay = items.map(displayItem);

  return (
    <item-selector>
      <ButtonGroup variant="contained" color="secondary">
        {itemsDisplay}
      </ButtonGroup>
    </item-selector>
  );
}

export default ItemSelector;
