import React from "react";
import { Button } from "@material-ui/core";

import SinglePanelLayout from "../../../Components/Layout/SinglePanel/SinglePanel";
import {
  getCurrentLocation,
  watchCurrentTicket,
} from "../../../Services/DataService";
import {
  rejoinQueue,
  restartWorkflow,
} from "../../../Services/WorkflowService";
import {
  TextBlockDescription,
  TextBlockHint,
} from "../../../Shared/Components/StandardStyles";
import { useObservable } from "../../../Shared/Hooks";
import { isQueueOpen } from "../../../Shared/hoursUtils";
import { appName } from "../../../Shared/Services/branding";
import { useExtendedTranslation } from "../../../Shared/Services/i18nService";

function ScreenRemoved() {
  const t = useExtendedTranslation();
  const name = appName();

  const ticket = useObservable(watchCurrentTicket());
  const lastMessage = ticket?.lastMessage;

  const location = getCurrentLocation();
  const isQueueClosed = !isQueueOpen(location);

  let allowRejoin = !isQueueClosed;

  let message;
  if (isQueueClosed) {
    message = (
      <TextBlockDescription>
        {t("removed.closing.description", { name })}
      </TextBlockDescription>
    );
  } else if (lastMessage) {
    message = lastMessage
      .split("|")
      .map((text) => <TextBlockDescription>{text}</TextBlockDescription>);
  } else {
    message = t.map("removed.standard_rejection", (text) => (
      <TextBlockDescription>{text}</TextBlockDescription>
    ));
  }

  return (
    <screen-removed>
      <SinglePanelLayout
        title={
          <>
            {t(
              isQueueClosed ? "removed.closing.title" : "removed.removed.title"
            )}
          </>
        }
        footer={
          allowRejoin ? (
            <Button variant="contained" color="primary" onClick={rejoinQueue}>
              {t("removed.rejoin")}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={restartWorkflow}
            >
              {t("removed.continue")}
            </Button>
          )
        }
      >
        {message}
        {allowRejoin ? (
          <TextBlockHint>{t("removed.hint")}</TextBlockHint>
        ) : (
          <></>
        )}
      </SinglePanelLayout>
    </screen-removed>
  );
}

export default ScreenRemoved;
