import React from "react";
import "./ChatAvatar.scss";
import { ParticipantView } from "./../../../Services/Chat/chat.model";
import { normalizeImageUrl } from "./../../ImageBox/ImageBox";

export enum AvatarSize {
  Big = "big",
  Small = "small",
}

const DEFAULT_AVATAR_SIZE = AvatarSize.Big;

function ChatAvatar({
  who,
  size = DEFAULT_AVATAR_SIZE,
}: {
  who: ParticipantView;
  size?: AvatarSize;
}) {
  const style = {
    backgroundImage: `url('${normalizeImageUrl(who.avatarUrl)}')`,
  };
  const words = who.name.split(" ").filter((n) => n && n.length > 0);
  const initials = words.map((w) => w[0]);
  const initialsString = initials.join(" ");
  const className = "" + size;
  return (
    <chat-avatar class={className} style={style} title={who.name}>
      <user-initials>{initialsString}</user-initials>
    </chat-avatar>
  );
}

export default ChatAvatar;
