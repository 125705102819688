import React, { useCallback, useState, useEffect } from "react";
import "./ScreenHealthCardCapture.scss";
import InputCamera from "./../../Shared/Components/Camera/InputCamera";
import FullScreenPanelLayout from "../../Components/Layout/FullScreenPanel/FullScreenPanel";
import {
  useMap,
  useInstanceConst,
  useMaybePromise,
} from "./../../Shared/Hooks";
import { IconButton } from "@material-ui/core";
import { Subject } from "rxjs";
import { ImageBox, StyledFileInput } from "../../Shared/Components";
import CameraWrapperSingleton from "../../Shared/Components/Camera/cameraWrapper";
import { Maybe } from "../../Shared/util";
import { createSyncStorageInterface } from "./../../Shared/Services/Storage";
import { advanceUserCheckInWorkflow } from "../../Services/WorkflowService";
import { useObservable } from "./../../Shared/Hooks/useObservable";
import {
  blobToEncodedByteString,
  updateUserPicture,
} from "../../Services/DataService";

const cameraPreferences = createSyncStorageInterface("camera", "localstorage");
const LAST_USED_KEY = "LastUsed";

function ScreenHealthCardCapture() {
  const advanceIsDisabled = useObservable(
    advanceUserCheckInWorkflow.watchIsBusy()
  );
  const cameras = useMaybePromise(CameraWrapperSingleton.listOfAllCameras());
  const [selectedCameraIndex, setSelectedCameraIndex] = useState(
    () => cameraPreferences.getItem<number>(LAST_USED_KEY) || 0
  );
  const [selectedCameraId, setSelectedCameraId] = useState("");
  useEffect(() => {
    let newId;
    if (cameras && cameras.length > 0) {
      newId =
        selectedCameraIndex > 0 && selectedCameraIndex < cameras.length
          ? cameras[selectedCameraIndex].deviceId
          : undefined;
    }
    console.debug("Camera id", newId);
    setSelectedCameraId(newId || "");
  }, [selectedCameraIndex, cameras]);
  const cameraButton = useInstanceConst(() => new Subject());

  const [snapshot, setSnapshot] = useState<Maybe<Blob>>();

  const imageCaptured = useCallback((image: Blob) => {
    console.log("Image captured", image);
    setSnapshot(image);
  }, []);

  const switchSelectedCamera = useCallback(() => {
    if (!cameras || cameras.length === 0) {
      console.debug("No cameras found, can't switch input");
      return;
    }
    const newIndex = (selectedCameraIndex + 1) % (cameras.length || 1);
    console.debug(`Camera index changed ${selectedCameraIndex} ${newIndex}`);
    setSelectedCameraIndex(newIndex);
    cameraPreferences.setItem(LAST_USED_KEY, newIndex);
  }, [selectedCameraIndex, cameras]);

  const snapshotUrl = useMap(snapshot, (blob) => {
    return blob ? URL.createObjectURL(blob) : "";
  });

  const useFile = useCallback(
    (blob: Blob) => {
      console.log("blob uploaded");
      setSnapshot(blob);
      setShowInput(false);
    },
    [setSnapshot]
  );

  const [showInput, setShowInput] = useState(true);

  const takePicture = useCallback(() => {
    cameraButton.next();
    setShowInput(false);
  }, [cameraButton, setShowInput]);

  const clearPicture = useCallback(() => {
    setShowInput(true);
  }, [setShowInput]);

  const refuseToTakePicture = useCallback(() => {
    advanceUserCheckInWorkflow();
  }, []);
  const acceptPicture = useCallback(async () => {
    if (snapshot) {
      const encodedImage = await blobToEncodedByteString(snapshot);
      await updateUserPicture({ healthCard: encodedImage });
    }
    advanceUserCheckInWorkflow();
  }, [snapshot]);

  const button_refuse = (
    <IconButton onClick={refuseToTakePicture}>
      <ImageBox url="camera_cancel.svg"></ImageBox>
    </IconButton>
  );
  const button_switch = (
    <IconButton onClick={switchSelectedCamera}>
      <ImageBox url="camera_switch.svg"></ImageBox>
    </IconButton>
  );
  const button_placeholder = <IconButton className="placeholder"></IconButton>;
  const button_takePicture = (
    <IconButton onClick={takePicture}>
      <ImageBox url="camera_capture.svg" className="bigIcon"></ImageBox>
    </IconButton>
  );
  const button_fileInput = (
    <StyledFileInput
      accept={["image/png", "image/jpeg"]}
      isIcon={true}
      onFileSelected={useFile}
    >
      <ImageBox url="camera_upload.svg"></ImageBox>
    </StyledFileInput>
  );
  const button_retake = (
    <IconButton onClick={clearPicture}>
      <ImageBox url="camera_retake.svg"></ImageBox>
    </IconButton>
  );
  const button_accept = (
    <IconButton onClick={acceptPicture} disabled={advanceIsDisabled}>
      <ImageBox
        url="camera_accept.svg"
        disabledUrl="camera_accept_disabled.svg"
        className="bigIcon"
      ></ImageBox>
    </IconButton>
  );

  return (
    <screen-health-card-capture>
      <FullScreenPanelLayout>
        <camera-preview>
          {showInput ? (
            <InputCamera
              captureImage={cameraButton}
              onImageCaptured={imageCaptured}
              selectedCameraId={selectedCameraId}
            ></InputCamera>
          ) : (
            <ImageBox id="previewImage" url={snapshotUrl}></ImageBox>
          )}
        </camera-preview>
        <camera-controls-top>
          {showInput ? (
            <>
              {button_refuse}
              {button_switch}
            </>
          ) : (
            <>
              {button_refuse}
              {button_placeholder}
            </>
          )}
        </camera-controls-top>
        <camera-controls-bottom>
          {showInput ? (
            <>
              {button_placeholder}
              {button_takePicture}
              {button_fileInput}
            </>
          ) : (
            <>
              {button_retake}
              {button_accept}
              {button_fileInput}
            </>
          )}
        </camera-controls-bottom>
      </FullScreenPanelLayout>
    </screen-health-card-capture>
  );
}

export default ScreenHealthCardCapture;
