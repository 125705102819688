import React from "react";
import "./ChatMessage.scss";
import { MessageView, MessageDirection } from "./../../../Services/Chat";
import ChatAvatar from "../ChatAvatar/ChatAvatar";

function ChatMessage({ message }: { message: MessageView }) {
  const className =
    message.direction === MessageDirection.Received ? "received" : "sent";
  return (
    <chat-message class={className}>
      <ChatAvatar who={message.author} />
      <message-bubble>{message.message}</message-bubble>
    </chat-message>
  );
}

export default ChatMessage;
