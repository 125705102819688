import React from "react";
import memoize from "lodash.memoize";
import "./SvgImport.scss";
import { useMaybePromise } from "../../Hooks";
import { normalizeImageUrl } from "./../ImageBox/ImageBox";

export function SvgImport({ url }: { url: string }) {
  const file = useMaybePromise(fetchUrl(url));
  const checkFile = file ? file.toLowerCase().trim() : "";
  if (
    checkFile &&
    !(checkFile.startsWith("<svg") && checkFile.endsWith("</svg>"))
  ) {
    throw new Error("Not a valid SVG file");
  }

  return file ? (
    <svg-import data-url={url} dangerouslySetInnerHTML={{ __html: file }} />
  ) : (
    <></>
  );
}

export function prefetchSvgImport(url: string) {
  fetchUrl(url);
  return <SvgImport url={url} />;
}

const fetchUrl: (url: string) => Promise<string> = memoize((url: string) => {
  const normalizedUrl = normalizeImageUrl(url);
  if (normalizedUrl.toLowerCase().includes("://")) {
    throw new Error(
      "SvgImport can only be used to load images using relative path.  url=" +
        url
    );
  }
  return fetch(normalizedUrl).then((response) => response.text());
});

export default SvgImport;
