import React, { useState } from "react";
import { Redirect } from "react-router-dom";

export function useRedirect() {
  const [redirectTo, setRedirectTo] = useState("");

  let redirectComponent;
  if (redirectTo) {
    redirectComponent = React.createElement(Redirect, { to: redirectTo });
  }

  return { redirectTo, setRedirectTo, redirectComponent };
}
