import React from "react";
import { Button } from "@material-ui/core";
import { selectNewLocation } from "../../Services/WorkflowService";
import { useMaybeObservable } from "../../Shared/Hooks";
import { watchAllLocations } from "./../../Services/DataService";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

function ButtonBackToLocationSelect() {
  const allLocations = useMaybeObservable(watchAllLocations());
  const showButton = allLocations && allLocations.length > 1;

  return showButton ? (
    <Button color="primary" variant="contained" onClick={selectNewLocation}>
      <ChevronLeftIcon />
    </Button>
  ) : (
    <></>
  );
}

export default ButtonBackToLocationSelect;
