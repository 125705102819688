import React from "react";
import "./SevenSegmentDisplay.scss";
import SevenSegmentDigit from "../SevenSegmentDigit/SevenSegmentDigit";
import KeyedFragment from "./../KeyedFragment";

function DotDot() {
  return (
    <seven-segment-dotdot>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 6 20">
        <circle id="dotdot" cx="2" cy="5" r="1" />
        <circle id="dotdot" cx="2" cy="12" r="1" />
      </svg>
    </seven-segment-dotdot>
  );
}

function SevenSegmentDisplay({
  message,
  pad = 0,
}: {
  message: string | number;
  pad?: number;
}) {
  const messageAsString = message + "";
  const characters = messageAsString.split("");
  while (characters.length < pad) {
    characters.unshift("");
  }
  const digits = characters.map((c, i) => (
    <KeyedFragment key={i}>
      {c === ":" && <DotDot></DotDot>}
      {c !== ":" && <SevenSegmentDigit character={c}></SevenSegmentDigit>}
    </KeyedFragment>
  ));

  return <seven-segment-panel>{digits}</seven-segment-panel>;
}

export default SevenSegmentDisplay;
