import { useEffect } from "react";

/**
 * An effect which runs asynchronously.  It is up to the consumer to protect against race conditions and catch errors
 * @param fnRef An async function to be invoked when the deps change.  It must not accept any values, and must not return any values
 * @param deps A list of dependencies
 */
export function useAsyncEffect(fnRef: () => Promise<void>, deps: any[]) {
  useEffect(() => {
    fnRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fnRef, ...deps]);
}
