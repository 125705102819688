import { Observable, ReplaySubject } from "rxjs";

const fatalErrorObservable = new ReplaySubject<any>(1);

/**
 * An unrecoverable error such as the hive being inaccessible
 * @param error Details of the error if available
 */
export function notifyOfFatalError(error: any) {
  fatalErrorObservable.next(error);
}

/**
 * Watch for any fatal errors
 */
export function watchForFatalErrors(): Observable<any> {
  return fatalErrorObservable;
}
