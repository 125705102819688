import React from "react";
import SinglePanelLayout from "../../../Components/Layout/SinglePanel/SinglePanel";
import TextBlockDescription from "../../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import { useExtendedTranslation } from "../../../Shared/Services/i18nService";
import TextBlockHint from "../../../Shared/Components/StandardStyles/TextBlockHint/TextBlockHint";
import { Button } from "@material-ui/core";
import { useObservable } from "./../../../Shared/Hooks/useObservable";
import { createClock } from "../../../Shared/observableClock";
import { useInit } from "../../../Shared/Hooks";

function reload() {
  window.location.reload();
}

const AUTO_RELOAD_TIME_SECONDS = 60;

function ScreenFatalError() {
  const t = useExtendedTranslation();
  const countdown = useObservable(createClock());
  const timeLeft = Math.max(0, AUTO_RELOAD_TIME_SECONDS - countdown);
  useInit(() => {
    setTimeout(reload, AUTO_RELOAD_TIME_SECONDS * 1000);
  });

  return (
    <screen-fatal-error>
      <SinglePanelLayout
        title={<>{t("fatalerror.title")}</>}
        footer={
          <Button variant="contained" color="primary" onClick={reload}>
            {t("fatalerror.reload", { timeLeft })}
          </Button>
        }
      >
        <TextBlockDescription>
          {t("fatalerror.description")}
        </TextBlockDescription>
        <TextBlockHint>{t("fatalerror.hint")}</TextBlockHint>
      </SinglePanelLayout>
    </screen-fatal-error>
  );
}

export default ScreenFatalError;
