import React from "react";
import "./InQToggleSwitch.scss";
import { useRef } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useGuid } from "../../Hooks";
import { useEffect } from "react";

function InQToggleSwitch({
  label,
  checked,
  disabled,
  onChange,
  id,
}: {
  label?: JSX.Element | string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (isChecked: boolean) => void;
  id?: string;
}) {
  const checkboxRef = useRef();
  const [isChecked, setIsChecked] = useState(checked);
  const checkboxId = useGuid();
  const handleChange = useCallback(
    (e: any) => {
      const elem: any = checkboxRef?.current;
      if (!elem || disabled) {
        return;
      }
      const state = elem.checked;
      if (state !== isChecked) {
        setIsChecked(state);
        if (onChange) {
          onChange(state);
        }
      }
    },
    [checkboxRef, isChecked, disabled, onChange]
  );
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <inq-toggle-switch id={id} class={disabled ? "disabled" : ""}>
      <input
        id={checkboxId}
        ref={checkboxRef}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        tabIndex="0"
      ></input>
      <label htmlFor={checkboxId}>
        <inq-toggle-switch-visual>
          <svg
            className={isChecked ? "checked" : "unchecked"}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="36"
            height="18"
            viewBox="0 0 36 18"
          >
            <defs>
              <circle id="off-b" cx="8" cy="8" r="7" />
              <filter
                id="off-a"
                width="200%"
                height="200%"
                x="-50%"
                y="-35.7%"
                filterUnits="objectBoundingBox"
              >
                <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                <feGaussianBlur
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                  stdDeviation="2"
                />
                <feComposite
                  in="shadowBlurOuter1"
                  in2="SourceAlpha"
                  operator="out"
                  result="shadowBlurOuter1"
                />
                <feColorMatrix
                  in="shadowBlurOuter1"
                  values="0 0 0 0 0.466666667   0 0 0 0 0.466666667   0 0 0 0 0.466666667  0 0 0 0.5 0"
                />
              </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
              <rect
                width="35"
                height="15"
                x=".5"
                y="1.5"
                fill="#FFF"
                stroke="#CDCDCD"
                rx="7.5"
              />
              <g className="knob">
                <use fill="#000" filter="url(#off-a)" xlinkHref="#off-b" />
                <use
                  fill="#000"
                  fillOpacity="0"
                  xlinkHref="#off-b"
                  style={{ mixBlendMode: "multiply" }}
                />
                <circle cx="8" cy="8" r="6.5" fill="#FFF" stroke="#FFF" />
                <circle
                  className="middleKnob"
                  cx="8"
                  cy="8"
                  r="6"
                  fill="#E6E6E8"
                />
              </g>
            </g>
          </svg>
        </inq-toggle-switch-visual>
        <inq-toggle-switch-label>{label}</inq-toggle-switch-label>
      </label>
    </inq-toggle-switch>
  );
}

export default InQToggleSwitch;
