const _ = require('lodash');
const hiveBee = require('hive-bee');
const { BehaviorSubject } = require('rxjs');
// Do NOT load './utils' from here! It creates circular dependencies

// Number of core reactions to keep
const KEEP_LAST_CORE_REACTIONS = 10;

let _subscriptionId, setSubscriptionId = v => _subscriptionId = v;

const _connection$ = new BehaviorSubject(undefined);

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const setBee = (token, username, bee) => {
  _connection$.next({ token, username, bee });
};

const clearBee = () => {
  _connection$.next(undefined);
};

const handleReaction = (bee) => reaction => {
  bee.lastReactions.push(reaction);
  bee.lastReactions = _.takeRight(bee.lastReactions, KEEP_LAST_CORE_REACTIONS);
};

const clearCallbacks = (bee) => {
  if (bee) {
    if (bee.callbackIds) {
      for (let callbackId of bee.callbackIds) {
        bee.reactions.removeCallback(callbackId);
      }
      bee.callbackIds = [];
    }

    bee.lastReactions = [];
  }
};

function initBee(config, username, token) {
  console.debug('Initializing authenticated bee...');

  const host = _.get(config, 'host', 'http://localhost');
  hiveBee.urls.setUrls(host, host, host);
  const bee = hiveBee.init(token);

  bee.on('reactions.error', (_ignored, error) => handleBeeError(error));
  bee.on('shell.error', error => handleBeeError(error));

  // Keep the last N core reactions
  bee.lastReactions = [];
  bee.callbackIds = [];
  bee.callbackIds.push(bee.reactions.setCallback('core', handleReaction(bee)));
  bee.callbackIds.push(bee.reactions.setCallback('invocation', handleReaction(bee)));

  // Check if we have the everyone role (don't care about the result, it just validates the token)
  let pr = bee.auth.hasRole('everyone')
    .then(hasRole => {
      // If we do get a response, but the answer is false, we have a token that is still valid, but
      // not its user. Since 'everyone' should have the everyone role, then we make sure that we
      // get true here.
      if (!hasRole) {
        return Promise.reject('User does not have required roles');
      }
    })
  ;

  const categories = _.get(config, 'categories', []);
  if (!_.isEmpty(categories)) {
    pr = pr
      .then(() => bee.reactions.subscribe(categories))
      .then(setSubscriptionId)
      .then(() => sleep(1000)); // TODO: fix libs/reactions to not return until WS is connected!
  }

  return pr
    .then(() => {
      setBee(token, username, bee);
      console.debug('Initialized authenticated bee');
      return bee;
    })
    .catch(e => {
      console.error('Error while initializing bee', e);
      clearCallbacks(bee);
      clearBee();
      throw e;
    })
  ;
}

function releaseBee() {
  console.debug('Releasing authenticated bee...');
  let pr = Promise.resolve();

  let bee;
  _connection$.subscribe(c => bee = _.get(c, 'bee')).unsubscribe();
  if (bee) {
    clearCallbacks(bee);

    if (_subscriptionId) {
      pr = bee.reactions.unsubscribe(_subscriptionId)
        .then(() => setSubscriptionId(undefined));
    }
    pr = pr
      .then(bee.auth.revokeToken)
      .then(() => {
        clearBee();
        console.debug('Released authenticated bee');
      })
      .catch(e => {
        clearBee();
        console.error('Error while releasing authenticated bee', e);
      });
  }

  return pr;
}

function signIn(authFn, config, username, password) {
  if (!username) {
    throw new Error('User name cannot be empty');
  }

  if (!password) {
    throw new Error('Password cannot be empty');
  }

  const host = _.get(config, 'host', 'http://localhost');
  const orgId = _.get(config, 'orgId');
  const appId = _.get(config, 'appId');
  hiveBee.urls.setUrls(host, host, host);
  return authFn(orgId, appId, username, password)
    .then(token => initBee(config, username, token));
}

function signInAsUser(config, username, password) {
  return signIn(hiveBee.auth.user, config, username, password);
}

function signInAsBee(config, username, password) {
  return signIn(hiveBee.auth.bee, config, username, password);
}

function handleBeeError(error) {
  const status = _.get(error, 'status');
  if (status === 401 || status === 403) {
    return releaseBee();
  }
}

/**
 * @namespace ConnectionService
 */

module.exports = {
  /**
   * @constant
   * @memberof ConnectionService
   * @description An observable that can be subscribe to in order to get updates about the connection to Hive. Refer
   * to [RxJS Observable](http://reactivex.io/rxjs/class/es6/Observable.js~Observable.html) for more details.
   */
  connection$: _connection$,

  /**
   * @function
   * @memberof ConnectionService
   * @description Signs in to Hive as an application user.
   * @param {Object} config - a configuration object
   * @param {String} config.host - the base URL to the Hive instance to connect to
   * @param {String} config.orgId - the organization to connect to
   * @param {String} config.appId - the application to connect to
   * @param {Array} config.categories An array of categories and filters that the connection will subscribe to. Refer
   * to [Hive-Bee subscribe](https://docs.m21lab.com/hive-bee/bee.AuthenticatedBee.Reactions.html#.subscribe) for more
   * details.
   * @param {String} username - the application user name
   * @param {String} password - the application user password
   * @param {Promise} - a promise that resolves when the connection is established
   */
  signInAsUser,

  /**
   * @function
   * @memberof ConnectionService
   * @description Signs in to Hive as a bee user.
   *
   * @param {Object} config - a configuration object
   * @param {String} config.host - the base URL to the Hive instance to connect to
   * @param {String} config.orgId - the organization to connect to
   * @param {String} config.appId - the application to connect to
   * @param {Array} config.categories An array of categories and filters that the connection will subscribe to. Refer
   * to [Hive-Bee subscribe](https://docs.m21lab.com/hive-bee/bee.AuthenticatedBee.Reactions.html#.subscribe) for more
   * details.
   * @param {String} username - the bee user name
   * @param {String} password - the bee user password
   * @param {Promise} - a promise that resolves when the connection is established
   */
  signInAsBee,

  /**
   * @function
   * @memberof ConnectionService
   * @description Initializes a bee with a known authorization token.
   *
   * @param {Object} config - a configuration object
   * @param {String} config.host - the base URL to the Hive instance to connect to
   * @param {String} config.orgId - the organization to connect to
   * @param {String} config.appId - the application to connect to
   * @param {Array} config.categories An array of categories and filters that the connection will subscribe to
   * @param {String} username - the username associated with the token
   * @param {String} token - the authorization token to use to establish the connection
   */
  initBee,

  /**
   * @function
   * @memberof ConnectionService
   * @description Releases any active connection.
   */
  releaseBee,

  /**
   * @function
   * @member ConnectionService
   * @description Use to pass errors from API calls. The service decides if it needs to disconnect or not
   * @param {Object} - An error object, where it's status field is used to determine the need to disconnect or not.
   */
  handleBeeError,
};
