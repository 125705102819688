import {
  ChatApi,
  ChatApi_Message,
  ChatApi_ReadMarker,
  ChatApi_TypingNotification,
  ChatApi_Participant,
  ParticipantType,
} from "./chat.model";
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { chat, conversation } from "hive-sms-chat-client";
import { assertConnection } from "../../HiveService";
import { waitForInvocationReaction } from "vwroom-client-lib";
import _compact from "lodash/compact";
import { Maybe } from "../../util";

const REACTION_TIMEOUT = 30000;

const PARTICIPANT_ADMIN = {
  id: "admin",
  type: ParticipantType.Admin,
};
const PARTICIPANT_USER = {
  id: "user",
  type: ParticipantType.User,
};
const PARTICIPANT_SYSTEM = {
  id: "system",
  type: ParticipantType.System,
};

export async function openChatApi(chatId: string): Promise<ChatApi> {
  const { bee } = assertConnection();

  // The chat Id is really a person id, since we don't really have the conversationId just yet.
  chatId = await bee.actions
    .invoke("vwroom.getOrStartConversation", chatId)
    .then(
      waitForInvocationReaction(
        bee,
        (r: any) => r.details.uuid,
        REACTION_TIMEOUT
      )
    );

  return new HiveChatApiImplementation(chatId);
}

/**
 * This is a lower level event driven API.  It is intended to be consumed by the Chat Service and used to interface with the messaging back end.
 * Please do not directly access this API from anywhere except the chat service
 */
class HiveChatApiImplementation implements ChatApi {
  public onMessage: ReplaySubject<ChatApi_Message[]>;
  public onRead: ReplaySubject<ChatApi_ReadMarker[]>;
  public onTyping: Observable<ChatApi_TypingNotification[]>;
  public onParticipants: ReplaySubject<ChatApi_Participant[]>;
  private conversation$: Observable<any>;
  private subscription: Subscription;

  constructor(private chatId: string) {
    const { bee } = assertConnection();
    this.conversation$ = conversation.observe(bee, this.chatId);

    this.onMessage = new ReplaySubject<ChatApi_Message[]>();
    this.subscription = this.conversation$.subscribe({
      next: (conversation: any) => {
        const hiveMessages: any[] = conversation.messages;
        const messages: ChatApi_Message[] = hiveMessages.map((m) =>
          this.processMessage(m)
        ) as any;
        this.onMessage.next(messages);

        const readMarkers = _compact(
          hiveMessages.map((m) => this.extractReadMarker(m))
        );
        this.onRead.next(readMarkers);
      },
      error: (error: any) => {
        this.onMessage.error(error);
      },
    });

    this.onRead = new ReplaySubject();
    this.onTyping = new ReplaySubject();
    this.onParticipants = new ReplaySubject();
    this.onParticipants.next([
      PARTICIPANT_ADMIN,
      PARTICIPANT_USER,
      PARTICIPANT_SYSTEM,
    ]);
  }

  determineAuthor(message: any): string {
    const originator = message?.properties?.originator || "";
    switch (originator) {
      case "User":
        return PARTICIPANT_USER.id;

      case "Admin":
        return PARTICIPANT_ADMIN.id;

      case "System":
        return PARTICIPANT_SYSTEM.id;

      default:
        throw new Error(`Invalid message originator: ${originator}`);
    }
  }

  processMessage(message: any): Partial<ChatApi_Message> {
    return {
      id: message.id,
      authorId: this.determineAuthor(message),
      whenSent: new Date(message.properties.created),
      lastUpdated: new Date(message.properties.updated),
      message: message.properties.message,
      messageFormat: message.properties.format,
      state: message.properties.state,
      hasBeenEdited: false,
    };
  }

  extractReadMarker(message: any): Maybe<ChatApi_ReadMarker> {
    if (message.properties.read) {
      return {
        participantId: PARTICIPANT_ADMIN.id,
        messageId: message.id,
        timestamp: new Date(message.properties.updated),
      };
    }
  }

  dispose(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  sendMessage(message: Partial<ChatApi_Message>) {
    const { bee } = assertConnection();
    return bee.actions
      .invoke("vwroom.sendMessage", this.chatId, message.message)
      .then(waitForInvocationReaction(bee, () => {}, 30000));
  }

  deleteMessage(messageId: string) {
    // Nothing to do
  }

  userIsTyping(userId: string) {
    // Nothing to do
  }

  markRead(userId: string, messageId: string) {
    const { bee } = assertConnection();
    return chat.markMessageAsRead(bee, messageId);
  }
}
