import { AesGcmEncryptor as encryptor } from "./Encrypt/AesEncrypt";
import { MapFn, AsyncIdentityFn } from "../util";

/**
 * Represents a sync object/value storage
 */
export interface SyncStorage {
  /**
   * Retrieves the object stored at the specified key
   * @param key The key to fetch
   */
  getItem<T>(key: string): T | undefined;
  /**
   * Stores an object at the specified key
   * @param key The key where to store the object
   * @param value The object to be stored
   */
  setItem<T>(key: string, value: T): void;

  /**
   * Removes an object with the specified key
   * @param key the key of the object to remove
   */
  removeItem(key: string): void;
}

/**
 * Represents an async object/value storage
 */
export interface AsyncStorage {
  /**
   * Retrieves the object stored at the specified key
   * @param key The key to fetch
   */
  getItem<T>(key: string): Promise<T | undefined>;
  /**
   * Stores an object at the specified key
   * @param key The key where to store the object
   * @param value The object to be stored
   */
  setItem<T>(key: string, value: T): Promise<void>;

  /**
   * Removes an object with the specified key
   * @param key the key of the object to remove
   */
  removeItem(key: string): Promise<void>;
}

function namespace(prefix: string): MapFn<string, string> {
  return (key: string) => prefix + key;
}

function serialize<T>(value: T): string {
  return JSON.stringify({ v: value });
}

function deserialize<T>(json: string | null): T {
  return json ? JSON.parse(json).v : null;
}

async function encrypt(value: string): Promise<string> {
  return encryptor.encrypt(value);
}

async function decrypt(value: string | null): Promise<string | null> {
  if (!value) {
    return null;
  }
  return encryptor.decrypt(value);
}

function combinePrefixDelimiter(prefix: string, delimiter: string): string {
  const useDelimiter = prefix && prefix.length > 0;
  return useDelimiter ? prefix + (delimiter || "") : prefix;
}

class SyncStorageImplementation implements SyncStorage {
  private ns: MapFn<string, string>;
  constructor(
    prefix: string,
    private storage: Storage,
    delimiter: string = "."
  ) {
    this.ns = namespace(combinePrefixDelimiter(prefix, delimiter));
  }

  getItem<T>(key: string): T | undefined {
    return deserialize(this.storage.getItem(this.ns(key)));
  }

  setItem<T>(key: string, value: T) {
    this.storage.setItem(this.ns(key), serialize(value));
  }

  removeItem(key: string) {
    this.storage.removeItem(this.ns(key));
  }
}

class AsyncStorageImplementation implements AsyncStorage {
  private ns: MapFn<string, string>;
  private ec: MapFn<string, Promise<string>>;
  private dc: MapFn<string | null, Promise<string | null>>;
  constructor(
    private prefix: string,
    private storage: Storage,
    private useEncryption: boolean,
    delimiter: string = "."
  ) {
    this.ns = namespace(combinePrefixDelimiter(prefix, delimiter));
    if (this.useEncryption) {
      this.ec = encrypt;
      this.dc = decrypt;
    } else {
      this.dc = this.ec = AsyncIdentityFn;
    }
  }

  async getItem<T>(key: string): Promise<T | undefined> {
    try {
      return deserialize(await this.dc(this.storage.getItem(this.ns(key))));
    } catch (err) {}
  }

  async setItem<T>(key: string, value: T) {
    this.storage.setItem(this.ns(key), await this.ec(serialize(value)));
  }

  async removeItem(key: string) {
    this.storage.removeItem(this.ns(key));
  }
}

type allowedStorage = "localstorage" | "sessionstorage";
type useEncryption = "encrypted" | "plaintext";

export function createSyncStorageInterface(
  prefix: string = "",
  storage: allowedStorage = "localstorage"
): SyncStorage {
  return new SyncStorageImplementation(
    prefix,
    storage === "localstorage" ? localStorage : sessionStorage
  );
}

export function createAsyncStorageInterface(
  prefix: string = "",
  storage: allowedStorage = "localstorage",
  useEncryption: useEncryption = "encrypted"
): AsyncStorage {
  return new AsyncStorageImplementation(
    prefix,
    storage === "localstorage" ? localStorage : sessionStorage,
    useEncryption === "encrypted" ? true : false
  );
}
