import React from "react";
import "./ChatLog.scss";
import ChatMessage from "../ChatMessage/ChatMessage";
import ChatSystemMessage from "../ChatSystemMessage/ChatSystemMessage";
import { useMaybeObservable } from "./../../../Hooks";
import KeyedFragment from "./../../KeyedFragment";
import ScrollMagnet from "./../../ScrollMagnet/ScrollMagnet";

import {
  ChatView,
  MessageView,
  ParticipantType,
} from "./../../../Services/Chat";

function displayMessage(message: MessageView) {
  const display =
    message.author.type === ParticipantType.System ? (
      <ChatSystemMessage message={message} />
    ) : (
      <ChatMessage message={message} />
    );
  return <KeyedFragment key={message.id}>{display}</KeyedFragment>;
}

function ChatLog({ chat }: { chat: ChatView }) {
  const messages = useMaybeObservable(chat.messages);
  const messageUi = messages ? messages.map(displayMessage) : [];

  return (
    <chat-log>
      <top-spacer />
      {messageUi}
      <ScrollMagnet />
    </chat-log>
  );
}

export default ChatLog;
