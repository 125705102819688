import React from "react";
import "./LeftCenterRight.scss";

export function LeftCenterRight({
  left,
  center,
  right,
}: {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
}) {
  return (
    <lcr-columns>
      <lcr-column>
        <lcr-column-content>{left}</lcr-column-content>
      </lcr-column>
      <lcr-column>
        <lcr-column-content>{center}</lcr-column-content>
      </lcr-column>
      <lcr-column>
        <lcr-column-content>{right}</lcr-column-content>
      </lcr-column>
    </lcr-columns>
  );
}

export default LeftCenterRight;
