import React from "react";
import SinglePanelLayout from "../../../Components/Layout/SinglePanel/SinglePanel";
import { TextBlockDescription } from "../../../Shared/Components/StandardStyles";
import { LeftCenterRight } from "../../../Shared/Components";
import { Button } from "@material-ui/core";
import { useExtendedTranslation } from "../../../Shared/Services/i18nService";
import { useObservable } from "../../../Shared/Hooks";
import { advanceUserCheckInWorkflow } from "../../../Services/WorkflowService";

function ScreenWelcomeQuestion() {
  const t = useExtendedTranslation();
  const advanceIsDisabled = useObservable(
    advanceUserCheckInWorkflow.watchIsBusy()
  );
  return (
    <screen-welcome-question>
      <SinglePanelLayout
        title={t("questionnaire.welcome.title")}
        footer={
          <LeftCenterRight
            center={
              <Button
                onClick={advanceUserCheckInWorkflow}
                disabled={advanceIsDisabled}
                variant="contained"
                color="primary"
              >
                {t("questionnaire.welcome.continue")}
              </Button>
            }
          />
        }
      >
        <screen-welcome-question-description>
          <TextBlockDescription>
            {t("questionnaire.welcome.description")}
          </TextBlockDescription>
        </screen-welcome-question-description>
        <screen-welcome-question-detail>
          <TextBlockDescription>
            {t("questionnaire.welcome.detail")}
          </TextBlockDescription>
        </screen-welcome-question-detail>
      </SinglePanelLayout>
    </screen-welcome-question>
  );
}

export default ScreenWelcomeQuestion;