import _get from "lodash/get";

import enabledFlags from "../../featureFlags.json";

export enum FeatureFlags {
  Chat = "chat",
  PriorityEntrants = "priority-entrants",
  Screener = "screener",
  ManualRegistration = "manual-registration",
}

let EnabledFeatures: FeatureFlags[] =
  (_get(enabledFlags, "enabled") as FeatureFlags[]) || [];

export function isFeatureEnabled(feature: FeatureFlags) {
  return EnabledFeatures.includes(feature);
}

const isReady: Promise<void> = Promise.resolve();
export function featureFlagsReady(): Promise<void> {
  return isReady;
}
