import React from "react";
import SinglePanelLayout from "../../../Components/Layout/SinglePanel/SinglePanel";
import { useExtendedTranslation } from "../../../Shared/Services/i18nService";
import "./ScreenTimesUp.scss";
import {
  TextBlockHint,
  TextBlockDescription,
} from "../../../Shared/Components/StandardStyles";
import { Button } from "@material-ui/core";
import { rejoinQueue } from "../../../Services/WorkflowService";
import { useDeferredPromise } from "../../../Shared/Hooks";
import { AsyncQueueViewer } from "../../../Shared/Components";
import {
  isReady,
  watchQueueAtCurrentLocation,
} from "../../../Services/DataService";
import { IQueue } from "../../../Shared/Types/IClient";

function ScreenTimesUp() {
  const { promise, resolveFn, rejectFn } = useDeferredPromise<IQueue>(() => {
    isReady().then(watchQueueAtCurrentLocation).then(resolveFn).catch(rejectFn);
  });
  const t = useExtendedTranslation();

  return (
    <screen-on-my-way>
      <SinglePanelLayout
        title={<>{t("timesup.title")}</>}
        footer={
          <Button variant="contained" color="primary" onClick={rejoinQueue}>
            {t("timesup.rejoin")}
          </Button>
        }
      >
        <TextBlockDescription>{t("timesup.description")}</TextBlockDescription>
        <TextBlockHint>{t("timesup.hint")}</TextBlockHint>
        <AsyncQueueViewer
          top={t("timesup.queue.top")}
          bottom={t("timesup.queue.bottom")}
          promise={promise}
          fetchFn={(q: IQueue) => q.itemCount}
        />
      </SinglePanelLayout>
    </screen-on-my-way>
  );
}

export default ScreenTimesUp;
