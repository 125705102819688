import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { getDistance } from "vwroom-client-lib";
import { ILocation } from "./../Types/IClient";
import { deepClone } from "./../util";

export interface GpsCoordinates {
  lat: number;
  long: number;
  acc: number;
}

const LOCATION_UNKNOWN: GpsCoordinates = {
  lat: 0,
  long: 0,
  acc: Number.MAX_SAFE_INTEGER,
};

const currentGpsLocation = new BehaviorSubject<GpsCoordinates>(
  deepClone(LOCATION_UNKNOWN)
);
let didPublishGpsLocation: boolean = false;

/**
 * Beyond this an the GPS is probably not working
 */
export const MAXIMUM_ALLOWED_ACCURACY = 30000;

/**
 * Returns an Observable which will be updated when+if the browser gives an update.
 * You must call updateGpsLocationNow !!!while processing a user interaction!!! such as a click to start the process.
 */
export function watchGpsLocation(): Observable<GpsCoordinates> {
  return currentGpsLocation;
}

/**
 * The actual object from the browser API has overrides to prevent serialization so we copy the args we need manually
 * @param gpsLocationUpdate The location update from the browser
 */
function copyGeo(gpsLocationUpdate: Position): GpsCoordinates {
  return {
    lat: gpsLocationUpdate.coords.latitude,
    long: gpsLocationUpdate.coords.longitude,
    acc: gpsLocationUpdate.coords.accuracy,
  };
}

/**
 * Attempts to fetch the user's current location.
 * This function should only be called in the context of a user action such as a click.
 * Calling this function at any other time may result in the api ignoring the call.
 */
export function updateGpsLocationNow() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (l) => {
        const gpsLocation = copyGeo(l);
        if (gpsLocation.acc < MAXIMUM_ALLOWED_ACCURACY) {
          didPublishGpsLocation = true;
          currentGpsLocation.next(gpsLocation);
          console.debug("Updated users location", gpsLocation);
        } else {
          console.warn(
            "User location updated, but the accuracy wasn't good enough to use for distance checking"
          );
        }
      },
      (err) => {
        didPublishGpsLocation = true;
        currentGpsLocation.next(deepClone(LOCATION_UNKNOWN));
        console.error("gps location fetch failed", err);
      }
    );
  } else {
    console.error("Featured Not Supported or Not Authorized by User");
  }
}

export function usersGpsLocationHasBeenDetermined(): boolean {
  return didPublishGpsLocation;
}

export function GpsLocationCoordinates(location: ILocation): GpsCoordinates {
  return {
    lat: Number(location.latitude),
    long: Number(location.longitude),
    acc: 0,
  };
}

function isNullIsland(position: Partial<GpsCoordinates>) {
  return !position.lat && !position.long;
}

export function calculateDistanceInMeters(
  positionA: Partial<GpsCoordinates>,
  positionB: Partial<GpsCoordinates>
): number {
  if (isNullIsland(positionA) || isNullIsland(positionB)) {
    return 0;
  }
  const dist = getDistance(
    positionA.lat,
    positionA.long,
    positionB.lat,
    positionB.long
  );
  const acc = (positionA.acc || 0) + (positionB.acc || 0);
  return Math.max(dist, acc); //Cannot return distances lower than the combined accuracy
}
