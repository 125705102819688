import React from "react";
import "./ImageBox.scss";
import { makeStyles } from "@material-ui/core";
import memoize from "lodash.memoize";
import { memoizeFunction } from "../../util";

const APP_PREFIX =
  process.env.PUBLIC_URL +
  ((process.env.PUBLIC_URL || "").endsWith("/") ? "" : "/");

function isAnExternalUrl(path: string): boolean {
  return (path || "").toLowerCase().indexOf("://") !== -1;
}

function applyApplicationSpecificPathPrefix(path: string): string {
  return path?.startsWith(APP_PREFIX) ? path : APP_PREFIX + path;
}
function correctSharedImages(path: string): string {
  return path.replace(/^\/?shared/i, "Shared");
}

const createPseudoUrls = memoize(
  (url: string, hover?: string, disabled?: string) => {
    const styles: any = {
      backgroundImage: wrapUrl(url),
    };
    if (hover) {
      styles["&:hover"] = {
        backgroundImage: wrapUrl(hover),
      };
    }
    if (disabled) {
      styles["&[disabled]"] = {
        backgroundImage: wrapUrl(disabled),
      };
      styles["[disabled] &"] = {
        backgroundImage: wrapUrl(disabled),
      };
    }
    return makeStyles({ root: styles });
  },
  (...args: any[]) => args.join(",")
);

export function normalizeImageUrl(url: string) {
  return isAnExternalUrl(url)
    ? url
    : applyApplicationSpecificPathPrefix(correctSharedImages(url));
}

function wrapUrl(url: string) {
  return `url("${normalizeImageUrl(url)}")`;
}

function prefetchImage_NotMemoized(url?: string) {
  if (!url || url.length === 0) {
    return;
  }
  const normalizedUrl = normalizeImageUrl(url);
  const elem = document.createElement("img");
  elem.src = normalizedUrl;
}
const prefetchImage = memoizeFunction(prefetchImage_NotMemoized);

function ImageBox({
  url,
  hoverUrl,
  disabledUrl,
  id,
  className,
}: {
  url: string;
  hoverUrl?: string;
  disabledUrl?: string;
  id?: string;
  className?: string;
}) {
  prefetchImage(url);
  prefetchImage(hoverUrl);
  prefetchImage(disabledUrl);
  if (hoverUrl || disabledUrl) {
    const styleGen = createPseudoUrls(url, hoverUrl, disabledUrl);
    const combinedClass = [className, styleGen().root]
      .filter((v) => v)
      .join(" ");
    return <image-box id={id} class={combinedClass}></image-box>;
  } else {
    const bgUrl = { backgroundImage: wrapUrl(url) };
    return <image-box id={id} class={className} style={bgUrl}></image-box>;
  }
}

export default ImageBox;
