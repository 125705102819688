import React from "react";
import "./VisitorFlagIcon.scss";
import { Dict } from "../../util";
import ImageBox from "../ImageBox/ImageBox";
import { useExtendedTranslation } from "../../Services/i18nService";

export enum VisitorFlags {
  HighPriority = "high-priority",
  ManualRegistration = "manual-registration",
  UnreadMessages = "unread-messages",
  HighRiskVisitor = "high-risk",
}

const ICON_URLS: Dict<VisitorFlags, string> = {
  [VisitorFlags.HighPriority]: "shared/high-priority.svg",
  [VisitorFlags.ManualRegistration]: "shared/manual-registration.svg",
  [VisitorFlags.UnreadMessages]: "shared/unread-messages.svg",
  [VisitorFlags.HighRiskVisitor]: "shared/high-risk.svg",
};
const SMALL_ICON_URLS: Dict<VisitorFlags, string> = {
  [VisitorFlags.HighPriority]: "shared/high-priority_small.svg",
  [VisitorFlags.ManualRegistration]: "shared/manual-registration_small.svg",
  [VisitorFlags.UnreadMessages]: "shared/unread-messages_small.svg",
  [VisitorFlags.HighRiskVisitor]: "shared/high-risk_small.svg",
};
const FLAG_TITLE: Dict<VisitorFlags, string> = {
  [VisitorFlags.HighPriority]: "tooltip.has.needs",
  [VisitorFlags.HighRiskVisitor]: "tooltip.high.risk",
  [VisitorFlags.ManualRegistration]: "tooltip.has.ticket",
  [VisitorFlags.UnreadMessages]: "tooltip.has.unread.messages",
};

function VisitorFlagIcon({
  flag,
  id,
  className,
  small,
}: {
  flag: VisitorFlags;
  id?: string;
  className?: string;
  small?: boolean;
}) {
  const t = useExtendedTranslation();
  const classes = (className || "") + (small ? " small" : "");
  const url = small ? SMALL_ICON_URLS[flag] : ICON_URLS[flag];
  return (
    <visitor-flag-icon title={t(FLAG_TITLE[flag])} class={classes}>
      <ImageBox id={id} className={className} url={url} />
    </visitor-flag-icon>
  );
}

export default VisitorFlagIcon;
