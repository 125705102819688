import React from "react";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import "./DebugChat.scss";
import { ChatDisplay } from "../../Shared/Components/Chat";
import { createChatView, ParticipantType } from "../../Shared/Services/Chat";

function ScreenDebugChat() {
  const theChatPromise = createChatView("hello", ParticipantType.Admin);
  return (
    <screen-debug-chat>
      <SinglePanelLayout>
        <ChatDisplay chat={theChatPromise} />
      </SinglePanelLayout>
    </screen-debug-chat>
  );
}

export default ScreenDebugChat;
