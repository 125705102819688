import React, { useCallback, useEffect, useState } from "react";
import { useInstanceConst } from "./../../Hooks/useInstanceConst";
import { uuidv4 } from "./../../Hooks/useGuid";

import "./InQRadioButtonGroup.scss";

export interface InQRadioButtonGroupMetaData {
  groupName: string;
  onChange: (newValue: string) => void;
  selectedValue?: string;
}

export const InQRadioButtonGroupContext = React.createContext<
  InQRadioButtonGroupMetaData
>(undefined as any);

function InQRadioButtonGroup({
  id,
  className,
  label,
  value,
  groupName,
  onChange,
  children,
}: {
  id?: string;
  className?: string;
  label?: JSX.Element | string;
  groupName?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  children?: React.ReactNode;
}) {
  const actualGroupName = useInstanceConst(groupName || uuidv4());
  const [selectedValue, setSelectedValue] = useState(value);
  const [previousValue, setPreviousValue] = useState(value);

  const onChangeHandler = useCallback(
    (newValue) => {
      if (newValue === previousValue) {
        //Probably the onChange handler changed, we don't care
        return;
      }
      setPreviousValue(newValue);
      setSelectedValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange, previousValue]
  );

  useEffect(() => setSelectedValue(value), [value]);

  const metaData: InQRadioButtonGroupMetaData = {
    groupName: actualGroupName,
    onChange: onChangeHandler,
    selectedValue: selectedValue,
  };
  return (
    <inq-radiobutton-group id={id} class={className}>
      <InQRadioButtonGroupContext.Provider value={metaData}>
        {children}
      </InQRadioButtonGroupContext.Provider>
    </inq-radiobutton-group>
  );
}

export default InQRadioButtonGroup;
