import React from "react";
import SinglePanelLayout from "../../../Components/Layout/SinglePanel/SinglePanel";
import TextBlockDescription from "../../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import { useExtendedTranslation } from "../../../Shared/Services/i18nService";
import TextBlockHint from "../../../Shared/Components/StandardStyles/TextBlockHint/TextBlockHint";
import { Button } from "@material-ui/core";
import { rejoinQueue } from "../../../Services/WorkflowService";

function ScreenSomethingHappened() {
  const t = useExtendedTranslation();

  return (
    <screen-something-happened>
      <SinglePanelLayout
        title={<>{t("errorunknown.title")}</>}
        footer={
          <Button variant="contained" color="primary" onClick={rejoinQueue}>
            {t("errorunknown.rejoin")}
          </Button>
        }
      >
        <TextBlockDescription>
          {t("errorunknown.description")}
        </TextBlockDescription>
        <TextBlockHint>{t("errorunknown.hint")}</TextBlockHint>
      </SinglePanelLayout>
    </screen-something-happened>
  );
}

export default ScreenSomethingHappened;
