import React from "react";
import "./ScreenTitle.scss";

function ScreenTitle({
  id,
  children,
}: {
  id?: string;
  children?: JSX.Element;
}) {
  return <screen-title id={id}>{children}</screen-title>;
}

export default ScreenTitle;
