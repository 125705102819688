import React from "react";
import "./StyledFileInput.scss";
import { useGuid } from "../../Hooks/useGuid";
import { Button, IconButton } from "@material-ui/core";
import { useCallback } from "react";
import { useRef } from "react";

function getBlob(elem: HTMLInputElement | undefined): Blob | undefined {
  if (!elem || !elem.files || elem.files.length < 0) {
    return undefined;
  }
  return elem.files[0];
}

function checkAllowedAcceptCharacters(accept: string) {
  return /^[a-zA-Z/]+$/.test(accept);
}
function assertAllowedAcceptCharacters(accept: string) {
  if (!checkAllowedAcceptCharacters(accept)) {
    throw new Error(`Invalid characters in accept string: ${accept}.  a-zA-Z/`);
  }
}

function StyledFileInput({
  children,
  accept,
  takeNew,
  color,
  size,
  variant,
  isIcon,
  onFileSelected,
}: {
  children?: JSX.Element;
  accept: string[];
  takeNew?: boolean;
  color?: any;
  size?: any;
  variant?: any;
  isIcon?: boolean;
  onFileSelected: (file: Blob) => void;
}) {
  accept.forEach(assertAllowedAcceptCharacters);
  const id = useGuid();
  const inputRef = useRef();
  const sendFile = useCallback(() => {
    if (inputRef.current) {
      console.log("fetching blob");
      const blob = getBlob(inputRef.current);
      if (blob) {
        onFileSelected(blob);
      }
    }
  }, [onFileSelected]);

  return (
    <styled-file-input>
      <input
        ref={inputRef}
        id={id}
        type="file"
        accept={accept.join(", ")}
        capture={takeNew}
        onChange={sendFile}
      ></input>
      <label htmlFor={id}>
        {isIcon ? (
          <IconButton component="span">{children}</IconButton>
        ) : (
          <Button component="span" color={color} size={size} variant={variant}>
            {children}
          </Button>
        )}
      </label>
    </styled-file-input>
  );
}

export default StyledFileInput;
