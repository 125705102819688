import { deepFreeze } from "./util";

/**
 * These colors are mirrored in constants.scss and need to be manually kept in sync if the style guide changes
 */

export const InQStyleGuide = deepFreeze({
  blue: {
    dark: "#02253c",
    medium: "#26436e",
    light: "#92a1b7",
    bright: "#077ad3",
  },
  grey: {
    light: "#eaeaea",
    medium: "#939393",
    dark: "#393939",
  },
  orange: "#fa7000",
  red: "#c20000",
  green: "#448e1b",
  white: "#ffffff",
  black: "#000000",
});
