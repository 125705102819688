import React, { useCallback } from "react";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import "./ScreenHealthCardNeeded.scss";
import { useExtendedTranslation } from "./../../Shared/Services/i18nService";
import TextBlockDescription from "../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import { advanceUserCheckInWorkflow } from "../../Services/WorkflowService";
import { Button } from "@material-ui/core";
import TextBlockHint from "./../../Shared/Components/StandardStyles/TextBlockHint/TextBlockHint";
import ImageBox from "../../Shared/Components/ImageBox/ImageBox";
import { useObservable } from "./../../Shared/Hooks/useObservable";
import ButtonBackToLocationSelect from "../../Components/ButtonBackToLocationSelect/ButtonBackToLocationSelect";
import { LeftCenterRight } from "../../Shared/Components";
import { updateUserData } from "../../Services/DataService";

function ScreenHealthCardNeeded() {
  const advanceIsDisabled = useObservable(
    advanceUserCheckInWorkflow.watchIsBusy()
  );
  const t = useExtendedTranslation();

  const linkClick = useCallback(async () => {
    await updateUserData({ skipHealthCardCapture: true });
    advanceUserCheckInWorkflow();
  }, []);
  return (
    <screen-health-card-needed>
      <SinglePanelLayout
        title={t("healthcardneeded.title")}
        footer={
          <LeftCenterRight
            left={<ButtonBackToLocationSelect />}
            center={
              <Button
                onClick={advanceUserCheckInWorkflow}
                variant="contained"
                color="primary"
                disabled={advanceIsDisabled}
              >
                {t("healthcardneeded.continue")}
              </Button>
            }
          />
        }
      >
        <TextBlockDescription>
          {t("healthcardneeded.description")}
        </TextBlockDescription>
        <TextBlockHint>{t("healthcardneeded.hint")}</TextBlockHint>
        <ImageBox url={"CaptureHealthCard.jpg"}></ImageBox>
        <screen-health-card-needed-skip>
          <TextBlockHint>
            {t.map("healthcardneeded.skip", [
              (text) => (
                <a href="# " onClick={linkClick}>
                  {text}
                </a>
              ),
              t.plainText,
            ])}
          </TextBlockHint>
        </screen-health-card-needed-skip>
      </SinglePanelLayout>
    </screen-health-card-needed>
  );
}

export default ScreenHealthCardNeeded;
