import { useState, useCallback } from "react";
import { Dict } from "../util";

export function useDictState<K extends string | number | symbol, V>(): [
  Dict<K, V>,
  (newValues: Dict<K, V>) => void
] {
  const [data, setData] = useState<Dict<K, V>>({} as any);

  const update = useCallback(
    (newValues: Dict<K, V>) => {
      setData((current) => Object.assign({}, current, newValues));
    },
    [setData]
  );

  return [data, update];
}
