import React from "react";
import "./LanguageSelector.scss";

import {
  SupportedLanguages,
  getLanguage,
  setLanguage,
} from "../../Services/i18nService";
import ImageBox from "../ImageBox/ImageBox";
import { Button } from "@material-ui/core";

function toggleLanguage() {
  setLanguage(
    getLanguage() === SupportedLanguages.French
      ? SupportedLanguages.English
      : SupportedLanguages.French
  );
}

function LanguageSelector({
  supportedLanguages,
}: {
  supportedLanguages?: SupportedLanguages[] | undefined;
}) {
  if (supportedLanguages && supportedLanguages.length === 1) {
    return null;
  }

  const langLogo =
    getLanguage() === SupportedLanguages.French ? "french.svg" : "english.svg";

  return (
    <language-selector>
      <Button onClick={toggleLanguage}>
        <ImageBox url={process.env.PUBLIC_URL + "/" + langLogo}></ImageBox>
      </Button>
    </language-selector>
  );
}

export default LanguageSelector;
