import { Theme } from "@material-ui/core";

export enum SupportedThemes {
  Dark = "dark",
  Light = "light",
}

let LightTheme: Theme;
let DarkTheme: Theme;
export function init(light: Theme, dark: Theme) {
  LightTheme = light;
  DarkTheme = dark;
}

const THEME_KEY = "theme";
const DEFAULT_THEME = SupportedThemes.Light;

export function getTheme(): Theme {
  switch (getThemeName()) {
    case SupportedThemes.Dark:
      return DarkTheme;
    case SupportedThemes.Light:
      return LightTheme;
    default:
      throw new RangeError("Unsupported Theme");
  }
}

export function getThemeName(): SupportedThemes {
  const fromStore = localStorage.getItem(THEME_KEY) as SupportedThemes;
  if (!fromStore) {
    setThemeName(DEFAULT_THEME);
  }
  return fromStore || DEFAULT_THEME;
}

export function setThemeName(newTheme: SupportedThemes) {
  localStorage.setItem(THEME_KEY, newTheme);
  window.location.reload(false);
}
