import { useState } from "react";
import { Observable } from "rxjs";
import { DeferredPromise } from "vwroom-client-lib";
import { useInit } from "../../Shared/Hooks/useInit";

export type initFn<T> = () => void;

export function useDeferredPromise<T>(init: initFn<T> = () => {}) {
  let promise: Promise<Observable<T>>;
  let resolveFn: (p: Observable<T>) => void;
  let rejectFn: (e: Error) => void;
  [{ promise, resolveFn, rejectFn }] = useState(() => DeferredPromise());

  useInit(init);

  return { promise, resolveFn, rejectFn };
}
