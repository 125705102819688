import { useEffect, useState, useCallback } from "react";

/**
 * Maps from a value to another value.  Your mapping function should ideally not depend on any other values, however if this is
 * unavoidable make sure you specify the mapper dependencies array
 * @param value The value to be mapped
 * @param mapper A function that maps from the input value to the output value
 * @param mapperDeps Optional [] - A list of dependencies which should force the mapper function itself to be updated
 */
export function useMap<T1, T2>(
  value: T1,
  mapper: (value: T1) => T2,
  mapperDeps: any[] = []
): T2 {
  const wrappedMapper = useCallback(mapper, mapperDeps);
  const [result, setResult] = useState(() => wrappedMapper(value));
  useEffect(() => {
    setResult(wrappedMapper(value));
  }, [value, wrappedMapper]);
  return result;
}
