import React, { useRef, useEffect, useCallback, useState } from "react";

const STABALIZATION_WINDOW_IN_MS = 200;

function ScrollMagnet({ active = true }: { active?: boolean }) {
  const [actuallyActive, setActuallyActive] = useState(active);
  const [whenUpdated, setWhenUpdated] = useState(Date.now());

  const elem = useRef<any>();

  const doScroll = useCallback(() => {
    try {
      if (!elem.current || !elem.current.scrollIntoView) {
        console.debug(
          "[ScrollMagnet] Scroll Element is no longer bound to the dom, ignoring attempt to scroll"
        );
        return;
      }
      elem.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } catch (err) {
      console.error(
        "[ScrollMagnet] Error occured while trying to programatically scroll",
        err
      );
    }
  }, [elem]);

  useEffect(() => {
    if (!elem || !elem.current) {
      return;
    }
    try {
      const observer = new IntersectionObserver(
        function (entries) {
          try {
            const magnetIsFullyVisibleOnScreen = entries[0].isIntersecting;
            const now = Date.now();
            if (whenUpdated + STABALIZATION_WINDOW_IN_MS < now) {
              setActuallyActive(active && magnetIsFullyVisibleOnScreen);
              setWhenUpdated(now);
            }
          } catch (err) {
            console.error(
              "[ScrollMagnet] Error occured while responding to an intersection update",
              err
            );
          }
        },
        { threshold: [1] }
      );
      observer.observe(elem.current);

      return () => {
        observer.disconnect();
      };
    } catch (err) {
      console.error(
        "[ScrollMagnet] Error occured while registering a scroll listener",
        err
      );
    }
  }, [elem, active, whenUpdated]);

  if (actuallyActive && elem.current) {
    doScroll();
    setTimeout(doScroll, 100);
  }
  return <scroll-magnet ref={elem} />;
}

export default ScrollMagnet;
