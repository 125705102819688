import React, { useCallback } from "react";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import "./ScreenDocumentNeeded.scss";
import { useExtendedTranslation } from "./../../Shared/Services/i18nService";
import TextBlockDescription from "../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import { advanceUserCheckInWorkflow } from "../../Services/WorkflowService";
import { Button } from "@material-ui/core";
import TextBlockHint from "./../../Shared/Components/StandardStyles/TextBlockHint/TextBlockHint";
import ImageBox from "../../Shared/Components/ImageBox/ImageBox";
import { useObservable } from "./../../Shared/Hooks/useObservable";
import { LeftCenterRight } from "../../Shared/Components";
import ButtonBackToLocationSelect from "../../Components/ButtonBackToLocationSelect/ButtonBackToLocationSelect";
import { updateUserData } from "../../Services/DataService";

function ScreenDocumentNeeded() {
  const advanceIsDisabled = useObservable(
    advanceUserCheckInWorkflow.watchIsBusy()
  );
  const t = useExtendedTranslation();

  const linkClick = useCallback(async () => {
    await updateUserData({ skipDocumentCapture: true });
    advanceUserCheckInWorkflow();
  }, []);

  return (
    <screen-document-needed>
      <SinglePanelLayout
        title={t("documentneeded.title")}
        footer={
          <LeftCenterRight
            left={<ButtonBackToLocationSelect />}
            center={
              <Button
                onClick={advanceUserCheckInWorkflow}
                variant="contained"
                color="primary"
                disabled={advanceIsDisabled}
              >
                {t("documentneeded.continue")}
              </Button>
            }
          />
        }
      >
        <TextBlockDescription>
          {t("documentneeded.description")}
        </TextBlockDescription>
        <TextBlockHint>{t("documentneeded.hint")}</TextBlockHint>
        <ImageBox url={"DocumentNeeded.png"}></ImageBox>
        <screen-document-needed-skip>
          <TextBlockHint>
            {t.map("documentneeded.skip", [
              (text) => (
                <a href="# " onClick={linkClick}>
                  {text}
                </a>
              ),
              t.plainText,
            ])}
          </TextBlockHint>
        </screen-document-needed-skip>
      </SinglePanelLayout>
    </screen-document-needed>
  );
}

export default ScreenDocumentNeeded;
