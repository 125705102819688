import React from "react";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import { Button } from "@material-ui/core";
import "./ScreenItsYourTurn.scss";
import { useExtendedTranslation } from "./../../Shared/Services/i18nService";
import TextBlockDescription from "./../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import TextBlockHint from "./../../Shared/Components/StandardStyles/TextBlockHint/TextBlockHint";
import {
  watchCurrentTicket,
  markCurrentTicketAsAcknowledged,
} from "../../Services/DataService";
import moment from "moment";
import { useObservable } from "../../Shared/Hooks/useObservable";
import { createClock } from "../../Shared/observableClock";
import { useInstanceConst } from "../../Shared/Hooks/useInstanceConst";
import CountdownTimer from "../../Components/CountdownTimer/CountdownTimer";

function ScreenItsYourTurn() {
  const t = useExtendedTranslation();

  const ticket = useObservable(watchCurrentTicket());

  // Hook to a clock
  const instanceClock = useInstanceConst(() => createClock());
  useObservable(instanceClock);

  const start = moment(ticket ? ticket.nextTimeout : 0);
  const elapsed = moment().diff(start, "seconds");
  const left = ticket ? ticket.location.maxEntrantTime - elapsed : -1;

  function handleOnMyWay() {
    markCurrentTicketAsAcknowledged();
  }

  return (
    <screen-its-your-turn id="screen">
      <SinglePanelLayout
        footer={
          <Button variant="contained" onClick={handleOnMyWay}>
            {t("yourturn.onmyway.button")}
          </Button>
        }
        title={<>{t("yourturn.title")}</>}
      >
        <TextBlockDescription>{t("yourturn.description")}</TextBlockDescription>

        <screen-its-your-turn-center>
          <CountdownTimer seconds={left}> </CountdownTimer>

          <TextBlockDescription>{t("yourturn.remaining")}</TextBlockDescription>
          <TextBlockHint>{t("yourturn.pleaseComeIn")}</TextBlockHint>
        </screen-its-your-turn-center>
      </SinglePanelLayout>
    </screen-its-your-turn>
  );
}

export default ScreenItsYourTurn;
