import { DeferredPromise, Deferred } from "./../DeferredPromise";
import { useState } from "react";
import { useInit } from "./useInit";
import { Maybe } from "../util";
import { Observable } from "rxjs";

const isRegistered = Symbol("registered");
const hasEmittedOnce = Symbol("hasEmittedOnce");
const value = Symbol("value");
const error = Symbol("error");
const deferred = Symbol("deferredPromise");
const tags = Symbol("tags");

interface DecoratedObservable<T> extends Observable<T> {
  [isRegistered]: boolean;
  [hasEmittedOnce]: boolean;
  [value]: T;
  [error]: any;
  [deferred]: Deferred<T>;
  [tags]: string[];
}

function log<T>(obs: DecoratedObservable<T>, ...msg: any[]) {
  if (obs && obs[tags] && obs[tags].length > 0) {
    console.log(...msg, obs[tags]);
  }
}

function watchObservableOnce<T>(obs: DecoratedObservable<T>, tag?: string) {
  if (!obs[isRegistered]) {
    obs[isRegistered] = true;
    obs[deferred] = DeferredPromise<T>();
    const subscription = obs.subscribe({
      next(v) {
        log(obs, "useObservable very first value", v);
        obs[value] = v;
        obs[hasEmittedOnce] = true;
        obs[deferred].resolveFn(v);
      },
      error(err) {
        obs[error] = err;
        obs[hasEmittedOnce] = true;
        obs[deferred].rejectFn(err);
      },
      complete() {
        if (!obs[hasEmittedOnce]) {
          console.error("completed without emitting");
          obs[error] = new Error("Completed without emitting any values");
        }
      },
    });
    const cleanup = () => subscription.unsubscribe();
    obs[deferred].promise.then(cleanup, cleanup);
    obs[tags] = [];
  }
  if (tag && !obs[tags].includes(tag)) {
    obs[tags].push(tag);
  }
}

function useFetchCurrentValueOngoing<T>(obs: DecoratedObservable<T>): T {
  const [currentValue, setValue] = useState(obs[value]);
  useInit(() => {
    const subscription = obs.subscribe({
      next(v) {
        obs[value] = v;
        log(obs, "useObservable ongoing update", v);
        setValue(v);
      },
      error(err) {
        obs[error] = err;
        setValue(err);
      },
    });
    return () => subscription.unsubscribe();
  });
  return currentValue;
}

export function useObservable<T>(observable: Observable<T>, tag?: string): T {
  const o: DecoratedObservable<T> = (observable as any) as DecoratedObservable<
    T
  >;

  watchObservableOnce(o, tag);
  const currentValue = useFetchCurrentValueOngoing(o);

  // Any observed errors will be thrown
  if (o[error]) {
    throw o[error];
  }

  if (!o[hasEmittedOnce]) {
    throw o[deferred].promise;
  }

  return currentValue;
}

export function useMaybeObservable<T>(observable: Observable<T>): Maybe<T> {
  const o: DecoratedObservable<T> = (observable as any) as DecoratedObservable<
    T
  >;

  watchObservableOnce(o);
  const currentValue = useFetchCurrentValueOngoing(o);

  // Any observed errors will be thrown
  if (o[error]) {
    throw o[error];
  }

  if (!o[hasEmittedOnce]) {
    return;
  }

  return currentValue;
}
