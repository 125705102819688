import React, { useState, useCallback, useEffect } from "react";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import "./ScreenSummary.scss";
import { TextBlockDescription } from "../../Shared/Components/StandardStyles";
import { useExtendedTranslation } from "../../Shared/Services/i18nService";
import {
  advanceUserCheckInWorkflow,
  retakeHealthCardPhoto,
} from "../../Services/WorkflowService";
import {
  watchCurrentLocation,
  hasUploadedAPicture,
} from "../../Services/DataService";
import { Button } from "@material-ui/core";
import {
  useObservable,
  useMaybeObservable,
} from "./../../Shared/Hooks/useObservable";
import { watchUserData, updateUserData } from "./../../Services/DataService";
import {
  ImageBox,
  InQSideBySideField,
  LeftCenterRight,
  FeatureFilter,
  InQToggleSwitch,
} from "../../Shared/Components";
import {
  NumberFormatPhoneCustom,
  NumberFormatPhoneCustomTextOnly,
} from "./../../Shared/Components/NumberFormatPhoneCustom/NumberFormatPhoneCustom";
import ButtonBackToLocationSelect from "../../Components/ButtonBackToLocationSelect/ButtonBackToLocationSelect";
import { FeatureFlags } from "../../Shared/Services/featureFlags";
import { highPrioritySelfIndicate } from "../../Shared/Services/branding";

function ScreenSummary() {
  const advanceIsDisabled = useObservable(
    advanceUserCheckInWorkflow.watchIsBusy()
  );
  const location = useMaybeObservable(watchCurrentLocation());
  const data = useObservable(watchUserData());
  const t = useExtendedTranslation();
  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = useCallback(() => setEditMode(!editMode), [
    editMode,
    setEditMode,
  ]);

  const [firstName, setFirstName] = useState(data.firstName);
  const [lastName, setLastName] = useState(data.lastName);
  const [phone, setPhone] = useState(data.phone);
  const [fullName, setFullName] = useState("");
  const [isHighPriority, setIsHighPriority] = useState(!!data.isHighPriority);
  useEffect(() => setFullName(firstName + " " + lastName), [
    firstName,
    lastName,
  ]);
  const healthCardProvided = hasUploadedAPicture();
  const saveChanges = useCallback(async () => {
    await updateUserData({ firstName, lastName, phone, isHighPriority });
    setEditMode(false);
  }, [firstName, lastName, phone, isHighPriority]);
  const retakePhoto = useCallback(async () => {
    await saveChanges();
    retakeHealthCardPhoto();
  }, [saveChanges]);

  return (
    <screen-summary>
      <SinglePanelLayout
        footer={
          <LeftCenterRight
            left={<ButtonBackToLocationSelect />}
            center={
              <>
                {editMode ? (
                  <Button
                    onClick={saveChanges}
                    variant="contained"
                    color="primary"
                  >
                    {t("summary.savechanges")}
                  </Button>
                ) : (
                  <Button
                    disabled={editMode || advanceIsDisabled}
                    onClick={advanceUserCheckInWorkflow}
                    variant="contained"
                    color="primary"
                  >
                    {t("summary.continue")}
                  </Button>
                )}
              </>
            }
          />
        }
      >
        <TextBlockDescription>{t("summary.description")}</TextBlockDescription>
        {editMode ? (
          <summary-fields>
            <InQSideBySideField
              label={t("summary.firstname")}
              required
              value={firstName}
              onChange={setFirstName}
            />
            <InQSideBySideField
              label={t("summary.lastname")}
              required
              value={lastName}
              onChange={setLastName}
            />
            <InQSideBySideField
              label={t("summary.phone")}
              required
              value={phone}
              onChange={setPhone}
              InputProps={{
                inputComponent: NumberFormatPhoneCustom,
              }}
            />
            <FeatureFilter feature={FeatureFlags.PriorityEntrants}>
              <InQToggleSwitch
                checked={isHighPriority}
                label={highPrioritySelfIndicate()}
                onChange={setIsHighPriority}
              />
            </FeatureFilter>
            {(location?.healthCardRequired ||
              location?.allowDocumentsUpload) && (
              <Button
                id="retakePhoto"
                onClick={retakePhoto}
                variant="outlined"
                color="primary"
              >
                {t("summary.retakepicture")}
              </Button>
            )}
          </summary-fields>
        ) : (
          <summary-fields>
            <InQSideBySideField label={t("summary.name")}>
              {fullName}
            </InQSideBySideField>
            <InQSideBySideField label={t("summary.phone")}>
              <NumberFormatPhoneCustomTextOnly value={phone} />
            </InQSideBySideField>
            {location?.healthCardRequired || location?.allowDocumentsUpload ? (
              <InQSideBySideField
                className="healthDocument"
                label={
                  location.healthCardRequired
                    ? t("summary.healthcard")
                    : t("summary.documents")
                }
              >
                <ImageBox
                  url={healthCardProvided ? "DocsAdded.svg" : "DocsMissing.svg"}
                ></ImageBox>
              </InQSideBySideField>
            ) : null}
            <FeatureFilter feature={FeatureFlags.PriorityEntrants}>
              <InQToggleSwitch
                checked={isHighPriority}
                disabled={true}
                label={highPrioritySelfIndicate()}
              />
            </FeatureFilter>
            <edit-button>
              <Button
                onClick={toggleEditMode}
                variant="outlined"
                color="primary"
              >
                {t("summary.editdetails")}
              </Button>
            </edit-button>
          </summary-fields>
        )}
      </SinglePanelLayout>
    </screen-summary>
  );
}

export default ScreenSummary;
