import { Maybe } from "../util";
import { useState } from "react";
import { useEffect } from "react";

const isRegistered = Symbol("registered");
const isDone = Symbol("isDone");
const value = Symbol("value");
const error = Symbol("error");

interface DecoratedPromise<T> extends Promise<T> {
  [isRegistered]: boolean;
  [isDone]: boolean;
  [value]: T;
  [error]: any;
}

function register<T>(p: DecoratedPromise<T>) {
  if (!p[isRegistered]) {
    p[isRegistered] = true;
    p.then(
      (v) => {
        p[value] = v;
        p[isDone] = true;
      },
      (err) => {
        p[error] = err || "Unknown Error";
        p[isDone] = true;
      }
    );
  }
}

export function usePromise<T>(promise: Promise<T>): T {
  const p: DecoratedPromise<T> = (promise as any) as DecoratedPromise<T>;
  register(p);

  if (p[error]) {
    throw p[error];
  }
  if (p[isDone]) {
    return p[value];
  }

  throw p;
}

export function useMaybePromise<T>(
  promise: Maybe<Promise<T>>,
  silentErrors?: boolean
): Maybe<T> {
  const p: DecoratedPromise<T> = (promise as any) as DecoratedPromise<T>;
  if (p) {
    register(p);
  }

  const [, setHadError] = useState(false);
  const [, setResult] = useState();

  useEffect(() => {
    if (!p) {
      return;
    }
    p.then(setResult, () => setHadError(true));
  }, [p]);

  if (p && p[error]) {
    if (silentErrors) {
      return;
    }
    throw p[error];
  }
  if (p && p[isDone]) {
    return p[value];
  }

  return;
}
