import React from "react";
import "./SinglePanel.scss";
import LanguageSelector from "../../../Shared/Components/LanguageSelector/LanguageSelector";
import ImageBox from "../../../Shared/Components/ImageBox/ImageBox";
import FrostedGlassPanel from "../../../Shared/Components/FrostedGlassPanel/FrostedGlassPanel";
import { logoImage } from "../../../Shared/Services/branding";
import ScreenTitle from "../../../Shared/Components/StandardStyles/ScreenTitle/ScreenTitle";
import { useMaybeObservable } from "../../../Shared/Hooks";
import { watchCurrentLocation } from "../../../Services/DataService";

function SinglePanelLayout({
  children,
  title,
  footer,
  buttons,
}: {
  children?: JSX.Element | JSX.Element[];
  title?: JSX.Element | string;
  footer?: JSX.Element;
  buttons?: JSX.Element;
}) {
  const location = useMaybeObservable(watchCurrentLocation());
  return (
    <single-panel-layout>
      <panel-header>
        <ImageBox id="logo" url={logoImage()}></ImageBox>
        <user-preferences>
          <LanguageSelector supportedLanguages={location?.supportedLanguages} />
          <ImageBox
            id="inQLogo"
            url={process.env.PUBLIC_URL + "/Qicon favicon.svg"}
          />
        </user-preferences>
      </panel-header>
      <FrostedGlassPanel>
        <panel-title>
          <ScreenTitle>{title}</ScreenTitle>
        </panel-title>
        {children}
        <panel-footer>{footer}</panel-footer>
      </FrostedGlassPanel>
    </single-panel-layout>
  );
}

export default SinglePanelLayout;
