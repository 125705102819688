import {
  watchCurrentTicket,
  watchUserData,
  isReady,
  updateUserData,
  checkInCurrentUser,
  getCurrentLocation,
  watchCurrentLocation,
  updateUserPicture,
  revokeCurrentTicket,
} from "../Services/DataService";
import { DeferredPromise } from "../Shared/DeferredPromise";
import { isQueueOpen as checkIsQueueOpen } from "../Shared/hoursUtils";
import {
  FeatureFlags,
  isFeatureEnabled,
} from "../Shared/Services/featureFlags";
import { QueueItemState } from "../Shared/Types/QueueItemState";
import { IQueueItem, ILocation } from "../Shared/Types/IClient";
import { IUserData, hasUploadedAPicture } from "./DataService";
import {
  createObservableDebouncedFunction,
  createMutex,
} from "./../Shared/util";
import { AwaitableFlag } from "./../Shared/AwaitableFlag";
import {
  updateGpsLocationNow,
  watchGpsLocation,
  GpsCoordinates,
  calculateDistanceInMeters,
  GpsLocationCoordinates,
} from "../Shared/Services/locationService";
import { featureFlagsReady } from "../Shared/Services/featureFlags";
import { watchForFatalErrors } from "../Shared/Services/FatalErrorService";
import { watchForInternetConnectionLost } from "../Shared/Services/InternetConnectionService";

enum AllScreens {
  Debug = "/debug",
  Welcome = "/welcome",
  CheckIn = "/checkin",
  Summary = "/summary",
  WaitingInQueue = "/waiting-in-queue",
  ItsYourTurn = "/its-your-turn",
  OnMyWay = "/on-my-way",
  TimesUp = "/timesup",
  Removed = "/removed",
  SomethingHappened = "/errorunknown",
  FatalError = "/fatal-error",
  NoInternet = "/no-internet",
  Closing = "/closing",
  OutOfRange = "/out-of-range",
  HealthCardNeeded = "/health-card-needed",
  HealthCardCapture = "/health-card-capture",
  PleaseWait = "/please-wait",
  DocumentNeeded = "/document-needed",
  WhatsNearby = "/whats-nearby/list-view",
  Questionnaire = "/questionnaire",
  Declaration = "/questionnaire-declaration",
  QuestionnaireWelcome = "/questionnaire-welcome",
  ExitQuestionnaire = "/questionnaire-exit",
}

/**
 * Todo fetch this from the location data
 */
function isHealthCardRequired() {
  const location = getCurrentLocation();
  return location ? location.healthCardRequired : false;
}

function isDocumentRequired() {
  const location = getCurrentLocation();
  return location ? location.allowDocumentsUpload : false;
}

function isQueueOpen() {
  const location = getCurrentLocation();
  return checkIsQueueOpen(location);
}

function questionnaireAvailable() {
  const location = getCurrentLocation();
  return isFeatureEnabled(FeatureFlags.Screener) && location
    ? location.questionnaire && location.questionnaire.length > 0
    : false;
}

let mostRecentHistoryInterface: any;
const DataServiceIsReady = isReady();
const ReactIsReady = DeferredPromise<boolean>();
let workflowServiceIsReady = false;
let workflowServiceMutex = createMutex();
let aFatalErrorHasOccured = false;
let internetConnectionLost = false;

const ticketLoadedOrTimedOut = new AwaitableFlag();
const locationLoadedOrTimedOut = new AwaitableFlag();
const userDataLoadedOrTimedOut = new AwaitableFlag();
const INIT_TIMEOUT_MS = 1.5 * 1000;

const DEBUG_NAVIGATION = false;

export function setHistoryInterface(historyInterface: any) {
  mostRecentHistoryInterface = historyInterface;
  ReactIsReady.resolveFn(true);
}

let currentUserData: IUserData;
let currentTicket: IQueueItem | undefined;
let pageTheUserIsActuallyOn: AllScreens;
let userIsInRangeForThisLocation: boolean = true;
let selectedLocation: ILocation;
let venuePosition: GpsCoordinates | undefined;
let usersPosition: GpsCoordinates | undefined;

/**
 * Queues a navigation event
 * @param path The path the user should be redirected to
 */
function navTo(path: AllScreens) {
  console.debug("navigate", path);
  setImmediate(() =>
    mostRecentHistoryInterface.push(DEBUG_NAVIGATION ? AllScreens.Debug : path)
  );
  pageTheUserIsActuallyOn = path;
}

function determinePageTheUserShouldBeOnRightNow(): AllScreens {
  if (aFatalErrorHasOccured) {
    return AllScreens.FatalError;
  }
  if (internetConnectionLost) {
    return AllScreens.NoInternet;
  }
  if (currentTicket) {
    switch (currentTicket.state) {
      case QueueItemState.PENDING:
        return AllScreens.WaitingInQueue;
      case QueueItemState.CALLED:
        return AllScreens.ItsYourTurn;
      case QueueItemState.ACKNOWLEDGED:
        return AllScreens.OnMyWay;
      case QueueItemState.EXPIRED:
        return AllScreens.TimesUp;
      case QueueItemState.REMOVED:
        return AllScreens.Removed;
      case QueueItemState.REVOKED:
      case QueueItemState.ARRIVED:
        return AllScreens.Welcome;
    }
  } else {
    if (!currentUserData.locationId) {
      return AllScreens.WhatsNearby;
    } else if (!userIsInRangeForThisLocation) {
      if (
        [AllScreens.Welcome, AllScreens.OutOfRange].includes(
          pageTheUserIsActuallyOn
        )
      ) {
        return pageTheUserIsActuallyOn;
      } else {
        return AllScreens.OutOfRange;
      }
    } else if (!isQueueOpen()) {
      return AllScreens.Closing;
    } else if (
      [
        AllScreens.CheckIn,
        AllScreens.HealthCardCapture,
        AllScreens.HealthCardNeeded,
        AllScreens.Summary,
        AllScreens.PleaseWait,
        AllScreens.DocumentNeeded,
        AllScreens.Questionnaire,
        AllScreens.Declaration,
        AllScreens.QuestionnaireWelcome,
        AllScreens.ExitQuestionnaire,
      ].includes(pageTheUserIsActuallyOn)
    ) {
      return pageTheUserIsActuallyOn;
    } else {
      return AllScreens.Welcome;
    }
  }
}

/**
 * Check if the current ticket is invalid
 */
function ticketIsInvalid() {
  return (
    (currentTicket &&
      [QueueItemState.REVOKED, QueueItemState.ARRIVED].includes(
        currentTicket.state
      )) ||
    !currentUserData.ticketId
  );
}

/**
 * Resets the applications state so that the user will get bumped back to the welcome screen
 */
async function resetApplicationState() {
  currentTicket = undefined;
  await updateUserData({ ticketId: "" });
}

/**
 * Detectmines if user should be on a different page and update navigation if needed
 */
function updateNavigation() {
  const pageTheUserShouldBeOnRightNow = determinePageTheUserShouldBeOnRightNow();

  if (pageTheUserShouldBeOnRightNow !== pageTheUserIsActuallyOn) {
    navTo(pageTheUserShouldBeOnRightNow);
    console.debug("App will navigate to ", pageTheUserShouldBeOnRightNow);
  } else {
    if (DEBUG_NAVIGATION) {
      console.debug("App does not need to navigate");
    }
  }
}

/**
 * Given the current state, perform any workflow actions required
 */
function evaluateCurrentState() {
  workflowServiceMutex.run(() => {
    if (!workflowServiceIsReady) {
      return;
    }
    if (ticketIsInvalid()) {
      resetApplicationState();
    }
    updateNavigation();
  });
}

function evaluateInRange() {
  if (
    !usersPosition ||
    !venuePosition ||
    !selectedLocation ||
    !selectedLocation.checkinDistance
  ) {
    console.debug("insufficient location data for rule checking");
    userIsInRangeForThisLocation = true;
    evaluateCurrentState();
  } else {
    const distance = calculateDistanceInMeters(usersPosition, venuePosition);
    userIsInRangeForThisLocation = distance <= selectedLocation.checkinDistance;
    evaluateCurrentState();
    console.debug(
      "users position recalculated: isInRange",
      userIsInRangeForThisLocation
    );
  }
}

/**
 * Binds to the observables and trigger checks when state changes
 */
function watchForWorkflowEvents() {
  const ticketObservable = watchCurrentTicket();
  ticketObservable.subscribe((ticket) => {
    console.debug("Workflow: Ticket updated", ticket);
    currentTicket = ticket;
    ticketLoadedOrTimedOut.setFlag();
    evaluateCurrentState();
  });
  watchUserData().subscribe((userData) => {
    if (DEBUG_NAVIGATION) {
      console.debug("Workflow: Userdata updated", userData);
    }
    currentUserData = userData;
    userDataLoadedOrTimedOut.setFlag();
    evaluateCurrentState();
  });
  watchCurrentLocation().subscribe((location) => {
    selectedLocation = location;
    venuePosition = location ? GpsLocationCoordinates(location) : undefined;
    locationLoadedOrTimedOut.setFlag();
    evaluateInRange();
  });
  watchGpsLocation().subscribe(gpsLocationUpdated);
  watchForFatalErrors().subscribe(() => {
    aFatalErrorHasOccured = true;
    evaluateCurrentState();
  });
  watchForInternetConnectionLost().subscribe(() => {
    internetConnectionLost = true;
    evaluateCurrentState();
  });
}

export const advanceUserCheckInWorkflow = createObservableDebouncedFunction(
  async () => {
    await workflowServiceMutex.run(async () => {
      updateGpsLocationNow();

      const needHealthCard = isHealthCardRequired();
      const needDocument = isDocumentRequired();

      if (!currentUserData) {
        console.error(
          "advanceUserCheckInWorkflow invoked before the user data was loaded"
        );
        return;
      }
      if (!isQueueOpen()) {
        navTo(AllScreens.Closing);
      } else if (
        isEmpty(currentUserData.firstName) ||
        isEmpty(currentUserData.lastName) ||
        isEmpty(currentUserData.phone)
      ) {
        navTo(AllScreens.CheckIn);
      } else if (
        questionnaireAvailable() &&
        !currentUserData.completedQuestionnaire
      ) {
        if (pageTheUserIsActuallyOn === AllScreens.QuestionnaireWelcome) {
          navTo(AllScreens.Questionnaire);
        } else {
          navTo(AllScreens.QuestionnaireWelcome);
        }
      } else if (pageTheUserIsActuallyOn === AllScreens.Questionnaire) {
        navTo(AllScreens.Declaration);
      } else if (pageTheUserIsActuallyOn === AllScreens.Declaration) {
        navTo(AllScreens.ExitQuestionnaire);
      } else if (
        (pageTheUserIsActuallyOn === AllScreens.HealthCardNeeded &&
          !currentUserData.skipHealthCardCapture) ||
        (pageTheUserIsActuallyOn === AllScreens.DocumentNeeded &&
          !currentUserData.skipDocumentCapture)
      ) {
        navTo(AllScreens.HealthCardCapture);
      } else if (pageTheUserIsActuallyOn === AllScreens.HealthCardCapture) {
        navTo(AllScreens.Summary);
      } else if (
        needHealthCard &&
        !hasUploadedAPicture() &&
        !currentUserData.skipHealthCardCapture
      ) {
        navTo(AllScreens.HealthCardNeeded);
      } else if (
        needDocument &&
        !hasUploadedAPicture() &&
        !currentUserData.skipDocumentCapture
      ) {
        navTo(AllScreens.DocumentNeeded);
      } else if (pageTheUserIsActuallyOn === AllScreens.Summary) {
        navTo(AllScreens.PleaseWait);
        await checkInCurrentUser();
      } else {
        navTo(AllScreens.Summary);
      }
    });
  }
);

export async function retakeHealthCardPhoto() {
  console.debug("User intends to retake their health card photo");
  await updateUserPicture({ healthCard: undefined });
  navTo(AllScreens.HealthCardCapture);
}

/**
 * Discards the current ticket and forces the user back to the summary screen
 */
export async function rejoinQueue() {
  console.debug("User intends to rejoin the queue");
  currentTicket = undefined;
  updateGpsLocationNow();
  await updateUserData({ ticketId: undefined });
  navTo(AllScreens.Summary);
}

/**
 * Discards the current ticket and forces the user back to the welcome screen
 */
export async function restartWorkflow() {
  console.debug("User intends to restart the workflow");
  updateGpsLocationNow();
  if (currentTicket) {
    revokeCurrentTicket().catch((error) => {
      console.error(error);
      // TODO: handle error
    });
  }
  navTo(AllScreens.Welcome);
}

export async function beginUserCheckIn() {
  updateGpsLocationNow();
  if (isQueueOpen()) {
    navTo(AllScreens.CheckIn);
  } else {
    navTo(AllScreens.Closing);
  }
}

export async function selectNewLocation() {
  await updateUserData({
    locationId: undefined,
    completedQuestionnaire: false,
    userAnswers: {},
  });
  navTo(AllScreens.WhatsNearby);
}

function isEmpty(text: string | undefined) {
  return !text || text.length === 0;
}

function gpsLocationUpdated(newUsersPosition: GpsCoordinates) {
  usersPosition = newUsersPosition;
  evaluateInRange();
}

async function init() {
  await ReactIsReady;
  await DataServiceIsReady;
  await featureFlagsReady();
  watchForWorkflowEvents();
  setTimeout(ticketLoadedOrTimedOut.setFlag, INIT_TIMEOUT_MS);
  setTimeout(locationLoadedOrTimedOut.setFlag, INIT_TIMEOUT_MS);
  setTimeout(userDataLoadedOrTimedOut.setFlag, INIT_TIMEOUT_MS);
  await locationLoadedOrTimedOut.untilFlagIsSet();
  await userDataLoadedOrTimedOut.untilFlagIsSet();
  if (currentUserData.ticketId) {
    await ticketLoadedOrTimedOut.untilFlagIsSet();
  }
  workflowServiceIsReady = true;
  setImmediate(updateNavigation);
}
init();
