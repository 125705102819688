import React, { useState, useCallback, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Button } from "@material-ui/core";

import SinglePanelLayout from "../../../Components/Layout/SinglePanel/SinglePanel";
import {
  updateScreenerDeclarationBasedOnQuestionaireAnswers,
  updateUserData,
  getCurrentLocation,
} from "../../../Services/DataService";
import { advanceUserCheckInWorkflow } from "../../../Services/WorkflowService";
import { InQCheckBox, LeftCenterRight } from "../../../Shared/Components";
import { TextBlockDescription } from "../../../Shared/Components/StandardStyles";
import {
  getLanguage,
  useExtendedTranslation,
} from "../../../Shared/Services/i18nService";

import "./ScreenQuestion.scss";

function ScreenQuestion() {
  const location = getCurrentLocation();
  const t = useExtendedTranslation();
  const questions = location?.questionnaire;
  const [questionId, setQuestionId] = useState(
    questions ? questions[0].questionId : ""
  );
  const [curQuestion, setCurQuestion] = useState(
    questions ? questions[0] : undefined
  );
  const [userAnswers, setUserAnswer] = useState({});
  useEffect(() => {
    setQuestionId(questionId);
    setCurQuestion(questions?.find((v) => v.questionId === questionId));
  }, [questionId, questions]);

  const [answerChecks, setAnswerChecks] = useState([] as boolean[]);
  const handleAnswerCheck = useCallback(
    (index: number, isChecked: boolean) => {
      answerChecks[index] = isChecked;
      setAnswerChecks(answerChecks);
    },
    [answerChecks]
  );

  const processCurrentAnswer = useCallback(
    async (answerYes: boolean, currentAnswer: number | string[]) => {
      if (!curQuestion) {
        return;
      }

      if (
        (answerYes && curQuestion.nextQuestion.yes === "") ||
        (!answerYes && curQuestion.nextQuestion.no === "")
      ) {
        await updateUserData({
          userAnswers: { ...userAnswers, [questionId]: currentAnswer },
          completedQuestionnaire: true,
        });
        await updateScreenerDeclarationBasedOnQuestionaireAnswers();
        advanceUserCheckInWorkflow();
      } else {
        await updateUserData({
          userAnswers: { ...userAnswers, [questionId]: currentAnswer },
        });
        setQuestionId(
          answerYes ? curQuestion.nextQuestion.yes : curQuestion.nextQuestion.no
        );
        setAnswerChecks([]);
        setUserAnswer({ ...userAnswers, [questionId]: currentAnswer });
      }
    },
    [curQuestion, userAnswers, questionId]
  );

  const yesPressed = useCallback(async () => {
    if (!curQuestion) {
      return;
    }
    await processCurrentAnswer(true, curQuestion.outcome.yes);
  }, [curQuestion, processCurrentAnswer]);

  const noPressed = useCallback(async () => {
    if (!curQuestion) {
      return;
    }
    await processCurrentAnswer(false, curQuestion.outcome.no);
  }, [curQuestion, processCurrentAnswer]);

  const continuePressed = useCallback(async () => {
    if (!curQuestion) {
      return;
    }

    const size = curQuestion.questionDetail[getLanguage()].length;
    let answers = [] as string[];
    let hasYesAnswer = false;
    answerChecks.forEach((check, i) => {
      if (check) {
        if (!hasYesAnswer) {
          hasYesAnswer = true;
          answers = Array(size).fill(curQuestion.outcome.no.toString());
        }
        answers[i] = curQuestion.outcome.yes.toString();
      }
    });

    await processCurrentAnswer(hasYesAnswer, answers);
  }, [curQuestion, processCurrentAnswer, answerChecks]);

  return (
    <screen-question>
      <SinglePanelLayout
        title={curQuestion?.questionTitle[getLanguage()]}
        footer={
          <LeftCenterRight
            center={
              curQuestion?.questionType === "yesNo" ? (
                <screen-question-yes-no>
                  <Button
                    className="yesNo"
                    onClick={yesPressed}
                    variant="contained"
                    color="primary"
                  >
                    {t("questionnaire.yes.button")}
                  </Button>
                  <Button
                    className="yesNo"
                    onClick={noPressed}
                    variant="contained"
                    color="primary"
                  >
                    {t("questionnaire.no.button")}
                  </Button>
                </screen-question-yes-no>
              ) : (
                <Button
                  onClick={continuePressed}
                  variant="contained"
                  color="primary"
                >
                  {t("questionnaire.continue.button")}
                </Button>
              )
            }
          />
        }
      >
        <screen-question-description>
          <TextBlockDescription>
            {ReactHtmlParser(curQuestion?.questionText[getLanguage()] || "")}
          </TextBlockDescription>
        </screen-question-description>
        <screen-question-details>
          {curQuestion?.questionDetail[getLanguage()].map((qd, i) => (
            <screen-question-detail key={i}>
              {curQuestion?.questionType === "yesNo" ? (
                <TextBlockDescription>
                  {ReactHtmlParser(qd)}
                </TextBlockDescription>
              ) : (
                <InQCheckBox
                  label={
                    <TextBlockDescription>
                      {ReactHtmlParser(qd)}
                    </TextBlockDescription>
                  }
                  checked={answerChecks[i]}
                  onChange={(isChecked: boolean) =>
                    handleAnswerCheck(i, isChecked)
                  }
                />
              )}
            </screen-question-detail>
          ))}
        </screen-question-details>
      </SinglePanelLayout>
    </screen-question>
  );
}

export default ScreenQuestion;
