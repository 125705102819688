import React from "react";
import "./App.scss";
import { MemoryRouter as Router, Switch, Route } from "react-router-dom";
import ScreenWhatsNearby from "../Screens/WhatsNearby/ScreenWhatsNearby";
import ScreenWelcome from "../Screens/Welcome/ScreenWelcome";
import WorkflowRoute from "../Screens/WorkflowRoute/WorkflowRoute";
import ScreenCheckIn from "../Screens/CheckIn/ScreenCheckIn";
import ScreenHealthCardCapture from "../Screens/HealthCardCapture/ScreenHealthCardCapture";
import ScreenSummary from "./../Screens/Summary/ScreenSummary";
import ScreenHealthCardNeeded from "./../Screens/HealthCardNeeded/ScreenHealthCardNeeded";
import ScreenDocumentNeeded from "./../Screens/DocumentNeeded/ScreenDocumentNeeded";
import ScreenItsYourTurn from "../Screens/ItsYourTurn/ScreenItsYourTurn";
import ScreenWaitingInQueue from "../Screens/WaitingInQueue/ScreenWaitingInQueue";
import ScreenOnMyWay from "../Screens/OnMyWay/ScreenOnMyWay";
import ScreenConfirmLeaveQueue from "../Screens/ConfirmLeaveQueue/ScreenConfirmLeaveQueue";
import ScreenTimesUp from "../Screens/Errors/TimesUp/ScreenTimesUp";
import { useExtendedTranslation } from "../Shared/Services/i18nService";
import useDocumentTitle from "../Shared/Hooks/useDocumentTitle";
import ScreenDebugNav from "../Screens/DebugNav/DebugNav";
import ScreenClosing from "../Screens/Errors/Closing/Closing";
import ScreenRemoved from "../Screens/Errors/Removed/Removed";
import ScreenSomethingHappened from "../Screens/Errors/SomethingHappened/SomethingHappened";
import ScreenPleaseWait from "./../Screens/PleaseWait/ScreenPleaseWait";
import ScreenOutOfRange from "./../Screens/Errors/OutOfRange/ScreenOutOfRange";
import ScreenFatalError from "../Screens/Errors/FatalError/ScreenFatalError";
import ScreenDebugChat from "./../Screens/DebugChat/DebugChat";
import ScreenWelcomeQuestion from "./../Screens/Questionnaire/WelcomeQuestion/ScreenWelcomeQuestion";
import ScreenQuestion from "./../Screens/Questionnaire/Question/ScreenQuestion";
import ScreenDeclaration from "../Screens/Questionnaire/Declaration/ScreenDeclaration";
import ScreenExitQuestion from "../Screens/Questionnaire/ExitQuestionnaire/ScreenExitQuestion";
import "./../Shared/Services/max-uptime";
import { BuildNumber } from "../Shared/Components";
import ScreenNoInternet from "../Screens/Errors/NoInternet/ScreenNoInternet";
import { watchForInternetConnectionRestored } from "../Shared/Services/InternetConnectionService";

// Check whether the app is online or not. If we ever come back online,
// we need to reload in order to reestablish the connection.
watchForInternetConnectionRestored().subscribe(() => window.location.reload());

function App() {
  const t = useExtendedTranslation();
  useDocumentTitle(t("VWR"));

  // Trick, to make sure we have the correct VH (viewport) on mobile phone when they show header and footer bar.
  // Reference: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  return (
    <Router>
      <macadamian-vwr>
        <BuildNumber />
        <Switch>
          <Route path="/welcome">
            <ScreenWelcome></ScreenWelcome>
          </Route>
          <Route path="/checkin">
            <ScreenCheckIn></ScreenCheckIn>
          </Route>
          <Route path="/waiting-in-queue">
            <ScreenWaitingInQueue></ScreenWaitingInQueue>
          </Route>
          <Route path="/its-your-turn">
            <ScreenItsYourTurn></ScreenItsYourTurn>
          </Route>
          <Route path="/health-card-needed">
            <ScreenHealthCardNeeded></ScreenHealthCardNeeded>
          </Route>
          <Route path="/health-card-capture">
            <ScreenHealthCardCapture></ScreenHealthCardCapture>
          </Route>
          <Route path="/document-needed">
            <ScreenDocumentNeeded></ScreenDocumentNeeded>
          </Route>
          <Route path="/on-my-way">
            <ScreenOnMyWay></ScreenOnMyWay>
          </Route>
          <Route path="/whats-nearby/list-view">
            <ScreenWhatsNearby></ScreenWhatsNearby>
          </Route>
          <Route path="/summary">
            <ScreenSummary></ScreenSummary>
          </Route>
          <Route path="/confirm-leave-queue">
            <ScreenConfirmLeaveQueue></ScreenConfirmLeaveQueue>
          </Route>
          <Route path="/questionnaire-welcome">
            <ScreenWelcomeQuestion></ScreenWelcomeQuestion>
          </Route>
          <Route path="/questionnaire">
            <ScreenQuestion></ScreenQuestion>
          </Route>
          <Route path="/questionnaire-exit">
            <ScreenExitQuestion></ScreenExitQuestion>
          </Route>
          <Route path="/questionnaire-declaration">
            <ScreenDeclaration></ScreenDeclaration>
          </Route>
          <Route path="/timesup">
            <ScreenTimesUp />
          </Route>
          <Route path="/closing">
            <ScreenClosing />
          </Route>
          <Route path="/out-of-range">
            <ScreenOutOfRange />
          </Route>
          <Route path="/removed">
            <ScreenRemoved />
          </Route>
          <Route path="/error-unknown">
            <ScreenSomethingHappened />
          </Route>
          <Route path="/fatal-error">
            <ScreenFatalError />
          </Route>
          <Route path="/no-internet">
            <ScreenNoInternet />
          </Route>
          <Route path="/please-wait">
            <ScreenPleaseWait />
          </Route>
          <Route path="/debug-chat">
            <ScreenDebugChat />
          </Route>
          <Route path="/debug">
            <ScreenDebugNav />
          </Route>
          <Route path="/">
            <WorkflowRoute></WorkflowRoute>
          </Route>
        </Switch>
      </macadamian-vwr>
    </Router>
  );
}

export default App;
