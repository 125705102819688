import React, { Suspense, useRef, useCallback, useEffect } from "react";
import "./InputCamera.scss";
import ErrorBoundary from "./../ErrorBoundary/ErrorBoundary";
import { Spinner } from "./../Spinner/Spinner";
import { useMap, useDestroy, useAsyncEffect, usePromise } from "./../../Hooks";
import { Observable } from "rxjs";
import CameraWrapperSingleton from "./cameraWrapper";

function InputCameraInternal(props: InputCameraProps) {
  const devices = usePromise(CameraWrapperSingleton.listOfAllCameras());

  const selectedDevice = useMap(
    props.selectedCameraId,
    (i) => devices.find((d) => d.deviceId === i) || devices[0]
  );

  const videoRef: any = useRef();

  const takeSnapshot = useCallback(async () => {
    const s = await CameraWrapperSingleton.capture(videoRef.current);
    props.onImageCaptured(s);
  }, [videoRef, props]);

  useEffect(() => {
    const sub = props.captureImage.subscribe(takeSnapshot);
    return () => sub.unsubscribe();
  }, [props.captureImage, takeSnapshot]);

  useAsyncEffect(async () => {
    if (videoRef.current) {
      const stream = await CameraWrapperSingleton.switchCamera(selectedDevice);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      } else {
        CameraWrapperSingleton.closeCamera();
      }
    }
  }, [videoRef, selectedDevice]);

  useDestroy(() => {
    CameraWrapperSingleton.closeCamera();
  });

  return (
    <>
      <video ref={videoRef} autoPlay playsInline></video>
    </>
  );
}

interface InputCameraProps {
  onImageCaptured: (blob: Blob) => void;
  selectedCameraId?: string;
  captureImage: Observable<any>;
}

function InputCamera(props: InputCameraProps) {
  const onError = () => <>Couldn't access your camera</>;
  return (
    <input-camera>
      <ErrorBoundary fallback={onError}>
        <Suspense fallback={<Spinner />}>
          <InputCameraInternal
            captureImage={props.captureImage}
            onImageCaptured={props.onImageCaptured}
            selectedCameraId={props.selectedCameraId}
          ></InputCameraInternal>
        </Suspense>
      </ErrorBoundary>
    </input-camera>
  );
}

export default InputCamera;
