import React from "react";
import { Button } from "@material-ui/core";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import TextBlockDescription from "./../../Shared/Components/StandardStyles/TextBlockDescription/TextBlockDescription";
import { useExtendedTranslation } from "./../../Shared/Services/i18nService";
import { useRedirect } from "../../Shared/Hooks/useRedirect";
import { appName } from "./../../Shared/Services/branding";
import "./ScreenConfirmLeaveQueue.scss";
import { revokeCurrentTicket } from "../../Services/DataService";

function ScreenConfirmLeaveQueue() {
  const t = useExtendedTranslation();

  const { setRedirectTo, redirectComponent } = useRedirect();
  if (redirectComponent) {
    return redirectComponent;
  }

  function handleContinue() {
    revokeCurrentTicket().catch((error) => {
      console.error(error);
      // TODO: handle error
    });
    setRedirectTo("/");
  }

  function handleCancel() {
    setRedirectTo("/waiting-in-queue");
  }

  return (
    <screen-confirm-leave-queue>
      <SinglePanelLayout
        footer={
          <footer-buttons>
            <Button variant="outlined" color="primary" onClick={handleContinue}>
              {t("leavequeue.continue.button")}
            </Button>
            <Button variant="contained" color="primary" onClick={handleCancel}>
              {t("leavequeue.cancel.button")}
            </Button>
          </footer-buttons>
        }
        title={<>{t("leavequeue.title")}</>}
      >
        <TextBlockDescription>
          {t("leavequeue.description1").replace("{name}", appName())}
        </TextBlockDescription>
        <TextBlockDescription>
          {t("leavequeue.description2").replace("{name}", appName())}
        </TextBlockDescription>
      </SinglePanelLayout>
    </screen-confirm-leave-queue>
  );
}

export default ScreenConfirmLeaveQueue;
