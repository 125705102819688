import React, { useState, useEffect, useCallback } from "react";
import { SketchPicker, ColorResult } from "react-color";
import "./InQColorPicker.scss";
import { MapFn } from "../../util";

const REG_EXP = new RegExp("^#(?:[0-9a-fA-F]{3}){1,2}$");

function InQColorPicker({
  value = "#000000",
  onChange,
}: {
  value?: string;
  onChange: MapFn<string, void>;
}) {
  if (!REG_EXP.test(value)) {
    console.error("Error, invalid hex value, setting value to #000000", value);
    value = "#000000";
  }

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const [localColorState, setLocalColorState] = useState(value);

  const handleClick = useCallback(() => {
    setDisplayColorPicker(!displayColorPicker);
  }, [displayColorPicker]);

  const handleClose = useCallback(() => {
    setDisplayColorPicker(false);
  }, []);

  const handleChange = useCallback((newColor: ColorResult) => {
    setLocalColorState(newColor.hex);
  }, []);

  useEffect(() => {
    onChange(localColorState);
  }, [localColorState, onChange]);

  useEffect(() => {
    setLocalColorState(value);
  }, [value]);

  return (
    <inq-color-picker>
      <inq-color-text>{localColorState.toUpperCase()}</inq-color-text>
      <inq-color-select>
        <inq-color-box
          onClick={handleClick}
          style={{ backgroundColor: localColorState }}
        />
        {displayColorPicker ? (
          <inq-color-popover>
            <inq-color-cover onClick={handleClose} />
            <SketchPicker color={localColorState} onChange={handleChange} />
          </inq-color-popover>
        ) : null}
      </inq-color-select>
    </inq-color-picker>
  );
}

export default InQColorPicker;
