import Fingerprint2 from "fingerprintjs2";
import { untilBrowserIsIdle } from "./../../util";

const screenRezCutOff = 600;

async function calculateFingerPrint() {
  await untilBrowserIsIdle();

  const options: Fingerprint2.Options = isDesktop()
    ? {
        excludes: {
          screenResolution: true,
          availableScreenResolution: true,
          enumerateDevices: true,
          pixelRatio: true,
          doNotTrack: true,
          fontsFlash: true,
        },
      }
    : {
        excludes: {
          enumerateDevices: true,
          pixelRatio: true,
          doNotTrack: true,
          fontsFlash: true,
        },
      };

  const components = await Fingerprint2.getPromise(options);
  const json = JSON.stringify(components);
  return Fingerprint2.x64hash128(json, 64);
}

function isDesktop() {
  return (window as any).screen.width > screenRezCutOff;
}

const BrowserFingerPrint = calculateFingerPrint();
export default BrowserFingerPrint;
