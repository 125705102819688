import React from "react";
import { Link } from "react-router-dom";
import SinglePanelLayout from "../../Components/Layout/SinglePanel/SinglePanel";
import "./DebugNav.scss";

function ScreenDebugNav() {
  return (
    <screen-debug-nav>
      <SinglePanelLayout>
        <ul>
          <li>
            <Link to="/debug-chat">Debug Chat</Link>
          </li>
          <li>
            <Link to="/welcome">Welcome</Link>
          </li>
          <li>
            <Link to="/whats-nearby/list-view">Whats Nearby</Link>
          </li>
          <li>
            <Link to="/checkin">Check In</Link>
          </li>
          <Link to="/questionnaire-welcome">Questionnaire Welcome</Link>
          <li>
            <Link to="/questionnaire">Questionnaire</Link>
          </li>
          <li>
            <Link to="/questionnaire-declaration">
              Questionnaire Declaration
            </Link>
          </li>
          <li>
            <Link to="/questionnaire-exit">Questionnaire exit</Link>
          </li>
          <li>
            <Link to="/health-card-needed">Health Card Needed</Link>
          </li>
          <li>
            <Link to="/health-card-capture">Health card capture</Link>
          </li>
          <li>
            <Link to="/document-needed">Document Needed</Link>
          </li>
          <li>
            <Link to="/summary">Summary</Link>
          </li>
          <li>
            <Link to="/waiting-in-queue">Waiting in Queue </Link>
          </li>
          <li>
            <Link to="/its-your-turn">It's Your turn </Link>
          </li>
          <li>
            <Link to="/on-my-way">On my way</Link>
          </li>
          <li>
            <Link to="/confirm-leave-queue">Confirm Leave Queue</Link>
          </li>
          <li>
            <Link to="/timesup">Times Up</Link>
          </li>
          <li>
            <Link to="/closing">Closing</Link>
          </li>
          <li>
            <Link to="/out-of-range">Out of Range</Link>
          </li>
          <li>
            <Link to="/removed">Removed</Link>
          </li>
          <li>
            <Link to="error-unknown">Something Happened</Link>
          </li>
          <li>
            <Link to="fatal-error">Fatal Error</Link>
          </li>
          <li>
            <Link to="/please-wait">Please Wait</Link>
          </li>
        </ul>
      </SinglePanelLayout>
    </screen-debug-nav>
  );
}

export default ScreenDebugNav;
